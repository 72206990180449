import { useFormik } from "formik"
import axios from "axios"
import { URLS, DEFAULT_HEADERS, ENCODE_PARAMS } from '../app-helpers'
import { Modal, ModalOverlay, ModalContent, ModalHeader, Text, Flex, Box, ModalCloseButton, ModalBody, VStack, FormControl, FormLabel, InputGroup, Input, ModalFooter, Grid, GridItem, Button, useDisclosure, IconButton, InputRightElement, HStack, PinInput, PinInputField, List, ListIcon, ListItem } from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { HiEyeOff, HiEye } from "react-icons/hi"
import { TenantBrandingContext } from "../../TenantBrandingContext";
import { MdCheckCircle, MdError } from "react-icons/md";

const validatePassword = (inputText: string): boolean => {
  const pswRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
  if (!inputText) {
    return false
  }
  return pswRegex.test(inputText)
}
const ValidatePassCodeAndUpdatePassword = (props: {
    initialRef: React.MutableRefObject<null>,
    finalRef: React.MutableRefObject<null>,
    tenant_id: string,
    toggleModel: boolean,
    resetToggleHandler: any,
    sentResetCode: boolean,
    emailAddress: string,
    setSentResetCode: React.Dispatch<React.SetStateAction<boolean>>,
    setShowFinalConfirmation: React.Dispatch<React.SetStateAction<boolean>>,
    styleCss: {normal: {background: string, color: string}, hover: {background: string}}
  }) => {
    const [criteriaCheckedIcon, criteriaFailedIcon] = [MdCheckCircle, MdError]
    const tenantBranding = useContext(TenantBrandingContext)
    const [errorMsg, setErrorMsg] = useState<string>()
    const [input5DigitPin, setInput5DigitPin] = useState<number>()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showPsw, setShowPsw] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const appNavigate = useNavigate()
    const handlePasswordClick = () => setShowPsw(!showPsw)

    const modalCloseHanlder = (param?: any) => {
        setIsSubmitting(false)
        onClose()
        props.setSentResetCode(false)
        props?.resetToggleHandler()
      }
    const resetPasswordForm = useFormik({
        initialValues: {
          new_passcode: ""
        },
        
        onSubmit: (values) => {
          if (! validatePassword(values?.new_passcode)) {
            setErrorMsg('The new password must be between 10 to 18 characters, contain at least 1 number, at least 1 uppercase, at least 1 lowercase, and at least 1 special character.')
            setIsSubmitting(false)
          }
          else {
            const sendCodeData = {
              email_address: props.emailAddress,
              otp_code: input5DigitPin,
              new_password: values.new_passcode,
              tenant_id: props.tenant_id,
            };
            setIsSubmitting(true)
            sendCodeHandler(sendCodeData)
          }
        },
      })
    
    const sendCodeHandler = (postParams: any) => {
      const errorMsg = "Unable to update the password. Please try again.";
      const errorParams = ENCODE_PARAMS({ error: errorMsg });
      axios
        .post(URLS.VALIDATE_PASSCODE_UPDATE_PASSWORD, { ...postParams }, { headers: DEFAULT_HEADERS() })
        .then((res) => res?.data?.body?.task_completed)
        .then((task_completed: string) => {
          if (task_completed !== "true") {
            modalCloseHanlder();
            appNavigate(`/t/${props.tenant_id}/login?${errorParams}`);
          } else {
            props.setShowFinalConfirmation(true);
            props.setSentResetCode(false);
          }
        })
        .catch((err) => {
          console.error("Unable to send code to the user", err);
          modalCloseHanlder();
          appNavigate(`/t/${props.tenant_id}/login?${errorParams}`);
        });
    }

    useEffect(() => {
        if (!props?.toggleModel) { 
          return; // Nothing to do
        } else if (props.toggleModel) {
          onOpen()
        }
        
      }, [props.tenant_id, props.toggleModel, onOpen])
    
      const [lengthCriteriaValid, setLengthCriteriaValid] = useState(false)
      const [digitCriteriaValid, setDigitCriteriaValid] = useState(false)
      const [lowercaseCriteriaValid, setLowercaseCriteriaValid] = useState(false)
      const [uppercaseCriteriaValid, setUppercaseCriteriaValid] = useState(false)
      const [specialCharacterCriteriaValid, setSpecialCharacterCriteriaValid] = useState(false)
    
      const validatePasscode = (event: any) => {
        const resetCode = event || ''
        let isValidCode: boolean = (resetCode && resetCode.length === 5)
        for (const ch of resetCode) {
          if (/[0-9]/.test(ch)) {
            isValidCode = isValidCode && true
          } else {
            isValidCode = false
          }
        }
        if (resetCode && isValidCode) {
          setInput5DigitPin(parseInt(resetCode))
        }
        
      }
    
      const validateInputNewPasswordText = (event: any) => {
        const newPassword = event?.target?.value || ''
        resetPasswordForm.handleChange(event) 
        setLengthCriteriaValid((newPassword && newPassword.length >= 10 && newPassword.length <= 18) ? true : false)
        setDigitCriteriaValid(false)
        setLowercaseCriteriaValid(false)
        setUppercaseCriteriaValid(false)
        setSpecialCharacterCriteriaValid(false)
        for (const ch of newPassword) { 
          if (/[a-z]/.test(ch)) {
            setLowercaseCriteriaValid(true)
          } else if (/[A-Z]/.test(ch)) {
            setUppercaseCriteriaValid(true)
          } else if (/[0-9]/.test(ch)) {
            setDigitCriteriaValid(true)
          } else if (/[!@#$%^&*]/.test(ch)) {
            setSpecialCharacterCriteriaValid(true)
          }
        }
      }

    return (
        <Modal
      initialFocusRef={props.initialRef}
      finalFocusRef={props.finalRef}
      isOpen={isOpen} size={'xl'}
      onClose={modalCloseHanlder}
    >
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(1deg)' />
      <ModalContent>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalCloseButton onClick={modalCloseHanlder} />
        <form onSubmit={resetPasswordForm.handleSubmit}>
          <ModalBody pb={6}>
            <VStack spacing={8} align="flex-start">
            <FormControl isRequired>
                <FormLabel
                  htmlFor="reset_code"  
                  fontSize={"md"} mb={4}
                >
                  Please enter the pin sent to the "{`${props.emailAddress}`}". The pin expires in 30 minutes.
                </FormLabel>
                <HStack alignItems={'center'} textAlign={'center'}>
                <InputGroup width={'100%'} alignItems={'center'} textAlign={'center'}> 
                <Flex w='100%'>  
                    <HStack w='100%' justifyContent={'center'}>
                      <PinInput otp size='lg' placeholder="" id="reset_code" onChange={validatePasscode}>
                        <PinInputField mx={2} />
                        <PinInputField mx={2} />
                        <PinInputField mx={2} />
                        <PinInputField mx={2} />
                        <PinInputField mx={2} />
                      </PinInput>
                    </HStack> 
                </Flex>
                </InputGroup>
                </HStack>
              </FormControl>
              <FormControl isRequired>
                <FormLabel
                  htmlFor="new_passcode" 
                  fontSize={"md"} mb={4}
                >
                  New Password
                </FormLabel>
                <InputGroup> 
                  <Input
                    id="new_passcode"
                    name="new_passcode"
                    type={showPsw ? "text" : "password"}
                    borderRadius="10px"
                    variant="outline"
                    placeholder="Enter New Password"
                    onChange={validateInputNewPasswordText}
                    value={resetPasswordForm.values.new_passcode}
                  />
                  <InputRightElement>
                    <IconButton
                      variant="text" color={tenantBranding.color_palette.icon_background_color_dark}
                      aria-label={showPsw ? "Mask password" : "Reveal password"}
                      icon={showPsw ? <HiEyeOff /> : <HiEye />}
                      onClick={handlePasswordClick}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Box fontSize={'sm'}>
                <List spacing={1}>
                  <ListItem><ListIcon as={lengthCriteriaValid ? criteriaCheckedIcon: criteriaFailedIcon} color={lengthCriteriaValid ? 'green.500': 'red.500'} />The new password must be between 10 to 18 characters.</ListItem>
                  <ListItem><ListIcon as={digitCriteriaValid ? criteriaCheckedIcon: criteriaFailedIcon} color={digitCriteriaValid ? 'green.500': 'red.500'} />The new password must have at least 1 digit.</ListItem>
                  <ListItem><ListIcon as={lowercaseCriteriaValid ? criteriaCheckedIcon: criteriaFailedIcon} color={lowercaseCriteriaValid ? 'green.500': 'red.500'} />The new password must have at least 1 lowercase character.</ListItem>
                  <ListItem><ListIcon as={uppercaseCriteriaValid ? criteriaCheckedIcon: criteriaFailedIcon} color={uppercaseCriteriaValid ? 'green.500': 'red.500'} />The new password must have at least 1 uppercase character.</ListItem>
                  <ListItem><ListIcon as={specialCharacterCriteriaValid ? criteriaCheckedIcon: criteriaFailedIcon} color={specialCharacterCriteriaValid ? 'green.500': 'red.500'} />The new password must have at least 1 special character such as !@#$%^&*.</ListItem>
                </List>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack> 
            <Grid w="100%" templateColumns="repeat(4, 1fr)" gap={3}>
              <GridItem />
              <GridItem>
                <Button
                  w="100%"
                  type="submit"
                  alignContent={"left"}  loadingText='Updating..' isLoading={isSubmitting} 
                  backgroundColor={`${tenantBranding.color_palette.submit_background_color}`}
                  color={`${tenantBranding.color_palette.submit_color}`}
                  style={{ ...props.styleCss.normal, ...props.styleCss.hover }}
                  disabled={isSubmitting}
                >
                  Update
                </Button>
              </GridItem>
              <GridItem>
                <Button w="100%" onClick={modalCloseHanlder} isDisabled={isSubmitting}>
                  Cancel
                </Button>
              </GridItem>
            </Grid>
            <Box><Text p={4} fontSize='xs' color='red'>{errorMsg}</Text></Box>
            </VStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
    )
}

export { ValidatePassCodeAndUpdatePassword }