import { ImUnlocked } from "react-icons/im";
import { RiLockFill } from "react-icons/ri";

import { IconButton, Tooltip, Box } from "@chakra-ui/react";
import { useEffect, useState } from 'react'


const ToggleAccess = (props: {isLocked: boolean, fnToggleAccess: (event?: any) => void, loggedInUserRole: string}) => { 
    const toogleLockForClient = (event?: any) => {}
    const [lockJsxElem, setLockJsxElem] = useState<JSX.Element>()

    useEffect(() => {
        if (!props.loggedInUserRole || props.loggedInUserRole === 'client') {
            return
        }
        if (props.isLocked) {
            setLockJsxElem(<Tooltip hasArrow label='Click to grant access to your client' bg='blue.600'><IconButton
                as="a" size={'sm'}
                onClick={props.fnToggleAccess}
                aria-label="Client Access Restricted"
                icon={<RiLockFill />}
              /></Tooltip>)
        } else {
            setLockJsxElem(<Tooltip hasArrow label="Click to restrict access from client" bg='red.600'><IconButton
                as="a" size={'sm'}
                onClick={props.fnToggleAccess}
                aria-label="Client Access Granted"
                icon={<ImUnlocked />}
              /></Tooltip>)
        }
    }, [
        props.isLocked, props.loggedInUserRole
    ])

    return (
        <Box>{lockJsxElem} </Box>
    )
}

export { ToggleAccess }