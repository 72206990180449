import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Container,
  VStack,
  HStack,
  ButtonGroup,
  IconButton,
  Text,
  useColorMode,
  Flex,
} from "@chakra-ui/react";
import { FaLinkedin, FaInstagram } from "react-icons/fa"  
import { TenantBrandingContext } from "../TenantBrandingContext";
import { useContext } from "react";

const FooterPanel = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const tenantBranding = useContext(TenantBrandingContext)
  
  return (
      <Flex w='100%' backgroundColor={tenantBranding.color_palette.footer_background_color}>
      <Container as="footer" role="contentinfo">
        <VStack spacing={{ base: "4", md: "1" }}>
          <HStack justify="space-between" direction="row" align="center">
            <Text fontSize="xs" color={tenantBranding.color_palette.icon_font_color}>
              &copy; {new Date().getFullYear()} Mosaic Biodata, Inc. All rights reserved.
            </Text>
          </HStack>
          <HStack justify="space-between" direction="row" align="center">
            <ButtonGroup variant="tertiary">
              <IconButton
                as="a"
                href="https://www.linkedin.com/company/mosaic-biodata"
                aria-label="LinkedIn" color={tenantBranding.color_palette.icon_font_color}
                icon={<FaLinkedin />}
              />
              <IconButton
                as="a"
                href="https://www.instagram.com/mosaic_biodata"
                aria-label="Instagram" color={tenantBranding.color_palette.icon_font_color}
                icon={<FaInstagram />}
              />
              {/* {colorMode === "light" ? (
                <IconButton
                  as="a"
                  onClick={toggleColorMode}
                  aria-label="Instagram"
                  icon={<MoonIcon />}
                />
              ) : (
                <IconButton
                  as="a"
                  onClick={toggleColorMode}
                  aria-label="Instagram"
                  icon={<SunIcon />}
                />
              )} */}
            </ButtonGroup>
          </HStack>
        </VStack>
      </Container>
      </Flex>
  );
};

export { FooterPanel };
