import { HStack, Icon, Link as ChakraLink, Text, VStack } from "@chakra-ui/react"; 
import { FaClipboardList, FaHome, FaQuestionCircle } from "react-icons/fa" 
import { TiShoppingCart } from "react-icons/ti"
import { Link as ReactRouterLink } from 'react-router-dom'
import { getAppPageUrl } from '../../AppConstants'
import { AiOutlineUserAdd } from "react-icons/ai"
import React, { useContext, useEffect, useState } from "react" 
import { AddNewUser } from "./AddUserModal";
import { TenantBrandingContext } from "../../TenantBrandingContext";
import { ITenantConfig } from "../../interfaces/ITenantConfig";
interface NavOptions { readonly roles: string[]; readonly jsxElem: JSX.Element; readonly configLabel: string }
const MyShortcuts = (props: { tenant_id: string; access_token: string, loggedInUserRole: string, topNavShortcutsMenu: string[] }) => { 
  const tenantBranding = useContext(TenantBrandingContext)
  const [initialRef, finalRef] = [React.useRef(null), React.useRef(null)] 
  const imgSize = 10, menuFontSize = "xs", homePage = getAppPageUrl(props.tenant_id, "home")
  const [openAddClientModel, setOpenAddClientModel] = useState<boolean>(false)
  const [navOptions, setNavOptions] = useState<JSX.Element>(<HStack></HStack>)
  
  useEffect( () => {

    const shortcutJsxElems = (modalOpenState: boolean, modelFn: React.Dispatch<React.SetStateAction<boolean>>, brContext: ITenantConfig): NavOptions[] => {
      return [
        {
            roles: ['all'],
            jsxElem: <VStack key='vstck-1' mx={2}>
                        <ChakraLink as={ReactRouterLink} to={homePage} reloadDocument>
                        <Icon as={FaHome} boxSize={imgSize} color={brContext.color_palette.icon_font_color} />
                        <Text fontSize={menuFontSize} color={brContext.color_palette.icon_font_color}>Home</Text>
                        </ChakraLink>
                    </VStack>,
            configLabel: 'HOME'
        },
        {
            roles: ['admin', 'coach'],
            jsxElem: <VStack key='vstck-2' mx={2}>
                        <Icon onClick={() => modelFn(true)} as={AiOutlineUserAdd} boxSize={imgSize} color={brContext.color_palette.icon_font_color} cursor={'pointer'}/>
                        <Text onClick={() => modelFn(true)} fontSize={menuFontSize} ref={finalRef} color={brContext.color_palette.icon_font_color} cursor={'pointer'}>New Client</Text>
                        <AddNewUser {...{initialRef: initialRef, loggedInUserRole: props.loggedInUserRole, 
                                        finalRef: finalRef, tenant_id: props.tenant_id, userRole: 'client', 
                                        toggleModel: modalOpenState, resetToggleHandler: () => {modelFn(false)}}} />
                    </VStack> ,
            configLabel: 'NEW CLIENT'
        }, 
        {
            roles: ['admin', 'coach'],
            jsxElem: <VStack key='vstck-3' mx={2}>
                        <ChakraLink as={ReactRouterLink} to={getAppPageUrl(props.tenant_id, "shop", "home", {'tab':'my reports'})} reloadDocument >
                        <Icon as={FaClipboardList} boxSize={imgSize} color={brContext.color_palette.icon_font_color} />
                        <Text fontSize={menuFontSize} color={brContext.color_palette.icon_font_color}>Reports</Text>
                        </ChakraLink>
                    </VStack>,
            configLabel: 'REPORTS'
        },
        {
            roles: ['admin coach'],
            jsxElem: <VStack key='vstck-4' mx={2}>
                        <ChakraLink as={ReactRouterLink} to={getAppPageUrl(props.tenant_id, "shop", "genetic kits")} reloadDocument>
                        <Icon as={TiShoppingCart} boxSize={imgSize} color={brContext.color_palette.icon_font_color} />
                        <Text fontSize={menuFontSize} color={brContext.color_palette.icon_font_color}>Buy Kits</Text>
                        </ChakraLink>
                    </VStack>,
            configLabel: 'BUY KITS'
        }, 
        {
            roles: ['admin', 'coach'],
            jsxElem: <VStack key='vstck-4' mx={2}>
                        <ChakraLink as={ReactRouterLink} to={getAppPageUrl(props.tenant_id, "faq")} reloadDocument>
                        <Icon as={FaQuestionCircle} boxSize={imgSize} color={brContext.color_palette.icon_font_color} />
                        <Text fontSize={menuFontSize} color={brContext.color_palette.icon_font_color}>FAQ</Text>
                        </ChakraLink>
                    </VStack>,
            configLabel: 'FAQ'
        }
      ]
    }

    const filteredElemes: JSX.Element[] = shortcutJsxElems(openAddClientModel, setOpenAddClientModel, tenantBranding)
            .filter( (elem: NavOptions) => (elem.roles.includes(props.loggedInUserRole) || elem.roles.includes('all')))
            .filter( (elem: NavOptions) => (props.topNavShortcutsMenu.includes(elem.configLabel)))
            .map(e => e.jsxElem)
    setNavOptions(
        <HStack spacing="20px" w='100%' justifyContent={'center'} textAlign="center" color={tenantBranding.color_palette.top_banner_font_color} backgroundColor={tenantBranding.color_palette.top_banner_background_color}>
            {filteredElemes}
        </HStack>
    )
  }, [
    props.loggedInUserRole, props.topNavShortcutsMenu, openAddClientModel, tenantBranding, initialRef, finalRef, props.tenant_id, homePage
  ])

  return (
    <>{navOptions}</>
  );
};
  


export { MyShortcuts };
