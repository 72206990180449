import React, { useEffect, useState, } from "react";
import { NavBar } from "../navigation/TopNavBar";
import { VStack, Flex, Container, Card, CardBody, CardHeader, Heading, Stack, Center, Skeleton, Box } from "@chakra-ui/react";
import { MyTabbedPanel } from "./TabbedPanel";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { DEFAULT_HEADERS, URLS } from "../app-helpers";
import { MyClientsDashboard } from "../Clients/MyClients";
import { MyCoachesDashboard } from "../Clients/MyCoaches";
import { MyReportsDashboard } from "../Reports/MyReports";
import { KitsDashboard } from "../kits/KitsDashboard";
import { FooterPanel } from "../footer";
import '../styles.css'

const HomePage = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { tenant_id } = useParams()
  const [queryParams] = useSearchParams()

  
  const [allowedTabs, setAllowedTabs] = useState<{panel_name: string; panel_component: JSX.Element; panel_roles: string[];}[]>()
  const [userRole, setUserRole] = useState<string>()
  const [tabIndex, setTabIndex] = useState<number>()
  const [homePageBanner, setHomePageBanner] = useState('Manage all your clients and the reports within the same dashboard.')
  const showTabName = queryParams.get('tab')
  const accessToken = queryParams.get("qat") || sessionStorage.getItem('access_token') || ''
  
  useEffect( () => {
    

    const postParams = { tenant_id: tenant_id };
    const reqHeaders = DEFAULT_HEADERS(accessToken);
    axios
      .post(URLS.GET_NAV_BAR, { ...postParams }, { headers: reqHeaders })
      .then( (res) => res.data.role)
      .then((role) => {
        setUserRole(role)
        if (role === 'client') {
          setHomePageBanner('Double click on the row to view your report.')
        }
        const allTabs = [
          { panel_name: 'My Clients', panel_component: <MyClientsDashboard {...{reqFilter: 'client', userRole: role}} />, panel_roles: ['coach', 'admin']},
          { panel_name: 'My Reports', panel_component: <MyReportsDashboard {...{userRole: role}} />, panel_roles: ['client', 'coach', 'admin']},
          { panel_name: 'My Kits', panel_component: <KitsDashboard {...{userRole: role}} />, panel_roles: ['coach', 'admin']},
          { panel_name: 'My Coaches', panel_component: <MyCoachesDashboard {...{reqFilter: 'coach', userRole: role}} />, panel_roles: ['admin']} 
        ]
        const filteredTabs = allTabs.filter(t => t.panel_roles.indexOf(role) > -1) 
        if (showTabName) {
          for(let t=0;t<filteredTabs.length;t++){
            if(filteredTabs[t].panel_name.toLowerCase() === showTabName.toLowerCase()) { setTabIndex(t) }
          }
        }
        setAllowedTabs(filteredTabs)
        setIsLoaded(true)
      })
      .catch((err) => {
        console.error("Unable to get navigations options for the user", err)
        setTabIndex(0)
        setAllowedTabs([])
        setIsLoaded(true)
      });
  }, [queryParams, tenant_id, showTabName, accessToken])

  return (
    <VStack w="100%" tabIndex={0} autoFocus>
      <NavBar />
      <Flex w='100%' alignContent={"center"} justifyContent={"center"}>
          <Skeleton isLoaded={isLoaded}  w='100%' m={10}>
            <Card className={'add_box_shadow'}>
              <CardHeader tabIndex={0} autoFocus>
                <Heading size="lg">Welcome! {`${sessionStorage.getItem("tenant.app.full_name")}`}</Heading>
              </CardHeader>
              <CardBody alignContent={"center"} justifyContent={"center"}>
                <Stack spacing="4">
                <Heading mb={2} size="sm">{homePageBanner}</Heading>
                  <Center>
                    <Box maxW={'7xl'} justifyContent={"center"}>
                      <MyTabbedPanel {...{userRole: userRole, allowedTabs: allowedTabs, tabIndex: tabIndex}}/>
                    </Box>
                  </Center>
                </Stack>
              </CardBody>
            </Card>
          </Skeleton>
      </Flex>
      <Box pos="fixed" w="100%" bottom={0}>
        <FooterPanel />
      </Box>
    </VStack>
  )
};

export { HomePage };
