import { useDisclosure, Modal, ModalOverlay, IconButton, ButtonGroup, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Button, useToast, FormErrorMessage } from "@chakra-ui/react"
import React, { useEffect, useState, useContext } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { BiSave } from "react-icons/bi";
import { DEFAULT_HEADERS, REPORT_SERVER_URLS, URLS } from "../app-helpers"; 
import { TenantBrandingContext } from "../../TenantBrandingContext";

const AddNewUser = (props: {initialRef: React.MutableRefObject<null>, loggedInUserRole: string,
                          finalRef: React.MutableRefObject<null>, tenant_id: string, 
                          userRole: string, toggleModel: boolean, resetToggleHandler: any}) => {
    const tenantBranding = useContext(TenantBrandingContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const modalTitle = `Add New ${props?.userRole.charAt(0).toUpperCase() + props?.userRole.slice(1)}`
    const [isSubmitting, setIsSubmitting] = useState(false)
    const toastClipboard = useToast()

    const modalCloseHanlder = (param?: any) => {
        onClose()
        newClientForm.values.email = ''
        newClientForm.values.name = ''
        props?.resetToggleHandler()
        setIsSubmitting(false)
    }
    const showMsg = (title: string, errorMsg: string, toastStatus: "success" | "error") => {
        toastClipboard({
          title: title,
          description: errorMsg,
          position: "top",
          isClosable: true,
          status: toastStatus
        })
      }

      const addNewClientHandler = (clientData: {email_address: string, first_name: string, last_name: string, tenant_id: string, user_role: string}) => {
        axios
          .post(URLS.INVITE_USERS, { ...clientData }, { headers: DEFAULT_HEADERS() })
          .then(async res => {
            if (res?.data?.body?.status) {
              const reqUrl: string = REPORT_SERVER_URLS(clientData.tenant_id, undefined)['ADMIN_TRIGGER_USER_CACHE_UPDATE']
              for (const id of [res?.data?.body?.new_cid, res?.data?.body?.from_cid]){
                await axios
                  .get(`${reqUrl}/${id}`, { headers: DEFAULT_HEADERS() })
                  .then(res2 => {return res?.data?.body?.status})
                  .catch((err) => {
                      modalCloseHanlder()
                      showMsg('Error', 'Unable to add the new user. Please try again later.', 'error') 
                  })
              }
              return res?.data?.body?.status
            } else {
              modalCloseHanlder()
              showMsg('Error', 'Unable to add the new user. Please try again later.', 'error') 
              return undefined
            } 
          })
          .then((status: string) => {
            if (status === 'Success') {
              modalCloseHanlder()
              showMsg('Success', `An invite has been sent to the email ${clientData.email_address}. Please request ${clientData.first_name} to check their inbox. If it's not in the inbox, please check the spam or junk folder too. If ${clientData.email_address} doesn't receive the email in next 15 minutes, then please email support@mosaicbio.io.`, 'success')
            } else {
              modalCloseHanlder()
              showMsg('Error', 'Unable to add the new user. Please try again later.', 'error') 
            }
          })
          .catch((err) => {
            console.error("Unable to add new user", err)
            modalCloseHanlder()
            showMsg('Error', 'Unable to add the new user. Please try again later.', 'error')
          })
      }

      const newClientForm = useFormik({
        initialValues: {
            email:'',
            name: ''
        }, onSubmit: (values) => {
            setIsSubmitting(true)
            const name_arr = values.name.split(' ')
            const last_name = (name_arr.length > 1) ? (name_arr.pop() || '') : ''
            const clientData = {
                first_name: name_arr.join(' '),
                last_name: last_name,
                email_address: values.email,
                tenant_id: props.tenant_id,
                user_role: props.userRole
              }
            addNewClientHandler(clientData)
        }
      })
    
      useEffect( () => {
        if(props?.toggleModel) {
            onOpen()
        }
      }, [props.toggleModel, onOpen])

    return <Modal
                initialFocusRef={props.initialRef}
                finalFocusRef={props.finalRef}
                isOpen={isOpen} size={'xl'}
                onClose={modalCloseHanlder}
            >
                <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(1deg)' />
                <ModalContent>
                <ModalHeader>{modalTitle}</ModalHeader>
                <ModalCloseButton onClick={modalCloseHanlder} />
                <form onSubmit={newClientForm.handleSubmit}>
                    <ModalBody pb={6}>
                        <FormControl isRequired>
                          <FormLabel>Name</FormLabel>
                          <Input id='name' name="name" ref={props.initialRef} placeholder='Enter Name' onChange={newClientForm.handleChange} value={newClientForm.values.name} />
                          <FormErrorMessage>Name is required.</FormErrorMessage>
                        </FormControl>
                        <FormControl mt={4} isRequired>
                        <FormLabel>Email</FormLabel>
                          <Input id='email' name="email" placeholder='Enter Email' onChange={newClientForm.handleChange} value={newClientForm.values.email} />
                          <FormErrorMessage>Email is required.</FormErrorMessage>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                      <ButtonGroup size='md' isAttached variant='outline' px={2}>
                        <IconButton aria-label='Save' icon={<BiSave />} color={tenantBranding.color_palette.footer_background_color || '#000'} disabled />
                        <Button type="submit" 
                          color={tenantBranding.color_palette.icon_font_color || '#000'}
                          backgroundColor={tenantBranding.color_palette.footer_background_color}
                          disabled={isSubmitting}
                          isLoading={isSubmitting} variant='solid'
                          loadingText='Submitting'>
                        Save
                        </Button>
                      </ButtonGroup>
                      <Button onClick={modalCloseHanlder} isDisabled={isSubmitting}>Cancel</Button>
                    </ModalFooter>
                </form>
                </ModalContent>
            </Modal>
}

export { AddNewUser }