import { VStack, Flex, Center, Container, Card, CardHeader, Heading, CardBody, Stack } from "@chakra-ui/react"
import { NavBar } from "../navigation/TopNavBar"
import { ShowPurchaseDataGrid } from "./ShowPurchaseData"
import React from "react"

const AllMyPurchases = () => {

    return (
        <VStack w="100%">
          <NavBar />
          <Flex  width={"100vw"} height={"75vh"} alignContent={"center"} justifyContent={"center"}>
            <Center>
              <Container maxW="5xl">
                <Card boxShadow='xl'>
                  <CardHeader>
                    <Heading size="lg">All Purchases</Heading>
                  </CardHeader>
                  <CardBody>
                    <Stack spacing="4">
                      <ShowPurchaseDataGrid />
                    </Stack>
                  </CardBody>
                </Card>
              </Container>
            </Center>
          </Flex>
        </VStack>
      )
}

export { AllMyPurchases }