import { Box, Grid, GridItem } from '@chakra-ui/react'
import { DisplayGenotypes } from './DisplayGenotypes'
import { IPanelDetails } from '../../interfaces/IPanelDetail'
import { GaugesPanel } from './GaugesPanel' 
import { NotesPanel } from './NotesPanel'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

const MarkersAndGaugesBottomPanel = (props: {panelData: IPanelDetails | undefined, loggedInUserRole: string, kidId: string, panelId: string, tenant_id: any, hideNotes: boolean}) => {
    const [notesPanel, setNotesPanel] = useState<JSX.Element>(<></>)
    const [queryParams] = useSearchParams()
    const gaugeParams = {snpData: props?.panelData?.genotype_data?.genes || [], kidId:props?.kidId || ''}
    const coachNoteParams = {hideNotes: props.hideNotes, newNotePlaceholderText: 'Add a note for coach', loggedInUserRole: props.loggedInUserRole, cardTitle: 'Notes for Coach', kidId:props?.kidId || '', panelId: props.panelId, noteType: 'coach_notes', reportId: queryParams.get('report') || ''}
    const clientNoteParams = {hideNotes: props.hideNotes, newNotePlaceholderText: 'Add a note for client', loggedInUserRole: props.loggedInUserRole, 
                                cardTitle: props.loggedInUserRole === 'client' ? 'Notes from Coach' : 'Notes for Client', 
                                kidId:props?.kidId || '', panelId: props.panelId, noteType: 'client_notes', reportId: queryParams.get('report') || ''}

    useEffect(() => {
        if (props.hideNotes) {
            return
        }
        setNotesPanel(
            <Grid w='100%' templateColumns='repeat(1, 1fr)' gap={4}>
                <GridItem w='100%' hidden={['admin', 'super admin', 'coach'].indexOf(props.loggedInUserRole) === -1}>
                    <NotesPanel {...coachNoteParams} />
                </GridItem>
                <GridItem w='100%'>
                    <NotesPanel {...clientNoteParams} />
                </GridItem>
            </Grid>
        )
    }, [
        props.hideNotes, props.panelId
    ])
    return (
        <Box w='100%'>
            <Grid w='100%' templateColumns='repeat(2, 1fr)' gap={6}>
                <GridItem w='100%'>
                    <DisplayGenotypes {...gaugeParams} />
                </GridItem>
                <GridItem w='100%'>
                    <GaugesPanel gaugeData={props?.panelData?.genotype_data?.gauges || []} />
                </GridItem>
            </Grid>
            {notesPanel}
        </Box>
    )
}

export { MarkersAndGaugesBottomPanel }