import { ENCODE_PARAMS } from "./components/app-helpers"; 
import { ITenantConfig } from "./interfaces/ITenantConfig";

const APP = {
    API_SERVER_BASE_URL: process.env.REACT_APP_SERVER_BASE_URL,
    REPORTING_APP_SERVER_BASE_URL: process.env.REACT_APP_REPORTING_SERVER_BASE_URL,
};

const HTTP_SERVER_URLS = {
    TENANT_DETAILS: `${APP.API_SERVER_BASE_URL}/tenants`,
    SUPPORT_DETAILS: `${APP.API_SERVER_BASE_URL}/support`,
    SUPPORT_AUTH: `${APP.API_SERVER_BASE_URL}/support/login`,
    AUTH: `${APP.API_SERVER_BASE_URL}/tenants/login`,
    GET_KIT_DETAILS: `${APP.API_SERVER_BASE_URL}/get-kit-details`
};

const HTML_PAGES = (tenant_id: any, page_requested: string): string => {
  if (!tenant_id || tenant_id === "") {
    console.error("Invalid id, unable to get the page.");
  }
  const pageUrl = {
    TENANT_LOGIN_PAGE: `/t/${tenant_id}/login`,
    TENANT_LOGOUT_PAGE: `/t/${tenant_id}/logout`,
    FAQ_PAGE: `/t/${tenant_id}/faq`,
    TENANT_SHOP_KITS_PAGE: `/t/${tenant_id}/shop/kits`,
    TENANT_SHOP_REPORTS_PAGE: `/t/${tenant_id}/shop/reports`,
    TENANT_HOME_PAGE: `/t/${tenant_id}/home`,
    TENANT_INVITE_NEW_COACH_PAGE: `/t/${tenant_id}/invite/new-coach`,
    TENANT_INVITE_NEW_CLIENT_PAGE: `/t/${tenant_id}/invite/new-client`,
    TENANT_MY_CLIENTS_DASHBOARD_PAGE: `/t/${tenant_id}/my-clients/dashboard`,
    USER_PURCHASES: `/t/${tenant_id}/my-purchases`,
    CHECKOUT_PAGE: `/t/${tenant_id}/checkout`,
    KIT_VIEW: `/t/${tenant_id}/my-purchase/kit/view`,
    VIEW_REPORT: `/t/${tenant_id}/my-purchase/reports/view`,
  }[page_requested]
  if (!pageUrl) {
    console.error(`Invalid page requested. ${page_requested}`)
  }
  return pageUrl || "";
}

const getAppPageUrl = (tenant_id: any, title: string, subTitle?: string, queryParams?: {}): string => {
  let qParam
  if (queryParams) {
    qParam = ENCODE_PARAMS(queryParams)
  }

  let pageUrl = ''
  if (title.toLowerCase() === "logout") {
    pageUrl = HTML_PAGES(tenant_id, 'TENANT_LOGOUT_PAGE')
  } else if (title === "home") {
    pageUrl = HTML_PAGES(tenant_id, 'TENANT_HOME_PAGE')
  } else if (title?.toLowerCase() === "shop" && subTitle?.toLowerCase() === "genetic kits") {
    pageUrl = HTML_PAGES(tenant_id, 'TENANT_SHOP_KITS_PAGE')
  } else if (title?.toLowerCase() === "shop reports") {
    pageUrl = HTML_PAGES(tenant_id, 'TENANT_SHOP_REPORTS_PAGE')
  } else if (title?.toLowerCase() === "invite new coach") {
    pageUrl = HTML_PAGES(tenant_id, 'TENANT_INVITE_NEW_COACH_PAGE')
  } else if (title?.toLowerCase() === "invite new client") {
    pageUrl = HTML_PAGES(tenant_id, 'TENANT_INVITE_NEW_CLIENT_PAGE')
  } else if (title?.toLowerCase() === "my clients dashboard") {
    pageUrl = HTML_PAGES(tenant_id, 'TENANT_MY_CLIENTS_DASHBOARD_PAGE')
  } else if (title?.toLowerCase() === "my clients my purchases") {
    pageUrl = HTML_PAGES(tenant_id, 'USER_PURCHASES')
  } else if (title === "checkout") {
    pageUrl = `${HTML_PAGES(tenant_id, 'CHECKOUT_PAGE')}/${subTitle}`;
  } else if (title === "faq") {
    pageUrl = HTML_PAGES(tenant_id, 'FAQ_PAGE')
  } else {
    pageUrl = HTML_PAGES(tenant_id, 'TENANT_HOME_PAGE')
  }
  if (qParam) {
    pageUrl += `?${qParam}`
  }

  return pageUrl
}

const setStorageTokens = (tenantConfig: ITenantConfig) => {
  window.sessionStorage.setItem('app.tenant_name', tenantConfig.tenant_name)
  window.sessionStorage.setItem('app.logo', tenantConfig.logo)
  window.sessionStorage.setItem('app.nav_logo', tenantConfig.nav_logo)
  window.sessionStorage.setItem('app.tenant_description', tenantConfig.tenant_description)
  window.sessionStorage.setItem('app.color_palette.background_color', tenantConfig.color_palette.background_color)
  window.sessionStorage.setItem('app.color_palette.font_color', tenantConfig.color_palette.font_color)
  window.sessionStorage.setItem('app.color_palette.submit_background_color', tenantConfig.color_palette.submit_background_color)
  window.sessionStorage.setItem('app.color_palette.submit_color', tenantConfig.color_palette.submit_color)
  window.sessionStorage.setItem('app.color_palette.small_tool_tips', tenantConfig.color_palette.small_tool_tips)
  window.sessionStorage.setItem('app.color_palette.highlight_color_dark', tenantConfig.color_palette.highlight_color_dark)
  window.sessionStorage.setItem('app.color_palette.icon_font_color', tenantConfig.color_palette.icon_font_color)
  window.sessionStorage.setItem('app.color_palette.icon_background_color_dark', tenantConfig.color_palette.icon_background_color_dark)
  window.sessionStorage.setItem('app.color_palette.top_banner_background_color', tenantConfig.color_palette.top_banner_background_color)
  window.sessionStorage.setItem('app.color_palette.footer_background_color', tenantConfig.color_palette.footer_background_color)
  window.sessionStorage.setItem('app.color_palette.top_banner_font_color', tenantConfig.color_palette.top_banner_font_color)
  window.sessionStorage.setItem('app.color_palette.footer_font_color', tenantConfig.color_palette.footer_font_color)
  window.sessionStorage.setItem('app.color_palette.highlight_color_light', tenantConfig.color_palette.highlight_color_light)
  window.sessionStorage.setItem('app.color_palette.submit_background_color_contrast', tenantConfig.color_palette.submit_background_color_contrast)
}

const getFromStorageToken = (param: string): string =>  {
  return window.sessionStorage.getItem(param) || ''
}
export { APP as ENV , HTTP_SERVER_URLS as URLS, HTML_PAGES as APP_PAGES, getAppPageUrl, setStorageTokens, getFromStorageToken};