import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDisclosure, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, VStack, Button, ModalFooter } from "@chakra-ui/react"
import { TenantBrandingContext } from "../../TenantBrandingContext"
import { useContext } from "react"

const ShowConfirmationModal = (props: {
    initialRef: React.MutableRefObject<null>,
    finalRef: React.MutableRefObject<null>,
    tenant_id: string,
    toggleModel: boolean,
    resetToggleHandler: any, 
    emailAddress: string,
    setShowFinalConfirmation: React.Dispatch<React.SetStateAction<boolean>>,
    styleCss: {normal: {background: string, color: string}, hover: {background: string}}
  }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const appNavigate = useNavigate()
    const tenantBranding = useContext(TenantBrandingContext)

    const modalCloseHanlder = (param?: any) => {
        onClose()
        props.setShowFinalConfirmation(false)
        props?.resetToggleHandler()
        appNavigate(`/t/${props.tenant_id}/login`);
      }
    
    useEffect(() => {
    if (!props?.toggleModel) { 
        return; // Nothing to do
    } else if (props.toggleModel) {
        onOpen()
    }
    
    }, [props.tenant_id, props.toggleModel, onOpen])

    return (
        <Modal
      initialFocusRef={props.initialRef}
      finalFocusRef={props.finalRef}
      isOpen={isOpen} size={'xl'}
      onClose={modalCloseHanlder}
    >
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(1deg)' />
      <ModalContent>
        <ModalHeader>Success!</ModalHeader>
        <ModalCloseButton onClick={modalCloseHanlder} /> 
          <ModalBody pb={6}>
            <VStack spacing={8} align="flex-start">
                <Text fontSize='md'>
                    The password for the "{`${props.emailAddress}`}" has been updated successfully. Please click on the Sign-In button below to login with your new password.
                </Text>
            </VStack>
          </ModalBody> 
          <ModalFooter>
            <Button w="100%" 
                onClick={modalCloseHanlder}
                backgroundColor={`${tenantBranding.color_palette.submit_background_color}`}
                color={`${tenantBranding.color_palette.submit_color}`}
                style={{ ...props.styleCss.normal, ...props.styleCss.hover }}
            >
                Sign In
            </Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
    )
}

export { ShowConfirmationModal }