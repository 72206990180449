
import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Box, HStack, Text, Tooltip, Icon } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom" 
import { DEFAULT_HEADERS, ENCODE_PARAMS, URLS } from "../app-helpers"
import axios from "axios"
import { useState, useMemo } from "react"
import { IReportPanels, IReportView } from "../../interfaces/IReportView"

const PanelNavigation = (props: {kit_id: string, tenant_id: string | undefined, report_id: any, panel_id: string, reportTitle: any}) => {
    
    const [leftNavToolTip, setLeftNavToolTip] = useState<string>()
    const [rightNavToolTip, setRightNavToolTip] = useState<string>()
    const [prevPanelId, setPrevPanelId] = useState<string>()
    const [nextPanelId, setNextPanelId] = useState<string>()
    const [currentIdx, setCurrentIdx] = useState<number>()
    const [totalPanels, setTotalPanels] = useState<number>()
    const [reportTitle, setReportTitle] = useState<string>(props.reportTitle)
    useMemo(() => { 
        const params = {
            kit_id: props.kit_id, report: props.report_id, tenant_id: props.tenant_id
        }
        axios.post(URLS.VIEW_REPORT, {...params}, { headers: DEFAULT_HEADERS() })
        .then((res) => res?.data?.body)
        .then((data: IReportView) => {
            setTotalPanels(data.report_modules.length)
            setReportTitle(data.name)
            const sortedModules = data.report_modules.sort( (elem1: IReportPanels, elem2: IReportPanels) => elem1.sort_order < elem2.sort_order ? -1 : 1)
            for (let i = 0 ; i < sortedModules.length; i++) {
                if(sortedModules[i]?.id === props?.panel_id) {
                    setCurrentIdx(i+1)
                    if ( (i+1) < sortedModules.length) { 
                        setNextPanelId(sortedModules[i+1].id)
                        setRightNavToolTip(sortedModules[i+1].name)
                    }
                    if (i > 0) {
                        setPrevPanelId(sortedModules[i-1].id)
                        setLeftNavToolTip(sortedModules[i-1].name)
                    }
                    
                }
            }
        })
        .catch((err) => {
            console.error('Unable to find the snapshot', err)
        })
    }, [props.tenant_id, props.kit_id, props.report_id, props.panel_id])
    const appNavigate = useNavigate() 
    const navigateToPanel = (params: any) => {
        appNavigate(`/t/${props.tenant_id}/my-purchase/reports/panel/view?${ENCODE_PARAMS(params)}`)
    }
    const goBackHandler = (event?: any) => {
        const params = {
            i: props.report_id,
            k: props.kit_id,
            n: reportTitle
        }
        appNavigate(`/t/${props.tenant_id}/my-purchase/reports/view?${ENCODE_PARAMS(params)}`)
    }
    const previousPanel = (event?: any) => {
        if(!prevPanelId) {
            return
        }
        const params = {
            panel_id: prevPanelId, kit_id: props.kit_id, tenant_id: props.tenant_id, report: props.report_id
        }
        navigateToPanel(params)
    }
    const nextPanel = (event?: any) => {
        if(!nextPanelId) {
            return
        }
        const params = {
            panel_id: nextPanelId, kit_id: props.kit_id, tenant_id: props.tenant_id, report: props.report_id
        }
        navigateToPanel(params)
    }

    return (
        <HStack my={5}>
            <Box width={'50%'} textAlign={'left'}>
                <Text fontSize={'md'} onClick={goBackHandler} cursor={'pointer'}><Icon as={ArrowLeftIcon} boxSize={'3'} color="red.500" /> Go Back</Text>
            </Box>
            <Box width={'50%'} textAlign={'right'}>
                <Text fontSize={'sm'}>
                    <Tooltip label={leftNavToolTip} placement='left'>
                        <Icon boxSize={'6'} as={ChevronLeftIcon} onClick={previousPanel} cursor={'pointer'} />
                    </Tooltip> {currentIdx}/{totalPanels} <Tooltip label={rightNavToolTip} placement='right'><Icon boxSize={'6'} as={ChevronRightIcon} onClick={nextPanel} cursor={'pointer'} /></Tooltip>
                </Text>
            </Box>
        </HStack>
    )
}

export { PanelNavigation }