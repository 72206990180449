import { ImUnlocked } from "react-icons/im";
import { RiLockFill } from "react-icons/ri";
import { Button, IconButton, ButtonGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { DEFAULT_HEADERS, ENCODE_PARAMS, REPORT_SERVER_URLS, URLS } from "../../app-helpers";

const ReportAccessConfirmation = (props: {isLocked: boolean, tenant_id: any, mosaicId: any, reportId: any, clientName: any,  
                                          reportName: any, initialRef: React.MutableRefObject<null>, finalRef: React.MutableRefObject<null>, 
                                          toggleModel: boolean, resetToggleHandler: any, setIsLocked: React.Dispatch<React.SetStateAction<boolean>>}) => {
    const [isSubmitting, setIsSubmitting] = useState(false) 
    const [line1Msg, setLine1Msg] = useState<string>()
    const [line2Msg, setLine2Msg] = useState<string>()
    const [btnIcon, setBtnIcon] = useState<JSX.Element>()
    const [confirmBtnText, setConfirmBtnText] = useState<string>()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toastClipboard = useToast()
    const [modalTitle, setModalTitle] = useState<string>()
 
    const modalCloseHanlder = (param?: any) => {
        onClose()
        kitRegistrationFormElem.values.client_id = ''
        setIsSubmitting(false)
        props?.resetToggleHandler()
    }
    const showMsg = (title: string, errorMsg: string, toastStatus: "success" | "error") => {
        toastClipboard({
          title: title,
          description: errorMsg,
          position: "top",
          isClosable: true,
          status: toastStatus
        })
      }

    const confirmationHandler = (kitData: {mosaic_id: string, report_id: string, access_action: string, panels_list: string, tenant_id: string}) => {
      axios
        .post(URLS.TOGGLE_REPORT_ACCESS, { ...kitData }, { headers: DEFAULT_HEADERS() })
        .then(async res => {
          if (res?.data?.body?.action_status === 'success') {
            const reqUrl: string = REPORT_SERVER_URLS(kitData.tenant_id, undefined)['TOGGLE_REPORT_CLIENT_ACCESS']
            const getParams = { 'rid': kitData.report_id, 'mid': kitData.mosaic_id }
            
            await axios
                  .get(`${reqUrl}?${ENCODE_PARAMS(getParams)}`, { headers: DEFAULT_HEADERS() })
                  .then(res2 => {
                    console.log(res2)
                    if (res2?.data?.status === 'success') {
                      modalCloseHanlder()
                      props.setIsLocked(res?.data?.body?.access_action === 'Revoked')
                      showMsg('Success', `The access to the report "${props.reportName}" has been granted to your client. Please request the client to check their inbox for the details.`, 'success') 
                    } else {
                      modalCloseHanlder()
                      showMsg('Error', `Unable to grant access to your client. Please try again later.`, 'error') 
                    }
                  })
                  .catch((err) => {
                    modalCloseHanlder()
                    showMsg('Error', `Unable to grant access to your client. Please try again later.`, 'error') 
                  })
          } else {
            modalCloseHanlder()
            showMsg('Error', `Unable to grant access to your client. Please try again later.`, 'error') 
          }
        })
        .catch((err) => {
          console.error(`Unable to grant access to your client. Please try again later.`, err)
          modalCloseHanlder()
          showMsg('Error', `Unable to grant access to your client. Please try again later.`, 'error')
        })
    }

    const kitRegistrationFormElem = useFormik({
        initialValues: { 
            client_id: ''
        }, onSubmit: (values) => {
            setIsSubmitting(true) 
            const kitData = {
              access_action: props.isLocked ? 'true': 'false',
              mosaic_id: props.mosaicId,
              panels_list: 'all',
              report_id: props.reportId, 
              tenant_id: props.tenant_id
              }
            confirmationHandler(kitData)
        }
      })
    
      useEffect( () => {
        if (props.isLocked) {
          setModalTitle('Grant Access?')
          setConfirmBtnText("Grant Access")
          setLine1Msg(`At present, access to this report is limited. Would you like to authorize access to your client, ${props.clientName}, for all panels in this report? Your client, ${props.clientName}, will receive an email with login instructions. If you choose to change your decision in the future, you may revoke access by clicking on the unlock icon.`)
          setLine2Msg(`Click "Grant Access" to confirm or "Cancel" otherwise.`)
          setBtnIcon(<RiLockFill />)
        } else {
          setModalTitle('Revoke Access?')
          setConfirmBtnText("Revoke Access")
          setLine1Msg(`You are about to revoke ${props.clientName}'s access to this report. So that you know, ${props.clientName} will not be notified of the revocation. If ${props.clientName} has already viewed or downloaded the report, revoking access will only restrict their future access.`)
          setLine2Msg(`Click "Revoke Access" to proceed or "Cancel" to abort the action.`)
          setBtnIcon(<ImUnlocked />)
        }
          if(props?.toggleModel && props?.mosaicId) {
              onOpen()
          }
        }, [props.toggleModel, onOpen, props.mosaicId, props.isLocked, props.reportId, props.mosaicId, kitRegistrationFormElem.handleChange])

    return <Modal size={'xl'}
            initialFocusRef={props.initialRef}
            finalFocusRef={props.finalRef}
            isOpen={isOpen}
            onClose={modalCloseHanlder}
        >
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>{modalTitle}</ModalHeader>
            <ModalCloseButton onClick={modalCloseHanlder} />
            <form onSubmit={kitRegistrationFormElem.handleSubmit}>
                <ModalBody pb={6} fontSize={'sm'}> 
                  {line1Msg}<br /><br />{line2Msg}
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup size='sm' isAttached variant='outline' colorScheme="telegram" px={2}>
                        <IconButton aria-label='Save' icon={btnIcon} disabled />
                        <Button type="submit" 
                            isLoading={isSubmitting} variant='solid' loadingText='Updating Access'
                            mr={3} disabled={isSubmitting}>{confirmBtnText}</Button>
                    </ButtonGroup>
                     
                    <Button size={'sm'} onClick={modalCloseHanlder}>Cancel</Button>
                </ModalFooter>
            </form>
            </ModalContent>
        </Modal>
}

export { ReportAccessConfirmation }