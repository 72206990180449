import { Box, Select, Button, IconButton, ButtonGroup, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { BiDna } from "react-icons/bi";
import { DEFAULT_HEADERS, REPORT_SERVER_URLS, URLS } from "../app-helpers";
import { IClient } from "../../interfaces/IClient";

const clientOptionsDropDown = (clientList: IClient[], selectionChangeHandler: any): JSX.Element => {
    if (!clientList || clientList.length === 0) {
        return <Select placeholder='Pick a client' />
    }

    return <Box fontSize='xs'> <Select variant='filled' id='client_id' name='client_id' placeholder='Pick a client' onChange={selectionChangeHandler}> 
    {clientList.map( (elem: IClient, idx: number) => <option key={`idx-${idx}-option`} id={`idx-${idx}-option`} value={elem.cid}>{elem.full_name} [{elem.email}]</option>)}
    </Select></Box>
}
const KitRegistration = (props: {clientList: IClient[], initialRef: React.MutableRefObject<null>, finalRef: React.MutableRefObject<null>, tenant_id: any, toggleModel: boolean, resetToggleHandler: any, mosaicId: any, setReloadGridHandler: React.Dispatch<React.SetStateAction<boolean>>}) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [myClients, setMyClients] = useState<JSX.Element>(clientOptionsDropDown([], () => {}))
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toastClipboard = useToast()
    const modalTitle = `Register Kit ${props.mosaicId}`
 
    const modalCloseHanlder = (param?: any) => {
        onClose()
        kitRegistrationFormElem.values.client_id = ''
        props?.resetToggleHandler()
        setIsSubmitting(false)
    }
    const showMsg = (title: string, errorMsg: string, toastStatus: "success" | "error") => {
        toastClipboard({
          title: title,
          description: errorMsg,
          position: "top",
          isClosable: true,
          status: toastStatus
        })
      }

    const registerKitHandler = (kitData: {mosaic_kit_id: string, client_id: string, tenant_id: string}) => {
      axios
        .post(URLS.REGISTER_KIT, { ...kitData }, { headers: DEFAULT_HEADERS() })
        .then(async res => {
          const kitCacheUrl: string = REPORT_SERVER_URLS(kitData.tenant_id, undefined)['ADMIN_TRIGGER_KIT_CACHE_UPDATE']
          await axios
                  .get(`${kitCacheUrl}/${kitData.mosaic_kit_id}`, { headers: DEFAULT_HEADERS() })
                  .then(res2 => {})
                  .catch((err) => {
                      modalCloseHanlder()
                      showMsg('Error', `Unable to register "${kitData.mosaic_kit_id}". Please try again later.`, 'error') 
                  }) 
          return res
        })
        .then(res => {
          if (res?.data?.body?.status) {
            props.setReloadGridHandler(true)
            modalCloseHanlder()
            showMsg('Success', `The kit "${kitData.mosaic_kit_id}" has been registered to "${res?.data?.body?.registered_to}"`, 'success') 
          } else {
            modalCloseHanlder()
            showMsg('Error', `Unable to register "${kitData.mosaic_kit_id}". Please try again later.`, 'error') 
          }
        })
        .catch((err) => {
          console.error(`Unable to register "${kitData.mosaic_kit_id}". Please try again later.`, err)
          modalCloseHanlder()
          showMsg('Error', `Unable to register "${kitData.mosaic_kit_id}". Please try again later.`, 'error')
        })
    }

    const kitRegistrationFormElem = useFormik({
        initialValues: { 
            client_id: ''
        }, onSubmit: (values) => {
            setIsSubmitting(true) 
            const kitData = {
                mosaic_kit_id: props.mosaicId,
                client_id: values.client_id, 
                tenant_id: props.tenant_id
              }
            registerKitHandler(kitData)
        }
      })
    
      useEffect( () => {
          setMyClients(clientOptionsDropDown(props.clientList, kitRegistrationFormElem.handleChange))
          if(props?.toggleModel && props?.mosaicId) {
              onOpen()
          }
        }, [props.toggleModel, onOpen, props.clientList, props.mosaicId, kitRegistrationFormElem.handleChange])

    return <Modal
            initialFocusRef={props.initialRef}
            finalFocusRef={props.finalRef}
            isOpen={isOpen} size={'xl'}
            onClose={modalCloseHanlder}
        >
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(1deg)' />
            <ModalContent>
            <ModalHeader>{modalTitle}</ModalHeader>
            <ModalCloseButton onClick={modalCloseHanlder} />
            <form onSubmit={kitRegistrationFormElem.handleSubmit}>
                <ModalBody pb={6}> 
                    <FormControl mt={4} isRequired>
                    <FormLabel>Select one from the drop down</FormLabel>
                    {myClients}
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup size='md' isAttached variant='outline' colorScheme="telegram" px={2}>
                        <IconButton aria-label='Save' icon={<BiDna />} disabled />
                        <Button type="submit" 
                            isLoading={isSubmitting} variant='solid' loadingText='Registering'
                            mr={3} disabled={isSubmitting}>Register</Button>
                    </ButtonGroup>
                     
                    <Button onClick={modalCloseHanlder} isDisabled={isSubmitting}>Cancel</Button>
                </ModalFooter>
            </form>
            </ModalContent>
        </Modal>
}

export { KitRegistration }