import { useEffect, useRef, useState } from "react"
import { IPanelDetails } from "../../interfaces/IPanelDetail"
import { Card, Text, Flex, Heading, CardBody, Image, CardFooter, Divider, Stack, Skeleton } from "@chakra-ui/react"
import { MarkersAndGaugesBottomPanel } from "./MarkersAndGaugesBottomPanel"
import { useParams } from "react-router-dom"

const PanelMarkersAndGauges = (props: {panelDetails: (IPanelDetails | undefined), 
                                      loggedInUserRole: string, kitId: string, 
                                      setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>, panelId: string,
                                      hideNotes?: boolean}) => {
    const [data, setData] = useState<IPanelDetails | undefined>(props?.panelDetails)
    const { tenant_id } = useParams()
    const initialRefCoach = useRef(null)
    const [ panelDescription, setPanelDescription] = useState<{__html: string}>({__html: ''})
    const [imageJsx, setImageJsx] = useState<JSX.Element>(<></>)
    const [initalLoadComplete, setInitialLoadComplete] = useState<boolean>(false)

    useEffect( () => {
      if(!tenant_id || !props.panelDetails?.banner_link) {
        return
      }

      const imgName = `https://media.mosaicbio.io/media/${tenant_id}/${props?.panelDetails?.banner_link}`
      setData(props.panelDetails)
      setImageJsx(<Image ref={initialRefCoach}
        src={imgName}
        alt={props?.panelDetails?.name || "Loading"}
        borderRadius="2xl" 
        overflow="hidden"
        objectFit="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        maxH="200"
        w="100%"
        m="0"
        p="0"
      />)
      setPanelDescription({__html: props.panelDetails?.description || ''})
      props.setIsLoaded(true)
      setInitialLoadComplete(true)
    }, [props, tenant_id])
    const params = {
        panelData: props.panelDetails, kidId: props?.kitId, 
        panelId: props.panelId, loggedInUserRole: props.loggedInUserRole, 
        tenant_id: tenant_id,
        hideNotes: props.hideNotes || false
    }
    return (
      <Card maxW="7xl" my={5}>
        <CardBody>
          <Skeleton isLoaded={initalLoadComplete}>
            <Flex>
              {imageJsx}
            </Flex> 
            <Stack mt="6" spacing="3"> 
              <Heading size="md">{data?.name}</Heading>
              <Text dangerouslySetInnerHTML={panelDescription} />
            </Stack>
          </Skeleton>
        </CardBody>
        <Divider />
        <CardFooter>
          <MarkersAndGaugesBottomPanel {...params} />
        </CardFooter>
      </Card>
    );
}

export { PanelMarkersAndGauges }