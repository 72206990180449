import { VStack, Flex, Center, Container, Card, CardHeader, Heading, CardBody, Image } from "@chakra-ui/react"; 

const pageNotFoundHandler = () => { 
    return (
        <VStack w="100%"> 
            <Flex  width={"120vw"} height={"105vh"} alignContent={"center"} justifyContent={"center"}>
                <Center>
                <Container maxW="5xl"> 
                    <Card boxShadow='xl'>
                    <CardHeader textAlign={'center'}>
                        <Heading size="2xl">404 ERROR</Heading>
                    </CardHeader>
                    <CardBody textAlign={'center'} alignContent={'center'} alignItems={'center'}   cursor={'pointer'}> 
                        <Heading mb={3} size="md">We can't find the page you are looking for.</Heading>
                        <Flex w='100%' justifyContent={"center"}>
                            <Image src='https://media.mosaicbio.io/404_error.jpg' width={'70%'}/> 
                        </Flex>
                    </CardBody>
                    </Card> 
                </Container>
                </Center>
            </Flex>
        </VStack>
    )
};

export { pageNotFoundHandler as PageNotFound};