
import { useFormik } from "formik"
import axios from "axios"
import { DEFAULT_HEADERS, URLS } from "../app-helpers";
import { Button, ButtonGroup, FormControl, Text, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react"; 
import { EmailIcon } from "@chakra-ui/icons";
const SendWelcomeEmail = (props: {cid: any, tenantId: any, 
                    initialRef: React.MutableRefObject<null>, recpName: any,
                    finalRef: React.MutableRefObject<null>, toggleModel: boolean, resetToggleHandler: any}) => {
    
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const toastClipboard = useToast()
    const modalTitle = `Send Welcome Email`

    const sendWelcomeEmailForm = useFormik({
        initialValues: { 
            client_id: ''
        }, onSubmit: (values) => {
            setIsSubmitting(true)
            welcomeEmailHandler({cid: props.cid, tenant_id: props.tenantId, welcome_email_only: 'True'})
        }
      })

      const showMsg = (title: string, errorMsg: string, toastStatus: "success" | "error") => {
          toastClipboard({
            title: title,
            description: errorMsg,
            position: "top",
            isClosable: true,
            status: toastStatus
          })
        }

    const welcomeEmailHandler = (reqParams: {cid: string, welcome_email_only: string, tenant_id: string}) => { 
    axios
        .post(URLS.SEND_WELCOME_EMAIL, { ...reqParams }, { headers: DEFAULT_HEADERS() })
        .then(res => {
        if (res?.data?.body?.status) {
            modalCloseHanlder()
            showMsg('Success', `An email has been sent to ${props.recpName}. Please request ${props.recpName} to check their inbox. If it's not in the inbox, please check the spam or junk folder too. If ${props.recpName} doesn't receive the email in next 15 minutes, then please email support@mosaicbio.io.`, 'success')
        } else {
            modalCloseHanlder()
            showMsg('Error', `Unable to send a welcome email to ${props.recpName}. Please try again later.`, 'error') 
        }
        })
        .catch((err) => {
            console.error(`Unable to send a welcome email. Please try again later.`, err)
            modalCloseHanlder()
            showMsg('Error', `Unable to send a welcome email to ${props.recpName}. Please try again later.`, 'error')
        })
    }

    const modalCloseHanlder = (param?: any) => {
        onClose()
        sendWelcomeEmailForm.values.client_id = ''
        props?.resetToggleHandler()
        setIsSubmitting(false)
    }
    
    useEffect( () => {
        
        if(props?.toggleModel && props?.cid && props?.recpName && props?.tenantId) {
            onOpen()
        }
      }, [props.toggleModel, onOpen, props.cid, props.tenantId, props.recpName])
    return (
        <Modal
            initialFocusRef={props.initialRef}
            finalFocusRef={props.finalRef}
            isOpen={isOpen} size={'lg'}
            onClose={modalCloseHanlder}
        >
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(1deg)' />
            <ModalContent>
            <ModalHeader>{modalTitle}</ModalHeader>
            <ModalCloseButton onClick={modalCloseHanlder} />
            <form onSubmit={sendWelcomeEmailForm.handleSubmit}>
                <ModalBody pb={6} whiteSpace={'1'}> 
                    <Text fontSize={'sm'} my={2}>You are about to send a welcome email to <Text as='b'>{props.recpName}</Text> along with a temporary password to use this platform.</Text>
                    <Text fontSize={'sm'} width={'100%'} textAlign={'center'}>Are you sure you want to continue?<br />Click "Yes" to proceed, or "No" to cancel.</Text>
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup size='md' isAttached variant='outline' colorScheme="telegram" px={2}>
                        <IconButton aria-label='Save' icon={<EmailIcon />} disabled />
                        <Button type="submit" 
                            isLoading={isSubmitting} variant='solid' loadingText='Sending'
                            mr={3} disabled={isSubmitting}>Yes</Button>
                    </ButtonGroup>
                    <Button onClick={modalCloseHanlder} isDisabled={isSubmitting}>No</Button>
                </ModalFooter>
            </form>
            </ModalContent>
        </Modal>
    )
}

export { SendWelcomeEmail }