import { ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import theme from './theme'
import { LicenseManager } from  'ag-grid-enterprise'

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-058413}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Mosaic_Biodata_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Mosaic_Biodata_Genomics_Platform}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Mosaic_Biodata_Genomics_Platform}_need_to_be_licensed___{Mosaic_Biodata_Genomics_Platform}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_May_2025}____[v3]_[01]_MTc0NzA5MDgwMDAwMA==e0cc5455dd692f83d418d20ef4a96784")

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

root.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

