import { Container, Flex, Text, VStack, StackDivider, TableContainer, Table, TableCaption, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react"
import { IShoppingCart } from "../../../interfaces/IShoppingCart"

const ShowOrderData = (props: { orderData: any; product: string }) => {
  const totalAmount = (props.orderData?.shopping_cart_list || [])
    .map((elem: IShoppingCart) => elem.amount_total_in_cents / 100)
    .reduce((a: number, b: number) => a + b, 0)

  let shippingAddress = props.orderData?.shipping_address?.street_address_line_1
  if (props.orderData?.shipping_address?.street_address_line_2) {
    shippingAddress += `, ${props.orderData?.shipping_address?.street_address_line_2}`
  }
  return (
    <Flex>
      <Container>
      <VStack divider={<StackDivider />}>
        <TableContainer width={"100vw"} >
          <Table variant="simple">
            <TableCaption>{props.product}</TableCaption>
            <Thead>
              <Tr>
                <Th>Item</Th>
                <Th>Qty</Th>
                <Th isNumeric>Unit Price</Th>
              </Tr>
            </Thead>
            <Tbody>
              {(props.orderData?.shopping_cart_list || []).map(
                (elem: IShoppingCart, idx: number) => {
                  return (
                    <Tr fontSize="sm" key={`${idx}-tr`}>
                      <Td>{elem.description}</Td>
                      <Td isNumeric>{elem.quantity}</Td>
                      <Td isNumeric>${elem.unit_amount / 100}</Td>
                    </Tr>
                  );
                }
              )}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th colSpan={2}>Total</Th>
                <Th isNumeric>${totalAmount}</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
        <TableContainer >
          <Table  size='sm' variant="simple" fontSize='sm' textAlign='left' alignContent={"left"}>
            <Tbody>
                <Tr>
                    <Td>Received</Td>
                    <Td>{new Date(props.orderData?.created_at * 1000).toLocaleString()}</Td>
                </Tr> 
                <Tr>
                    <Td>Status</Td>
                    <Td>Pending Shipping</Td>
                </Tr> 
                <Tr>
                    <Td>Email</Td>
                    <Td>{props.orderData?.coach_email}</Td>
                </Tr>
                <Tr>
                    <Td>Address</Td>
                    <Td>
                        <Text fontSize='xs'>{props.orderData?.shipping_address?.owner_name}</Text>
                        <Text fontSize='xs'>{shippingAddress}</Text>
                        <Text fontSize='xs'>{props.orderData?.shipping_address?.city}, {props.orderData?.shipping_address?.state} - {props.orderData?.shipping_address?.postal_code}, {props.orderData?.shipping_address?.country}</Text>
                    </Td>
                </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        </VStack>
      </Container>
    </Flex>
  );
};

export { ShowOrderData }