import { NavBar } from "../navigation/TopNavBar";
import { VStack } from "@chakra-ui/react";
import "../shop/kits.css";
import { useEffect, useState } from "react";
import { PlaceOrderToKits } from "./ShowKitsOrderPanel";
import { CheckoutKits } from "./Checkout";
import { useParams } from "react-router-dom";
import { StripeShippingAddress } from "./StripeShippingAddress";
import { IAddress } from "../../interfaces/IAddress";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { DEFAULT_HEADERS, URLS } from "../app-helpers";


const productData = {
  title: "Mosaic Genetic Test Kit",
  description:
    "Our genetic testing kit provides practitioners with a comprehensive genomic analysis and actionable lifestyle recommendations for each client. We are HIPAA compliant and your data is secure.",
  selections: [
    {
      htmlOptionValue: 1,
      priceBeforeTax: 285,
      htmlBtnLabel: "1 Kit",
    },
    {
      htmlOptionValue: 5,
      priceBeforeTax: 1400,
      htmlBtnLabel: "5 Kits",
    },
    {
      htmlOptionValue: 10,
      priceBeforeTax: 2750,
      htmlBtnLabel: "10 Kits",
    },
    {
      htmlOptionValue: 20,
      priceBeforeTax: 5400,
      htmlBtnLabel: "20 Kits",
    },
    {
      htmlOptionValue: 30,
      priceBeforeTax: 7950,
      htmlBtnLabel: "30 Kits",
    },
    {
      htmlOptionValue: 50,
      priceBeforeTax: 13000,
      htmlBtnLabel: "50 Kits",
    },
  ],
  checkouts: {
    taxPercent: 0,
    shippingFlatRate: 0,
  },
};

const ShopKits = () => {
  const [readyToPurchase, setReadyToPurchase] = useState(false)
  const [deliveryAddress, setDeliveryAddress] = useState<IAddress>()
  const [shippingAddressCollected, setShippingAddressCollected] = useState(false)
  const [orderInCart, setOrderInCart] = useState({})
  const { tenant_id } = useParams()
  
  const [googleMapsApiKey, setGoogleMapsApiKey]= useState<string>()
  const [stripeSession, setStripeSession] =  useState<Promise<Stripe | null> | undefined>()

  useEffect( () => {
    axios
      .create({
        baseURL: URLS.GET_UI_SESSION_TOKENS,
      })
      .post("", { tenant_id: tenant_id }, { headers: DEFAULT_HEADERS()})
      .then((res) => {
        if (res?.data.gapi) {
          setGoogleMapsApiKey(res?.data.gapi)
        }
        if (res?.data.sapi) {
          setStripeSession(loadStripe(res?.data.sapi))
        } 
      })
      .catch((err) => console.error(err))
  }, [tenant_id])
  
  const placeOrderParams = {
    productData: productData, tenantId: tenant_id,
    coachEmail: sessionStorage.getItem('tenant.app.user_email'),
    readyToPurchaseHook: setReadyToPurchase, setOrderInCartHook: setOrderInCart,
  }
  const shippingAddressParams = {setDeliveryAddress: setDeliveryAddress, deliveryAddress: deliveryAddress,
                                  googleMapsApiKey: googleMapsApiKey, stripeSession: stripeSession, 
                                  setShippingAddressCollected: setShippingAddressCollected}
  
  const checkoutKitsParams = { orderInCart: orderInCart, stripePromise: stripeSession, deliveryAddress: deliveryAddress, setShippingAddressCollected: setShippingAddressCollected }

  return (
    <VStack w="100%">
      <NavBar />
      {(!readyToPurchase && !shippingAddressCollected) ? (
        <PlaceOrderToKits {...placeOrderParams} />
      ) : (
        !shippingAddressCollected ? <StripeShippingAddress {...shippingAddressParams} /> : <CheckoutKits {...checkoutKitsParams} />
      )}
      
    </VStack>
  );
};

export { ShopKits };
