import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useState } from "react";
import { FN_DATE_COMP, URLS, DEFAULT_HEADERS, ENCODE_PARAMS, REPORT_SERVER_URLS } from '../app-helpers'
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IReportSnapshot } from "../../interfaces/IReportSnapshot";
import { TimestampDateCellRenderer } from "../ag-grid/TimestampDateCellRenderer";
import { APP_PAGES } from "../../AppConstants";
import React from "react";
import { StatusPanelDef } from "ag-grid-enterprise";


const MyReportsDashboard = (props: {userRole: string | undefined}) => {

    const appNavigate = useNavigate()
    const [rowData, setRowData] = useState<Array<IReportSnapshot> | undefined>()
    const { tenant_id } = useParams()
    const onGridReady = useCallback((params: any) => { 
        const clientsUrl = REPORT_SERVER_URLS(tenant_id, null)['REPORT_SNAPSHOT']  
        axios.get(clientsUrl, { headers: DEFAULT_HEADERS() })
        .then((res) => res?.data || [])
        .then((data: IReportSnapshot[]) => setRowData(data))
        .catch((err) => {
            console.error('Unable to find the snapshot', err) 
        })
    }, [tenant_id])
    

    const defaultColDef = useMemo(() => {
        return {
            resizable: true, enableRowGroup: true, minWidth: 200,
            filter: "agTextColumnFilter",
        }
    }, [])

    const columnTypes = useMemo(() => {
        return {
            nonEditableColumn: { editable: false },
            dateColumn: {
            filter: "agDateColumnFilter",
            filterParams: { comparator: FN_DATE_COMP },
            },
        }
    }, [])

    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 250
        }
    }, [])

    const autoSizeStrategy: any = useMemo(() => {
        return {
            type: "fitGridWidth"
        }
    }, [])

    const viewReportsComponent = (row: any) => {
        const navUrl = APP_PAGES(tenant_id, 'VIEW_REPORT')
        const params = {
            i: row?.data?.id,
            k: row?.data?.kit_id,
            t: row?.data?.thumbnail_link,
            n: row?.data?.name,
            c: row?.data?.client_name,
            ct: row?.data?.created_on
        }
        appNavigate(`${navUrl}?${ENCODE_PARAMS(params)}`);
    }

    const gridOptions = {
        onRowDoubleClicked: viewReportsComponent,
      }
    
      const statusBar = useMemo<{
          statusPanels: StatusPanelDef[];
        }>(() => {
          return {
            statusPanels: [
              { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
              { statusPanel: 'agTotalRowCountComponent', align: 'center' },
              { statusPanel: 'agFilteredRowCountComponent' },
              { statusPanel: 'agSelectedRowCountComponent' },
              { statusPanel: 'agAggregationComponent' },
            ],
          };
        }, [])
      
    const gridCols: any = [
        { field: "kit_id", headerName: 'Mosaic Kit ID'},
        { field: "client_name", headerName: 'Client', sort: 'asc'},
        { field: "coach_name", headerName: 'Coach'},
        { field: "name", headerName: 'Report', width: 300},
        { field: "created_on", headerName: 'Report Date', type: ['dateColumn', 'nonEditableColumn'], cellRenderer: TimestampDateCellRenderer },
        { field: "num_panels", headerName: 'Panels' }
    ]
      
    return (
        <div className="ag-theme-quartz" style={{ height: '450px', minWidth: '850px' }}>
            <AgGridReact autoSizeStrategy={autoSizeStrategy} 
            autoGroupColumnDef={autoGroupColumnDef}  
            rowData={rowData} columnTypes={columnTypes} columnDefs={gridCols}  gridOptions={gridOptions}
            defaultColDef={defaultColDef} onGridReady={onGridReady}
            statusBar={statusBar} rowGroupPanelShow={'always'}
            overlayLoadingTemplate={
              '<div style="position:absolute;top:0;left:0;right:0; bottom:0; background: url(https://ag-grid.com/images/ag-grid-loading-spinner.svg) center no-repeat" aria-label="loading"></div>'
            } />
        </div>
    );
}

export { MyReportsDashboard }