// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add_box_shadow {
    box-shadow: 2px -2px 20px 1px #A3A3A3 !important;
}

.add_box_radius {
    border-radius: 10px 10px 10px 10px  !important;
}

.add_box_shadow_and_radius {
    box-shadow: 2px -2px 20px 1px #A3A3A3 !important;
    border-radius: 10px 10px 10px 10px !important;
}

@media only screen and (min-width: 350px) and (max-width: 550px){
    .media_content_box {
        width: 80% !important;
        margin: 0 auto !important;
    }

    .media_loging_logo {
        width: 35% !important;
        margin: 0 auto !important;
    }
}

.ag-theme-quartz,
.ag-theme-quartz-dark {
  --ag-card-radius: 10px  !important;
  --ag-card-shadow: 0 10px 40px #A3A3A3  !important;
  --ag-popup-shadow: var(--ag-card-shadow)  !important; 
}

 `, "",{"version":3,"sources":["webpack://./src/components/styles.css"],"names":[],"mappings":"AAAA;IAEI,gDAAgD;AACpD;;AAEA;IAEI,8CAA8C;AAClD;;AAEA;IAEI,gDAAgD;IAEhD,6CAA6C;AACjD;;AAEA;IACI;QACI,qBAAqB;QACrB,yBAAyB;IAC7B;;IAEA;QACI,qBAAqB;QACrB,yBAAyB;IAC7B;AACJ;;AAEA;;EAEE,kCAAkC;EAClC,iDAAiD;EACjD,oDAAoD;AACtD","sourcesContent":[".add_box_shadow {\n    -webkit-box-shadow: 2px -2px 20px 1px #A3A3A3 !important;\n    box-shadow: 2px -2px 20px 1px #A3A3A3 !important;\n}\n\n.add_box_radius {\n    -webkit-border-radius: 10px 10px 10px 10px !important;\n    border-radius: 10px 10px 10px 10px  !important;\n}\n\n.add_box_shadow_and_radius {\n    -webkit-box-shadow: 2px -2px 20px 1px #A3A3A3 !important;\n    box-shadow: 2px -2px 20px 1px #A3A3A3 !important;\n    -webkit-border-radius: 10px 10px 10px 10px !important;\n    border-radius: 10px 10px 10px 10px !important;\n}\n\n@media only screen and (min-width: 350px) and (max-width: 550px){\n    .media_content_box {\n        width: 80% !important;\n        margin: 0 auto !important;\n    }\n\n    .media_loging_logo {\n        width: 35% !important;\n        margin: 0 auto !important;\n    }\n}\n\n.ag-theme-quartz,\n.ag-theme-quartz-dark {\n  --ag-card-radius: 10px  !important;\n  --ag-card-shadow: 0 10px 40px #A3A3A3  !important;\n  --ag-popup-shadow: var(--ag-card-shadow)  !important; \n}\n\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
