import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { APP_PAGES } from '../AppConstants'

const LogoutPage = () => {
    const { tenant_id } = useParams()
    const appNavigate = useNavigate();

    useEffect( () => {
        // clearing all the session storage.
        sessionStorage.clear()
        appNavigate(APP_PAGES(tenant_id, 'TENANT_LOGIN_PAGE'))
    }, [tenant_id, appNavigate])
    return (
        <>
        &nbsp;
        </>
    )
}

export {LogoutPage}