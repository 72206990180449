import { Card, CardBody, Grid, GridItem, Heading, Stack, Text, HStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { IGauges, Ranges } from "../../interfaces/IGauges"
import GaugeChart from "react-gauge-chart"

const calcGaugeDescription = (i: IGauges): (Ranges | undefined) => {
    if (! i?.gauge_html_display?.ranges || i.gauge_html_display.ranges.length === 0) {
        return undefined
    }

    return i.gauge_html_display.ranges.filter((x: Ranges, idx: number) => {
          const [lowerLimit, upperLimit] = [x.lower_bound, x.upper_bound]
          return lowerLimit <= i.units && upperLimit >= i.units
        }).pop()
}
const calcGaugeValue = (r: IGauges): number => {
    if (!r) {
        return 0
    }
    const elemRanges = r.gauge_html_display.ranges.map(e => [e.lower_bound, e.upper_bound])
    const lowerBound: number = Math.min(...elemRanges.map(e => e[0]))
    const upperBound: number = Math.max(...elemRanges.map(e => e[1]))
    const fullPercentValue = (r.units - lowerBound) / (upperBound - lowerBound)
    const finalPercent = parseFloat(fullPercentValue.toPrecision(5))
    return finalPercent
}
const calcGaugeRange = (r: Ranges[]): number => {
    if (!r) {
        return 0
    }
    const elemRanges = r.map(e => [e.lower_bound, e.upper_bound])
    const lowerBound: number = Math.min(...elemRanges.map(e => e[0]))
    const upperBound: number = Math.max(...elemRanges.map(e => e[1]))
    return Math.abs(upperBound - lowerBound)
}
const calcArcLengths = (r: Ranges[]): number[] => {
    if (!r) {
        return [0]
    }
    const totalRangeGauge = calcGaugeRange(r)
    return r.map(e => parseFloat(((e.upper_bound - e.lower_bound)/totalRangeGauge).toPrecision(3)))
}

const DisplayGauge = (props: {gaugeData: IGauges}) => {
    const [colors, setColors] = useState<string[]>([])
    const [gaugeDescription, setGaugeDescription] = useState<{__html: string}>({__html: ''})
    const [gaugeLabel, setGaugeLabel] = useState<String>('')
    const [arcsLength, setArcLengths] = useState<number[]>([])
    const [gaugeValue, setGaugeValue] = useState<number>(0)

    useEffect( () => {
        const _gaugeValue = calcGaugeValue(props?.gaugeData || [])
        setGaugeValue(_gaugeValue)
        const gaugeResult = calcGaugeDescription(props?.gaugeData)
        if (gaugeResult) {
            setGaugeDescription({__html: gaugeResult.description })
            setGaugeLabel(gaugeResult.label)
        }
        
        const allRanges: Ranges[] = props?.gaugeData?.gauge_html_display?.ranges || []
        const _colors = allRanges.map(e => e.color)
        setColors(_colors)
        const _arcsLength = calcArcLengths(allRanges)
        setArcLengths(_arcsLength)
    }, [props])

    const widthStyle = { maxWidth: '150px'}
    return (
      <Card
        w="100%"
        direction={{ base: "column", sm: "row" }}
        overflow="hidden"
        variant="outline"
        my="2" boxShadow={'-2px 22px 24px -26px rgba(0,0,0,0.75);'}
      >
        <HStack>
          <Stack>
            <CardBody>
              <Heading size="sm" textAlign="left">
                {props.gaugeData.gauge_html_display.name}
              </Heading>
              <Text
                py="2"
                fontSize="sm"
                textAlign="left"
                dangerouslySetInnerHTML={gaugeDescription}
              />
            </CardBody>
          </Stack>
          <Grid>
            <GridItem>
              <GaugeChart
                style={widthStyle}
                arcWidth={0.15}
                id="gauge-chart1"
                percent={gaugeValue}
                hideText={true}
                arcsLength={arcsLength}
                colors={colors}
              />{" "}
            </GridItem>
            <GridItem>
              <Text fontSize="xs">
                {gaugeLabel}
              </Text>
            </GridItem>
          </Grid>
        </HStack>
      </Card>
    );
}

export { DisplayGauge }