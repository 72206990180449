
import { VStack, Flex, Skeleton, Card, CardHeader, Heading, Text, CardBody, Stack, Center, Box, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from "@chakra-ui/react"
import axios from "axios"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { DEFAULT_HEADERS, URLS } from "../app-helpers"
import { FooterPanel } from "../footer"
import { NavBar } from "../navigation/TopNavBar"
import { IFaq, IQuestion } from "../../interfaces/IFaq"

const setJsxElems = (notes: IFaq): JSX.Element => {
    if (!notes || !notes.questions || notes.questions.length === 0) {
        return <></>
    }

    const allQuestions: Array<IQuestion> = notes.questions
    return <Accordion allowMultiple>
        { allQuestions.map( (elem: IQuestion, idx: number) =>  {
            console.log(elem)
            let videoBox = <></>
            if (elem.video_tag && elem.video_tag.length > 0 && notes.videos && notes.videos[elem.video_tag]) {

                videoBox = <Center py={5}> 
                    <Box maxW='50%'
                as='video'
                controls
                src={notes.videos[elem.video_tag].link}
                poster={notes.videos[elem.video_tag].cover_image}
                title={elem.question}
                objectFit='contain' controlsList="nodownload"
                sx={{
                  aspectRatio: '16/9'
                }}
            /></Center>
            }


            return <AccordionItem key={`${idx}-faq`} my={4}>
            <h2>
                <AccordionButton>
                    <Box as='span' flex='1' textAlign='left'>
                        <Text as='b' dangerouslySetInnerHTML={{__html: elem.question}} fontSize='md' />
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <Box textAlign='left'>
                    <Text dangerouslySetInnerHTML={{__html: elem.answer}} fontSize='sm' />
                </Box>
                {videoBox}
            </AccordionPanel>
        </AccordionItem>
        } )}
    </Accordion>
}
const FAQ = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const { tenant_id } = useParams()
    const [notesJsxElems, setNotesJsxElems] = useState<JSX.Element>(<></>)

    useEffect( () => {
        const accessToken = sessionStorage.getItem('access_token') || ''

        const postParams = { tenant_id: tenant_id }
        const reqHeaders = DEFAULT_HEADERS(accessToken)
        axios
          .post(URLS.GET_FAQ, { ...postParams }, { headers: reqHeaders })
          .then( (r: any) => r?.data?.body)
          .then((resp: IFaq) => {
            setNotesJsxElems(setJsxElems(resp))
            setIsLoaded(true)
          })
          .catch((err) => {
            console.error("Unable to get navigations options for the user", err) 
            setIsLoaded(true)
          });
      }, [tenant_id])
    return (
        <VStack w="100%" tabIndex={0} autoFocus key='faq-vstack'>
            <NavBar />
            <Flex w='100%' alignContent={"center"} justifyContent={"center"}>
                <Skeleton isLoaded={isLoaded}  w='100%' m={10}>
                    <Card className={'add_box_shadow'}>
                    <CardHeader tabIndex={0} autoFocus>
                        <Heading size="lg">Frequently Asked Questions (FAQ)</Heading>
                    </CardHeader>
                    <CardBody alignContent={"center"} justifyContent={"center"}>
                        <Stack spacing="4" key='faq_card_body'>
                            <Center>
                                <Box w='100%' justifyContent={"center"}>
                                    {notesJsxElems}
                                </Box>
                            </Center>
                        </Stack>
                    </CardBody>
                    </Card>
                </Skeleton>
            </Flex>
            <Box pos="fixed" w="100%" bottom={0}>
                <FooterPanel />
            </Box>
        </VStack>
    )
}

export { FAQ }