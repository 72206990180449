import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DEFAULT_HEADERS, URLS, ENCODE_PARAMS } from "../../app-helpers";
import { IReportPanels, IReportView } from "../../../interfaces/IReportView";
import { useDisclosure, VStack, Flex, Center, Container, Tooltip, Card, CardHeader, Heading, CardBody, Grid, GridItem, Tag, TagLabel, Stat, StatLabel, Code, TagLeftIcon, StatHelpText, StatNumber, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay, Box, HStack, Spinner, CardFooter, Link, Button, Icon } from "@chakra-ui/react";
import { NavBar } from "../../navigation/TopNavBar";
import { ThumbnailView } from "./PanelThumbnailView";
import { CalendarIcon } from "@chakra-ui/icons";
import { FooterPanel } from "../../footer";
import { ToggleAccess } from "./AccessToggle";
import { ReportAccessConfirmation } from "./ReportAccessConfirmationModal";
import '../../styles.css'
import { MdLocalPrintshop } from "react-icons/md";

const ViewMyReport = () => {

    const [loggedInUserRole, setLoggedInUserRole] = useState<string>('')
    const [initialRefCoach, finalRefCoach] = [useRef(null), useRef(null)] 
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef: RefObject<any> = useRef()
    const appNavigate = useNavigate()
    const [queryParams] = useSearchParams()
    const { tenant_id } = useParams()
    const [reportDate, setReportDate] = useState<string>()  
    const [clientAccessRestricted, setClientAccessRestricted] = useState<boolean>(true)
    const [accessOpenModal, setAccessOpenModal] = useState(false)
    const [ panelsHtmlDisplay, setPanelsHtmlDisplay] = useState<JSX.Element>()
    let clientName = queryParams.get('c')
    const [id, kitId, createdOn] = [queryParams.get('i') || '', queryParams.get('k') || '', queryParams.get('ct')]
    const [reportName, setReportName] = useState<string>(queryParams.get('n') || '')
    const accessToken = sessionStorage.getItem('access_token') || queryParams.get("qat") || ''
    if (!clientName || (clientName && clientName === 'null')) {
        clientName = 'Not Assigned'
    }
    

    const printVersionHandler = () => {
        const pdfParams = {
            'cn': clientName,
            'qat': accessToken,
            'k': kitId,
            'id': id,
            'ct': createdOn
        }
        const pdfHrefLink: string = `/t/${tenant_id}/my-purchase/reports/print-view?${ENCODE_PARAMS(pdfParams)}` 
        appNavigate(pdfHrefLink)
    }
    
    useMemo(() => {
        const postParams = { tenant_id: tenant_id };
        const reqHeaders = DEFAULT_HEADERS(accessToken);
        axios
        .post(URLS.GET_NAV_BAR, { ...postParams }, { headers: reqHeaders })
        .then((res) => res?.data?.role)
        .then((user_role) => { 
            if (user_role) {
                setLoggedInUserRole(user_role)
            } 
        })
        .catch((err) => {
            console.error("Unable to get user role for the logged in user", err)
        })
    }, [accessToken, tenant_id])

    useEffect(() => {
        onOpen()
        const params = {
            kit_id: kitId, report: id, tenant_id: tenant_id
        }
        if (createdOn) {
            const dt = new Date(parseInt(createdOn))
            const monStr = dt.getMonth() + 1
            const dtStr = `${dt.getFullYear()}-${(monStr < 10 ? '0' + monStr : monStr)}-${(dt.getDate() < 10 ? '0'+dt.getDate(): dt.getDate())}`
            setReportDate(dtStr)
        }
        
        axios.post(URLS.VIEW_REPORT, {...params}, { headers: DEFAULT_HEADERS() })
        .then((res) => res?.data?.body)
        .then((data: IReportView) => {
            if (data.created_on) {
                const dt = new Date(data.created_on)
                const monStr = dt.getMonth() + 1
                const dtStr = `${dt.getFullYear()}-${(monStr < 10 ? '0' + monStr : monStr)}-${(dt.getDate() < 10 ? '0'+dt.getDate(): dt.getDate())}`
                setReportDate(dtStr)
            }
            if(data.access_status === 'Granted') {
                setClientAccessRestricted(false)
            }
            setReportName(data.name)
            updatePanelsDisplay(data)
        })
        .catch((err) => {
            console.error('Unable to find the snapshot', err)
        })
    }, [tenant_id, kitId, id, createdOn])

    const panelNavigationHook = (panel_id: string, kit_id: string, tenant_id: string, report: string) => {
        const qParams = {
            panel_id: panel_id, kit_id: kit_id, tenant_id: tenant_id, report: report, reportTitle: reportName, loggedInUserRole: loggedInUserRole
        }
        appNavigate(`/t/${tenant_id}/my-purchase/reports/panel/view?${ENCODE_PARAMS(qParams)}`)
    }
    
    const jsx_img_src: string = `https://media.mosaicbio.io/media/${tenant_id}`
    const updatePanelsDisplay = (panelsData: IReportView) => {
        const sortedModules = panelsData.report_modules.sort( (elem1: IReportPanels, elem2: IReportPanels) => elem1.sort_order < elem2.sort_order ? -1 : 1)
        const allPanelsLayout: JSX.Element[] = sortedModules.map( (v: IReportPanels, idx: number) => {
            const params = { img_src: `${jsx_img_src}/${v.banner_link}`, img_alt: v.name, title: v.name, description: v.name, loggedInUserRole: loggedInUserRole, 
            panel_id: v.id , kit_id: kitId, tenant_id: tenant_id || '', report:id, navHook: panelNavigationHook, reportTitle: reportName }
            return <GridItem key={`${idx}-grd-item-${v.id}`}> <ThumbnailView key={`${idx}-thu-${v.id}`} {...params} /> </GridItem>
        } )
        
        setPanelsHtmlDisplay(<Grid templateColumns='repeat(3, 1fr)' gap={6}>{allPanelsLayout}</Grid>)
        onClose()
    }

    const toggleClientAccess = (event?: any) => {
        setAccessOpenModal(true)
    }
 
    return (
        <VStack w="100%">
            <NavBar />
            <Flex  width={"100vw"} alignContent={"center"} justifyContent={"center"}>
                <Center>
                    <Container maxW="5xl">
                        <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered closeOnOverlayClick={false}>
                            <AlertDialogOverlay>
                            <AlertDialogContent>
                                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Please Wait
                                </AlertDialogHeader>
                                <AlertDialogBody>
                                <HStack spacing='40px'>
                                    <Box>Loading.....</Box>
                                    <Box><Spinner thickness='8px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
                                </HStack>              
                                </AlertDialogBody>
                            </AlertDialogContent>
                            </AlertDialogOverlay>
                        </AlertDialog>
                        <Card  className={'add_box_shadow'} my={10}>
                        <CardHeader>
                            <HStack>
                                <Heading ref={initialRefCoach} size="md" mb={2}>{`${reportName}`} </Heading>
                                <ToggleAccess {...{isLocked:clientAccessRestricted, loggedInUserRole:loggedInUserRole, fnToggleAccess:toggleClientAccess}} />
                            </HStack>
                            <Stat>
                                <StatLabel>
                                    <Tag borderRadius='full' variant='solid' colorScheme='blue'>
                                        <TagLeftIcon as={CalendarIcon} />
                                        <TagLabel>{reportDate}</TagLabel>
                                    </Tag>
                                </StatLabel>
                                <StatNumber fontSize='md'>
                                    <HStack my={'2'}>
                                        <Box>{clientName}</Box>
                                        <Box mx={'1'}>
                                            <Icon as={MdLocalPrintshop} color={'linkedin'} boxSize={6} onClick={printVersionHandler} />
                                        </Box>
                                    </HStack>
                                </StatNumber>
                                <StatHelpText><Code colorScheme='yellow' fontSize='xs'>{kitId}</Code> </StatHelpText>
                            </Stat> 
                        </CardHeader>
                        <CardBody>
                            {panelsHtmlDisplay}
                        </CardBody>
                        <CardFooter>
                            <ReportAccessConfirmation {...{ isLocked: clientAccessRestricted, tenant_id: tenant_id, 
                                                            mosaicId: kitId, reportId: id, reportName: reportName, 
                                                            clientName: clientName, initialRef: initialRefCoach, 
                                                            finalRef: finalRefCoach, setIsLocked: setClientAccessRestricted, 
                                                            toggleModel: accessOpenModal, resetToggleHandler: () => { setAccessOpenModal(false); } }} />
                        </CardFooter>
                        </Card>
                    </Container>
                </Center>
            </Flex>
            <Box w='100%'>
                <FooterPanel />
            </Box> 
        </VStack>
    )
}

export { ViewMyReport }