import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  DrawerBody,
  Flex,
  HStack,
  Icon,
  List,
  ListIcon,
  ListItem,
  Spacer, Grid
} from "@chakra-ui/react";
import { URLS, DEFAULT_HEADERS } from '../app-helpers'
import { MdExitToApp } from "react-icons/md";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useRef, useState, useEffect, useContext } from "react";
import { NavBarRenderer } from "./SideDrawerBar";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { getAppPageUrl } from '../../AppConstants'
import { IconAndLogoMenuBar } from "./IconAndLogoBar";
import { MyShortcuts } from "./QuickShortcuts";
import React from "react";
import { AddNewUser } from "./AddUserModal";
import { TenantBrandingContext } from "../../TenantBrandingContext";
import { ITenantConfig } from "../../interfaces/ITenantConfig";

function useInterval(callback: any, delayInSeconds: number) {
  const savedCallback = useRef<any>();
  const delayInMilliseconds: number = delayInSeconds * 1000
 
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
 
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delayInMilliseconds !== null) {
      let id = setInterval(tick, delayInMilliseconds);
      return () => clearInterval(id);
    }
  }, [delayInMilliseconds]);
}

const navForChildren = (tenantBranding: ITenantConfig, navList: any[], clickHandler: any) => {
  if (!navList) {
    navList = [];
  }
  
  const reactHtmlNavTags = navList.map((nav: any, i: number) => {
    if (nav["children"] === undefined || nav["children"]?.length === 0) {
      return (
        <AccordionItem className={`${nav.parent_nav}-NOT_ASSIGNED-${i}-list-item`} key={`${nav.i || 0}-${nav.parent_nav}-single`} color={tenantBranding.color_palette?.font_color}>
          <h2>
            <AccordionButton onClick={() => clickHandler(`${nav.parent_nav}`)}>
              <Box as="span" flex="1" textAlign="left">
                {nav.parent_nav}
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>
      );
    } else {
      return (
        <AccordionItem key={`${nav.id}-${nav.parent_nav}`} color={tenantBranding.color_palette?.font_color}>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                {nav.parent_nav}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <List spacing={3}>
              {nav["children"].map((child: any, idx: number) => (
                <ListItem cursor='pointer' className={`${nav.parent_nav}-${child.title}-${idx}-list-item`} key={`list-item-${nav.id}-${idx}`} onClick={() => clickHandler(`${nav.parent_nav} ${child.title}`)}>
                  <ListIcon
                    as={ChevronRightIcon}
                    color={tenantBranding.color_palette?.font_color}
                  />
                  {child.title}
                </ListItem>
              ))}
            </List>
          </AccordionPanel>
        </AccordionItem>
      );
    }
  });
  return (<DrawerBody>
      <Accordion defaultIndex={[0]} allowMultiple>
        {reactHtmlNavTags}
        <AccordionItem key='10000'>
          <h2>
            <AccordionButton onClick={() => clickHandler(`Logout`)}>
              <Box as="span" flex="1" textAlign="left" color={tenantBranding.color_palette?.font_color}>
                <Flex>
                  Logout
                  <Icon as={MdExitToApp} boxSize={6}  />
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>
      </Accordion>
    </DrawerBody>)
};
const NavBar = () => {
  const tenantBranding = useContext(TenantBrandingContext)
  const [initialRefClient, finalRefClient] = [React.useRef(null), React.useRef(null)] 
  const [openAddClientModel, setOpenAddClientModel] = useState(false)
  const [initialRefCoach, finalRefCoach] = [React.useRef(null), React.useRef(null)] 
  const [openAddCoachModel, setOpenAddCoachModel] = useState(false)
  const [navIsSet, setNavIsSet] = useState(false)
  const [loggedInUserRole, setLoggedInUserRole] = useState<string>('')
  const [topNavShortcutsMenu, setTopNavShortcutsMenu] = useState<string[]>([])
  const [queryParams] = useSearchParams()
  const appNavigate = useNavigate()
  const { tenant_id } = useParams()
  const [accessToken, setAccessToken] = useState(queryParams.get("qat") || sessionStorage.getItem('access_token') || '');
  
  const timeoutErrorPath: string = `/t/${tenant_id}/login?error=Your%20session%20has%20timed%20out.%20Please%20login%20again.`
 
  const renewToken = () => {
    const reqHeaders = DEFAULT_HEADERS(accessToken)
    axios
      .post(URLS.REAUTH, { ...{'tenant_id': tenant_id} }, { headers: reqHeaders })
      .then((res) => {
        const responeMessge = res?.data?.body
        const newAccessToken = responeMessge?.access_token
        const userRoleToken = responeMessge.user_role
        sessionStorage.removeItem('tenant.app.full_name')
        sessionStorage.removeItem('tenant.app.user_email')
        sessionStorage.removeItem('access_token')
        if (userRoleToken) {
          setLoggedInUserRole(userRoleToken)
        }
        if (responeMessge.shortcuts) {
          setTopNavShortcutsMenu(responeMessge.shortcuts || [])
        }
        if (accessToken === undefined) {
          appNavigate(timeoutErrorPath)
        } else {
          sessionStorage.setItem("tenant.app.user_email", res?.data?.body?.user_email);
          sessionStorage.setItem("tenant.app.full_name", res?.data?.body?.user_full_name);
          sessionStorage.setItem("access_token", newAccessToken);
          setAccessToken(newAccessToken)
        }
      })
      .catch((err) => {
        console.error("Unable to renew token", err)
        appNavigate(timeoutErrorPath)
      })
  }
  useInterval(renewToken, 1200)

  const navHandler = (navTextClicked: any) => {
    if(navTextClicked?.toLowerCase() === 'invite new client') {
      setOpenAddClientModel(true)
      return
    }
    else if(navTextClicked?.toLowerCase() === 'invite new coach') {
      setOpenAddCoachModel(true)
      return
    }
    
    appNavigate(getAppPageUrl(tenant_id, navTextClicked)) 
  }

  const [navLayout, setNavLayout] = useState(navForChildren(tenantBranding, [], navHandler));

    
    useEffect( () => {
      const postParams = { tenant_id: tenant_id };
      const reqHeaders = DEFAULT_HEADERS(accessToken);
      axios
      .post(URLS.GET_NAV_BAR, { ...postParams }, { headers: reqHeaders })
      .then((res) => {
        const navOptions = res?.data?.perms;
        if (res?.data?.role) {
          setLoggedInUserRole(res?.data?.role)
        }
        if (res?.data?.shortcuts) {
          setTopNavShortcutsMenu(res?.data?.shortcuts || [])
        }
        if (navOptions && navOptions.length > 0) {
          setNavLayout(navForChildren(tenantBranding, navOptions, navHandler));
          setNavIsSet(true);
        }
      })
      .catch((err) => {
        console.error("Unable to get navigations options for the user", err);
        setNavIsSet(false)
        appNavigate(timeoutErrorPath)
      });
    }, [tenant_id]) 

  const navProps: any = {
    navJsxElement: navLayout,
  };

  if (tenant_id) {
    return (
      <Flex minH="60px" w='100%' bgColor={tenantBranding.color_palette.top_banner_background_color}>
        <Grid templateColumns='repeat(3, 1fr)' gap={6} bgColor={tenantBranding.color_palette.top_banner_background_color}>
        <NavBarRenderer {...navProps} />
        <Spacer />
        <HStack w='100%' bgColor={tenantBranding.color_palette.top_banner_background_color} key='hstack-v1'>
          <Box ><IconAndLogoMenuBar {...{ tenant_id: tenant_id, access_token: accessToken }} /></Box>
          <Spacer />
          <Box minW='450px' justifyContent='center'><MyShortcuts {...{ tenant_id: tenant_id, access_token: accessToken, loggedInUserRole: loggedInUserRole, topNavShortcutsMenu: topNavShortcutsMenu }} /></Box>
        </HStack>
        </Grid>
        <AddNewUser {...{initialRef: initialRefClient, loggedInUserRole: loggedInUserRole, finalRef: finalRefClient, tenant_id: tenant_id, userRole: 'client', toggleModel: openAddClientModel, resetToggleHandler: () => {setOpenAddClientModel(false)}}} />
        <AddNewUser {...{initialRef: initialRefCoach, loggedInUserRole: loggedInUserRole, finalRef: finalRefCoach, tenant_id: tenant_id, userRole: 'coach', toggleModel: openAddCoachModel, resetToggleHandler: () => {setOpenAddCoachModel(false)}}} />
      </Flex>
    );
  } else {
    return <></>;
  }
};

export { NavBar };
