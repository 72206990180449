import { Alert, AlertIcon } from "@chakra-ui/react";

const AllSalesFinalAsText = (props?: {
  showJustMsg?: boolean;
  showAlertIcon?: boolean;
}) => {
  const showJustMsg = props?.showJustMsg === true;
  const showAlertIcon = props?.showJustMsg === true;
  const msg: string = "Please carefully review your order before confirming your purchase. All sales are considered final. Due to the nature of DNA kits, we are not able to offer returns or refunds for any products or services sold through Mosaic Biodata Inc.";

  return showJustMsg ? (
    <>{msg}</>
  ) : (
    <Alert status="warning" fontSize="xs">
      {showAlertIcon ? <AlertIcon /> : <></>}
      {msg}
    </Alert>
  );
};

export { AllSalesFinalAsText };
