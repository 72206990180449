import { Card, CardBody, Stack, Heading, Image } from "@chakra-ui/react"

const ThumbnailView = (props: {
    img_src: string, img_alt: string, title: string, description: string, 
    panel_id: string, kit_id: string, tenant_id: string, report: string,
    navHook: (panel_id: string, kit_id: string, tenant_id: string, report: string) => void
}) => {
  const navHandler = () => {
    props?.navHook(props?.panel_id, props?.kit_id, props?.tenant_id, props?.report)
  }
    return (<Card maxW='sm'>
    <CardBody onClick={navHandler}>
      <Image
        src={props.img_src}
        alt={props.img_alt}
        borderRadius='md'
      />
      <Stack mt='6' spacing='3'>
        <Heading size='xs'>{props.title}</Heading>
      </Stack>
    </CardBody>
  </Card>)
}

export { ThumbnailView }