import { Flex, Center, Container, VStack, Heading, HStack, Box } from "@chakra-ui/react"
import { AddToCart } from "./AddToCart"
import { ProductDisplay } from "./ProductDisplay"
import { AllSalesFinalAsText } from "./AllSalesFinalText"

const PlaceOrderToKits = (props: {productData: any, tenantId: any, coachEmail: any, readyToPurchaseHook: React.Dispatch<React.SetStateAction<boolean>>, setOrderInCartHook: React.Dispatch<React.SetStateAction<{}>>}) => {
    return (
        <Flex  width={"100vw"} height={"50vh"} alignContent={"center"} justifyContent={"center"}>
          <Center>
            <Container centerContent maxW="4xl">
              <VStack>
                <Flex w="100%" textAlign="left">
                  <Heading as="h3" size="lg" noOfLines={1}>
                    {props.productData.title}
                  </Heading>
                </Flex>
                <HStack textAlign="left">
                  <ProductDisplay />
                  <AddToCart {...{ data: props.productData, tenantId: props.tenantId, coachEmail: props.coachEmail, readyToPurchaseHook: props.readyToPurchaseHook, orderInCartHook: props.setOrderInCartHook }} />
                </HStack> 
                <Box mt={10}><AllSalesFinalAsText {...{showAlertIcon: false}}/></Box>
              </VStack>
            </Container>
          </Center>
        </Flex>
    )
}

export { PlaceOrderToKits }