import { Box, Card, CardBody } from "@chakra-ui/react";
import dna_kit_image from "../../assets/kit_image.png";

const ProductDisplay = () => {
  return (
    <Box>
      <Card>
        <CardBody>
          <img src={dna_kit_image} className="mosaic-dna-kit-img" alt='Mosaic Genetic Kits' />
        </CardBody>
      </Card>
    </Box>
  );
};

export { ProductDisplay };