import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useRef, useState } from "react";
import { DEFAULT_HEADERS, FN_DATE_COMP, REPORT_SERVER_URLS, URLS } from '../app-helpers'
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { IClient } from "../../interfaces/IClient";
import { useParams } from "react-router-dom";
import axios from "axios"
import { BlankValueRenderer } from "../ag-grid/BlankValueRenderer";
import { Box, VStack, Text } from "@chakra-ui/react"; 
import { SendWelcomeEmail } from "../navigation/SendWelcomeEmail";
import { StatusPanelDef } from "ag-grid-enterprise";

const MyCoachesDashboard = (props: {reqFilter: string, userRole: string | undefined}) => {

  const { tenant_id } = useParams();
  const [cid, setCid] = useState<string>()
  const [recpName, setRecpName] = useState<string>()
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [initialRefCoach, finalRefCoach] = [useRef(null), useRef(null)] 
  const [rowData, setRowData] = useState<Array<IClient>>();

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      minWidth: 150,
      filter: "agTextColumnFilter",
    };
  }, []);

  const gridCols: any = [
    {
      field: "full_name",
      headerName: "Name",
      cellRenderer: BlankValueRenderer,
      sort: "asc",
    },
    { field: "num_clients", headerName: "# of Clients" },
    { field: "email", headerName: "Email" }
  ];
  const onGridReady = useCallback(
    (params: any) => {
      const coachHeaders = DEFAULT_HEADERS()
      const coachsUrl = REPORT_SERVER_URLS(tenant_id, 'coach')['USER_DETAILS']
      axios.get(coachsUrl, { headers: coachHeaders })
        .then((res) => res?.data || [])
        .then((data: IClient[]) => {
          setRowData(data);
        })
        .catch((err) => {
          console.error("Unable to get client data for the coach", err);
        });
    },
    [tenant_id, props.reqFilter]
  );

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: { comparator: FN_DATE_COMP },
      },
    };
  }, []);

    const gridOptions = {
    }

    const autoSizeStrategy: any = useMemo(() => {
        return {
          type: 'fitGridWidth',
        };
      }, [])
    
      const statusBar = useMemo<{
          statusPanels: StatusPanelDef[];
        }>(() => {
          return {
            statusPanels: [
              { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
              { statusPanel: 'agTotalRowCountComponent', align: 'center' },
              { statusPanel: 'agFilteredRowCountComponent' },
              { statusPanel: 'agSelectedRowCountComponent' },
              { statusPanel: 'agAggregationComponent' },
            ],
          };
        }, [])

      const getContextMenuItems = useCallback((params: any) => {
          const result = [
              {
                  name: 'Send Welcome Email',
                  action: () => { 
                    setOpenEmailModal(true)
                  },
                  tooltip: 'Click here to send a welcome email.'
              },
              'copy',
              'copyWithHeaders',
              'separator',
              'export'
          ]
          return result
      }, [])
      
      
      
    return (
      <VStack>
        <Box>
        <div className="ag-theme-quartz" style={{ height: '450px', minWidth: '850px' }}>
            <AgGridReact autoSizeStrategy={autoSizeStrategy} 
            autoGroupColumnDef={autoGroupColumnDef}
            onCellContextMenu={(event: any) => {setCid(event.data.cid); setRecpName(event.data.full_name); event.node.setSelected()}}
            gridOptions={gridOptions}
            rowData={rowData} columnTypes={columnTypes}  
            columnDefs={gridCols}
            defaultColDef={defaultColDef} statusBar={statusBar}
            getContextMenuItems={getContextMenuItems}
            onGridReady={onGridReady}
            overlayLoadingTemplate={
              '<div style="position:absolute;top:0;left:0;right:0; bottom:0; background: url(https://ag-grid.com/images/ag-grid-loading-spinner.svg) center no-repeat" aria-label="loading"></div>'
            }  />
        </div>
          </Box>
          <Box>
              <Text fontSize='xs' as='i'>To send a welcome email to your coaches, right-click on the name of the coach to which you want to send the email, then click <Text as='b'>Send Welcome Email</Text>.</Text>
          </Box>
          <Box>
                <SendWelcomeEmail {...{ recpName: recpName, tenantId: tenant_id, cid: cid, initialRef: initialRefCoach, finalRef: finalRefCoach, tenant_id: tenant_id, toggleModel: openEmailModal, resetToggleHandler: () => { setOpenEmailModal(false); } }} />
          </Box>
        </VStack>
    );
}

export { MyCoachesDashboard }