
const APP = {
    API_SERVER_BASE_URL: process.env.REACT_APP_SERVER_BASE_URL,
    REPORTING_APP_SERVER_BASE_URL: process.env.REACT_APP_REPORTING_SERVER_BASE_URL,
    GOOGLE_MAPS_API_KEY: `${process.env.GOOGLE_MAPS_API_KEY}`,
    STRIPE_API_KEY: `${process.env.STRIPE_API_KEY}`
};

const HTTP_SERVER_URLS = {
    AUTH_TOKEN: sessionStorage.getItem('access_token'),
    TENANT_DETAILS: `${APP.API_SERVER_BASE_URL}/tenants`,
    SUPPORT_DETAILS: `${APP.API_SERVER_BASE_URL}/support`,
    SUPPORT_AUTH: `${APP.API_SERVER_BASE_URL}/support/login`,
    AUTH: `${APP.API_SERVER_BASE_URL}/tenants/login`,
    INVITE_USERS: `${APP.API_SERVER_BASE_URL}/tenants/invite-users`,
    SEND_WELCOME_EMAIL: `${APP.API_SERVER_BASE_URL}/tenants/invite-users/welcome-email`,
    ADMIN_MY_TASKS: `${APP.API_SERVER_BASE_URL}/tenants/admin`,
    REAUTH: `${APP.API_SERVER_BASE_URL}/tenants/login/reauth`,
    REGISTER_KIT: `${APP.API_SERVER_BASE_URL}/tenants/my-purchases/kit/register`,
    VIEW_REPORT: `${APP.API_SERVER_BASE_URL}/tenants/my-purchases/kit/report`,
    TOGGLE_REPORT_ACCESS: `${APP.API_SERVER_BASE_URL}/tenants/my-purchases/kit/report/toggle-client-access`,
    REPORT_SNAPSHOT: `${APP.API_SERVER_BASE_URL}/tenants/my-purchases/kit/report/snapshot`,
    PURCHASE_SUMMARY: `${APP.API_SERVER_BASE_URL}/tenants/my-purchases`,
    MY_CLIENT_DETAILS: `${APP.API_SERVER_BASE_URL}/tenants/my-clients`,
    PURCHASE_DETAILS: `${APP.API_SERVER_BASE_URL}/tenants/my-purchases/details`,
    GET_TOOL_TIPS: `${APP.API_SERVER_BASE_URL}/tenants/my-purchases/kit/report/tool-tips`,
    PANEL_DETAILS: `${APP.API_SERVER_BASE_URL}/tenants/my-purchases/kit/report/panel`,
    PANEL_NOTES: `${APP.API_SERVER_BASE_URL}/tenants/my-purchases/kit/report/panel/notes`,
    UPSERT_PANEL_NOTES: `${APP.API_SERVER_BASE_URL}/tenants/my-purchases/kit/report/panel/notes/upsert`,
    GET_KIT_DETAILS: `${APP.API_SERVER_BASE_URL}/get-kit-details`,
    GET_NAV_BAR: `${APP.API_SERVER_BASE_URL}/tenants/user-access`,
    GET_FAQ: `${APP.API_SERVER_BASE_URL}/tenants/user-access/faq`,
    GET_UI_SESSION_TOKENS: `${APP.API_SERVER_BASE_URL}/tenants/user-access/session`,
    GET_STRIPE_CHECKOUT_SESSION_ID: `${APP.API_SERVER_BASE_URL}/tenants/stripe`,
    CONFIRM_STRIPE_SESSION_ID: `${APP.API_SERVER_BASE_URL}/tenants/stripe/confirm`,
    SEND_PASSWORD_RESET_CODE: `${APP.API_SERVER_BASE_URL}/tenants/password-reset-code`,
    VALIDATE_PASSCODE_UPDATE_PASSWORD: `${APP.API_SERVER_BASE_URL}/tenants/password-reset-code/update`
};

const REPORT_SERVER_URLS = (tenantId: any, user_role:any): any => {
    const path_dict = {
        KITS_SUMMARY: `${APP.REPORTING_APP_SERVER_BASE_URL}/reports/t/${tenantId}/kits/view/all`,
        REPORT_SNAPSHOT: `${APP.REPORTING_APP_SERVER_BASE_URL}/reports/t/${tenantId}/reports/snapshot`,
        USER_DETAILS: `${APP.REPORTING_APP_SERVER_BASE_URL}/reports/t/${tenantId}/users/${user_role}/view/all`,
        TOGGLE_REPORT_CLIENT_ACCESS: `${APP.REPORTING_APP_SERVER_BASE_URL}/reports/t/${tenantId}/cache/toggle/access`,
        ADMIN_TRIGGER_CID_UPDATE: `${APP.REPORTING_APP_SERVER_BASE_URL}/reports/t/${tenantId}/users/upsert`,
        ADMIN_TRIGGER_KIT_CACHE_UPDATE: `${APP.REPORTING_APP_SERVER_BASE_URL}/reports/t/${tenantId}/cache/kit`,
        ADMIN_TRIGGER_USER_CACHE_UPDATE: `${APP.REPORTING_APP_SERVER_BASE_URL}/reports/t/${tenantId}/cache/users`,
    }
    return path_dict
}

const convertToDateObject = (dt: string): Date | undefined => {
    if (!dt) {
        return undefined
    }

    return new Date(dt)
}

const convertToTimeObject = (dt: string): number | undefined => {
    const dtObj = convertToDateObject(dt)
    if (!dtObj) {
        return undefined
    }

    return dtObj.getTime()
}

const myDateComparator = (date1: string, date2: string): number => {
    // Expected format: YYYY-MM-DD Example: Jan 1, 2023 is 2023-01-01

    const date1Number: number | undefined = convertToTimeObject(date1);
    const date2Number: number | undefined = convertToTimeObject(date2);
    if (date1Number === undefined && date2Number === undefined) {
        return 0
    }
    else if(date1Number === undefined) {
        return -1
    }
    else if (date2Number === undefined) {
        return 1
    }
    return date1Number - date2Number
}

export const uidGenerator = (len: number): string => {
    let result = '';
    const allCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = allCharacters.length;
    let counter = 0;
    while (counter < len) {
      result += allCharacters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

const encodeQueryParams = (params: any): string => {
    if (! params) { return ''}
    let encodedStringValue = []
    for (let p in params) {
        encodedStringValue.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]))
    }
    return encodedStringValue.join('&')
}
const APP_DEFAULT_HEADERS = (access_token?: string) => {
    return {
        'Accept':'application/json',
        'Content-Type':'application/json', 
        'Authorization': access_token || sessionStorage.getItem('access_token')  
    }
}

const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export { APP as ENV, numberWithCommas as COMMA_SEPARATOR, HTTP_SERVER_URLS as URLS, APP_DEFAULT_HEADERS as DEFAULT_HEADERS, myDateComparator as FN_DATE_COMP, encodeQueryParams as ENCODE_PARAMS, REPORT_SERVER_URLS};