import { Tabs, TabList, Tab, TabPanels, TabPanel, Flex } from "@chakra-ui/react"; 
import React from "react";  

const MyTabbedPanel = (props: {userRole: any, allowedTabs: any[] | undefined, tabIndex: any}) => {
  
  return (
    <Flex tabIndex={0} autoFocus> 
        <Tabs variant="soft-rounded" isLazy defaultIndex={props?.tabIndex || 0}>
          <TabList hidden={(props?.allowedTabs || []).length < 2}>
            {(props.allowedTabs || []).map((elem: any, idx: number) => {
              return (
                <Tab key={`tab-${idx}-${elem.panel_name}`}>
                  {elem.panel_name}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            {(props.allowedTabs || []).map((elem: any, idx: number) => {
              return (
                <TabPanel key={`panel-${idx}-${elem.panel_name}`}>
                  {elem.panel_component}
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs> 
    </Flex>
  );
};

export { MyTabbedPanel }