// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.embedded-checkout-box,
.embedded-checkout-box iframe {
    width: 100% !important; 
}
 `, "",{"version":3,"sources":["webpack://./src/components/shop/StripeCheckout.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;AAC1B","sourcesContent":[".embedded-checkout-box,\n.embedded-checkout-box iframe {\n    width: 100% !important; \n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
