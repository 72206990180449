import { Elements, AddressElement } from "@stripe/react-stripe-js";
import { IAddress } from "../../interfaces/IAddress" 
import { Box, Container, Grid, Heading, Card, VStack, Text, Button, ButtonGroup, IconButton, GridItem, CardHeader, CardBody, CardFooter, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay, HStack, Spinner } from "@chakra-ui/react"; 
import { useEffect, useRef, useState } from "react";
import { GrDeliver } from "react-icons/gr";
import { ShowPendingCart } from "./ShowCart"; 

const StripeShippingAddress = (props: {setDeliveryAddress: React.Dispatch<React.SetStateAction<IAddress | undefined>>, 
                                        stripeSession: any, googleMapsApiKey: any, setShippingAddressCollected: React.Dispatch<React.SetStateAction<boolean>>, 
                                        deliveryAddress: IAddress | undefined}) => {
    const [stripeElems, setStripeElems] = useState<JSX.Element>(<></>)
    const [errorMsg, setErrorMsg] = useState<string>()
    const [addressIsValid, setAddressIsValid] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [initialRef, finalRef, cancelRef] = [useRef(null), useRef(null), useRef(null)]
    const setAddressHandler = (event: any) => {
 
        if (event.complete && event?.value?.address) {
            const address = event.value.address;
            console.log('address', event.value)
            const shippingAddress: IAddress = {
                    owner_name: event?.value?.name,
                    street_address_line_1: address?.line1,
                    street_address_line_2: address?.line2,
                    city: address?.city,
                    state: address?.state,
                    country: address?.country,
                    postal_code: address?.postal_code,
                    phone: event?.value?.phone
                }
            props.setDeliveryAddress(shippingAddress)
            if (shippingAddress.street_address_line_1 
                && shippingAddress.city && shippingAddress.state 
                && shippingAddress.country && shippingAddress.postal_code && shippingAddress.owner_name) {
                    props.setDeliveryAddress(shippingAddress)
                    setAddressIsValid(true)
                    props.setShippingAddressCollected(true)
                }
        }
    }
    
    const startCheckout = (event: any) => {
        if (!props.deliveryAddress) {
            setErrorMsg('All fields are mandatory.')
        } 
        else if (!props.deliveryAddress.owner_name) {
            setErrorMsg('Please provide a valid name.')
        } 
        else if (!props.deliveryAddress.street_address_line_1) {
            setErrorMsg('Please provide a valid address line 1.')
        }
        else if (!props.deliveryAddress.phone) {
            setErrorMsg('Please provide a valid phone.')
        }
    }
    useEffect( () => {
        onOpen()
        if (props.stripeSession && props.googleMapsApiKey) {
            setStripeElems(
            <form>
                <Elements stripe={props.stripeSession}>
                    <AddressElement 
                        options={{mode: 'shipping', blockPoBox: true, 
                                validation: { phone: { required: 'always' }}, fields: { phone: 'always' },
                                defaultValues: { address: {country: 'US'}},
                                autocomplete: {
                                    mode: "google_maps_api",
                                    apiKey: props.googleMapsApiKey,
                                }}} 
                                onChange={setAddressHandler} />
                </Elements>
            </form>
            )
            onClose()
        }
    }, [props.stripeSession, props.googleMapsApiKey])
    return (
        <Container
            maxW="2xl"
            py={{ base: "12", md: "24" }}
            px={{ base: "0", sm: "8" }} >
                <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered closeOnOverlayClick={false}>
                    <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Please Wait
                        </AlertDialogHeader>
                        <AlertDialogBody>
                        <HStack spacing='40px'>
                            <Box>Preparing checkout.....</Box>
                            <Box><Spinner thickness='8px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
                        </HStack>              
                        </AlertDialogBody>
                    </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
                <Card  boxShadow='xl' borderRadius={{ base: "none", sm: "xl" }}>
                    <CardHeader>
                        <Heading size='md'>Enter Shipping Address</Heading>
                    </CardHeader>
                    <CardBody>
                        {stripeElems}
                    </CardBody>
                    <CardFooter my={1}>
                        <Grid gridTemplateRows={'50% 50%'} width={'100%'} justifyContent={'right'}>
                        <GridItem width={'100%'}>
                            <Text fontSize={'xs'} color='tomato'>{errorMsg}</Text>
                        </GridItem>
                        <GridItem width={'100%'}>
                            <ButtonGroup mb={5} size='sm' isAttached variant='outline' colorScheme="telegram" px={2}>
                                <Button type="submit" 
                                colorScheme="telegram" 
                                disabled={!addressIsValid} onClick={startCheckout}
                                isLoading={addressIsValid} variant='solid'
                                loadingText="Validating">
                                Next
                                </Button>
                                <IconButton aria-label='Save' icon={<GrDeliver />} disabled />
                            </ButtonGroup>
                        </GridItem>
                        </Grid>
                    </CardFooter>
                </Card>
        </Container>
    )
}

export { StripeShippingAddress }