import { Tabs, TabList, Tab, TabPanels, TabPanel, Flex } from "@chakra-ui/react";
import { MyReportsDashboard } from "../Reports/MyReports"; 
import React from "react";
import { KitsDashboard } from "../kits/KitsDashboard";

const ShowPurchaseDataGrid = () => {
  return (
    <Flex>
        <Tabs variant="soft-rounded">
      <TabList>
        <Tab>Kits</Tab>
        <Tab>My Reports</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <KitsDashboard {...{userRole: ''}}/>
        </TabPanel>
        <TabPanel>
          <MyReportsDashboard {...{userRole: ''}} />
        </TabPanel>
      </TabPanels>
    </Tabs>
    </Flex>
  );
};

export { ShowPurchaseDataGrid }