import * as React from "react"
import { Login } from './components/login' 
import { extendTheme } from '@chakra-ui/react'
import { LogoutPage } from "./components/logout" 
import { PageNotFound } from "./components/404Page"
import { ShopKits } from "./components/shop/OrderKits"
import { KitDetails } from "./components/kits/KitView"
import { KitWorkflow } from "./components/kits/KitWorkflow"
import { ViewPanelData } from "./components/Panels/ViewPanelData"
import { AllMyPurchases } from "./components/Purchases/MyPurchases"
import { HomePage } from "./components/HomePageDashboard/MainLayout"
import { ViewMyReport } from "./components/Reports/view/ReportDisplay"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { PrintAllPaneslView } from "./components/Reports/view/PrintAllPanelsView"
import { ChakraProvider, Flex, ThemeConfig } from "@chakra-ui/react"
import { TenantBrandingContext, TenantBrandingProvider } from "./TenantBrandingContext"
import { ViewOrderDetails } from "./components/Purchases/order-details/ViewOrderDetails"
import { FAQ } from "./components/faq/FaqPage"

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false
}

let customTheme = extendTheme({
  config,
  styles: {
    brand: { 
      100: "#F8F9FA",
      200: "#E9ECEF",
      300: "#DEE2E6",
      400: "#CED4DA",
      500: "#ADB5BD",
      600: "#6C757D",
      700: "#495057",
      800: "#343A40",
      900: "#212529"
    },
    body: {
      bg: '#E7F2F8',
      color: 'white',
    }
  } 
}) 

const routes = [
  { path: "/", element: <PageNotFound />, errorElement: <Login /> }, 
  { path: "/kr/*", element: <KitWorkflow />, errorElement: <PageNotFound /> }, 
  { path: "/t/:tenant_id", element: <Login />, errorElement: <Login /> },
  { path: "/t/:tenant_id/login", element: <Login />, errorElement: <Login /> },
  { path: "/t/:tenant_id/faq", element: <FAQ />, errorElement: <Login /> },
  { path: "/t/:tenant_id/home", element: <HomePage />, errorElement: <Login /> },
  { path: "/t/:tenant_id/my-clients/dashboard", element: <PageNotFound />, errorElement: <Login /> },
  { path: "/t/:tenant_id/shop/kits", element: <ShopKits />, errorElement: <Login /> },
  { path: "/t/:tenant_id/shop/reports", element: <PageNotFound />, errorElement: <Login /> },
  { path: "/t/:tenant_id/my-purchase/view", element: <ViewOrderDetails />, errorElement: <Login /> },
  { path: "/t/:tenant_id/my-purchase/kit/view", element: <KitDetails />, errorElement: <Login /> },
  { path: "/t/:tenant_id/my-purchase/reports/view", element: <ViewMyReport />, errorElement: <Login /> },
  { path: "/t/:tenant_id/my-purchase/reports/print-view", element: <PrintAllPaneslView />, errorElement: <Login /> },
  { path: "/t/:tenant_id/my-purchase/reports/panel/view", element: <ViewPanelData />, errorElement: <Login /> },
  { path: "/t/:tenant_id/my-purchases", element: <AllMyPurchases />, errorElement: <Login /> },
  { path: "/t/:tenant_id/logout", element: <LogoutPage />, errorElement: <Login /> },
  { path: "/t/:tenant_id/password-reset", element: <LogoutPage />, errorElement: <Login /> },
  { path: "*", element: <PageNotFound /> },
]

export const App = () => {
  const tenantBranding = React.useContext(TenantBrandingContext)
  React.useEffect( () => {
    document.title = tenantBranding.tenant_name
  }, [tenantBranding])
  const elem = (
    <span style={{minWidth: '-webkit-fill-available'}}>
      <Flex w="100%" minH="1080px" style={{minWidth: '-webkit-fill-available'}}>
        <RouterProvider router={createBrowserRouter(routes)} />
      </Flex>
    </span>
  );
  return (
    
      <ChakraProvider theme={customTheme}>
        <TenantBrandingProvider {...{ children: elem }} />
      </ChakraProvider> 
  );
};