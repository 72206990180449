import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DEFAULT_HEADERS, FN_DATE_COMP, REPORT_SERVER_URLS } from '../app-helpers'
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import { IClient } from "../../interfaces/IClient";
import { useParams } from "react-router-dom";
import axios from "axios";
import { TimestampDateCellRendererInSeconds } from "../ag-grid/TimestampDateCellRenderer";
import { BlankValueRenderer } from "../ag-grid/BlankValueRenderer";
import { VStack, Box, Text } from "@chakra-ui/react";
import { SendWelcomeEmail } from "../navigation/SendWelcomeEmail";
import { StatusPanelDef, ValueFormatterParams } from "ag-grid-enterprise";
import { TransferClient } from "./TransferClient";
import '../styles.css'

function coachNamesCommaDelimited(params: ValueFormatterParams) {
  if (!params || !params.value || params.value === '') {
    return 'Not Assigned'
  }
  
  return (params.value || []).map((elem: IClient) => elem.full_name).join(',') || 'Not Assigned'
}

const MyClientsDashboard = (props: {reqFilter: string, userRole: string | undefined}) => {

  const { tenant_id } = useParams();
  const [cid, setCid] = useState<string>()
  const [cidCoachIdSelected, setCidCoachIdSeleted] = useState<any>({})
  const [recpName, setRecpName] = useState<string>()
  const [coachList, setCoachList] = useState<Array<IClient>>([]);
  const [rowData, setRowData] = useState<Array<IClient>>();
  const [reloadGrid, setReloadGrid] = useState(false)
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [openClientTransferModal, setOpenClientTransferModal] = useState(false)
  const [initialRefCoach, finalRefCoach] = [useRef(null), useRef(null)] 
  const gridRef = useRef<AgGridReact>(null)

  const setGridData = () => {
    const clientHeaders = DEFAULT_HEADERS()
    const clientsUrl = REPORT_SERVER_URLS(tenant_id, 'client')['USER_DETAILS'] 
    
    axios.get(clientsUrl, { headers: clientHeaders })
      .then((res) => res?.data || [])
      .then((data: IClient[]) => {
        for (const obj of data) {
          let coach_str = ''
          for (const a of obj.assigned_coaches || []) { 
            if (a?.full_name) {
              coach_str = a.full_name
            }
          }
          obj.comma_delimited_val = coach_str
        }
        setRowData(data) 
        if (gridRef?.current) { 
          gridRef.current!.api.setGridOption('rowData', data)
          gridRef.current!.api.refreshCells({})
        }        
      })
      .catch((err) => {
        console.error("Unable to get client data for the coach", err);
      });
  }
  useEffect(() => {
    if (!props?.userRole || !tenant_id || props.userRole !== 'admin') {
      return
    }

    const coachHeaders = DEFAULT_HEADERS()
    const coachsUrl = REPORT_SERVER_URLS(tenant_id, 'coach')['USER_DETAILS']
    axios.get(coachsUrl, { headers: coachHeaders })
      .then((res) => res?.data || [])
      .then((data: IClient[]) => {
        setCoachList(data);
      })
      .catch((err) => {
        console.error("Unable to get client data for the coach", err);
      });
      if (reloadGrid) {
        setGridData()
        setReloadGrid(false)
      }
  }, [tenant_id, props.reqFilter, props.userRole, reloadGrid])
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      minWidth: 200,  enableRowGroup: true,
      filter: "agTextColumnFilter",
    };
  }, []);

  const gridCols: any = [
    {
      field: "full_name",
      headerName: "Name",
      cellRenderer: BlankValueRenderer,
      sort: "asc",
    },
    { field: "comma_delimited_val", headerName: "Coach(s)", cellStyle: (params:any) => {
      if ((params?.value || []).length === 0 || params.value === 'Not Assigned') {
          return {color: 'black', backgroundColor: '#F9E4E0'};
      }
      return null;
    }},
    { field: "email", headerName: "Email" },
    { field: "cid", headerName: "ID", hide: true },
    {
      field: "created_at",
      headerName: "Client Since",
      type: ["dateColumn"],
      cellRenderer: TimestampDateCellRendererInSeconds
    },
  ];
  const onGridReady = useCallback(
    (params: any) => {
      setGridData()
    },
    [tenant_id, props.reqFilter, reloadGrid]
  );

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,  
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: { comparator: FN_DATE_COMP },
      },
    };
  }, []);

  const gridOptions = {};

  const autoSizeStrategy: any = useMemo(() => {
    return {
      type: "fitGridWidth",
    };
  }, []);

  const getContextMenuItems = useCallback((params: any) => {
    let result = []
    if (props?.userRole === 'admin') {
      result.push(
        {
          name: 'Transfer Client',
          action: () => {
            setOpenClientTransferModal(true)
          },
          icon:  '<span class="ag-icon ag-icon-unlinked" unselectable="on" role="presentation"></span>',
          tooltip: 'Click here to transfer (or reassign) to another coach'
        }
      )
    }
    const coachNav = [
        {
            name: 'Send Welcome Email',
            action: () => {
              setOpenEmailModal(true)
            },
            tooltip: 'Click here to send a welcome email.'
        },
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
    ]
    for (const i of coachNav) {
      result.push(i)
    }
    return result
  }, [props.userRole])
    
  const statusBar = useMemo<{
      statusPanels: StatusPanelDef[];
    }>(() => {
      return {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agTotalRowCountComponent', align: 'center' },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' },
        ],
      };
    }, [])
      
    return (
        <VStack>
          <Box>
            <div className="ag-theme-quartz" style={{ height: '450px', minWidth: '850px' }}>
              <AgGridReact autoSizeStrategy={autoSizeStrategy} 
              autoGroupColumnDef={autoGroupColumnDef}
              onCellContextMenu={(event: any) => {
                setCid(event.data.cid); 
                setRecpName(event.data.full_name); 
                setCidCoachIdSeleted({
                  'clientName': event.data.full_name,
                  'cid': event.data.cid, 
                  'coachName': event.data.comma_delimited_val,
                  'coachId': (event.data.assigned_coaches || []).map((elem: IClient) => elem.cid).join('|')
                })
                event.node.setSelected()}}
              gridOptions={gridOptions}
              rowData={rowData} columnTypes={columnTypes}  
              columnDefs={gridCols}
              defaultColDef={defaultColDef} ref={gridRef}
              statusBar={statusBar} rowGroupPanelShow={'always'}
              getContextMenuItems={getContextMenuItems} enableCellChangeFlash={true}
              onGridReady={onGridReady}
              overlayLoadingTemplate={
                '<div style="position:absolute;top:0;left:0;right:0; bottom:0; background: url(https://ag-grid.com/images/ag-grid-loading-spinner.svg) center no-repeat" aria-label="loading"></div>'
              }  />
            </div>
          </Box>
          <Box>
              <Text fontSize='xs' as='i'>To send a welcome email to your client, right-click on the name of the client to which you want to send the email, then click <Text as='b'>Send Welcome Email</Text>.</Text>
          </Box>
          <Box>
                <TransferClient {...{ setReloadGrid: setReloadGrid, clientName: cidCoachIdSelected['clientName'], coachName: cidCoachIdSelected['coachName'], coachId: cidCoachIdSelected['coachId'], coachList: coachList, 
                                      tenantId: tenant_id, cid: cidCoachIdSelected['cid'], initialRef: initialRefCoach, finalRef: finalRefCoach, toggleModel: openClientTransferModal, resetToggleHandler: () => { setOpenClientTransferModal(false); } }} />
                <SendWelcomeEmail {...{ recpName: recpName, tenantId: tenant_id, cid: cid, initialRef: initialRefCoach, finalRef: finalRefCoach, toggleModel: openEmailModal, resetToggleHandler: () => { setOpenEmailModal(false); } }} />
          </Box>
        </VStack>
    );
}

export { MyClientsDashboard }