import { Box } from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react" 
import { SendPassCode } from "./Step1SendPasscode"
import { ValidatePassCodeAndUpdatePassword } from "./Step2ValidateCodeUpdatePassword"
import { ShowConfirmationModal } from "./Step3ShowConfirmation"
import { TenantBrandingContext } from "../../TenantBrandingContext" 

const ResetPassword = (props: {
  initialRef: React.MutableRefObject<null>, 
  finalRef: React.MutableRefObject<null>, 
  tenant_id: any, 
  toggleModel: boolean, 
  resetToggleHandler: any,
  showErrorMessage: (errorMsg: string) => void
}) => { 
    const tenantBranding = useContext(TenantBrandingContext)
    const [styleCss, setStyleCss] = useState({ normal:{ background: '#fff', color: 'blue' },  hover: { background: '#fff' } })
    const [sentResetCode, setSentResetCode] = useState(false)
    const [showFinalConfirmation, setShowFinalConfirmation] = useState(false)
    const [emailAddress, setEmailAddress] = useState<string>('')
    const [htmlElement, setHtmlElement] = useState<JSX.Element>()
    const params = {
      initialRef: props.initialRef,
      finalRef: props.finalRef,
      tenant_id: props.tenant_id,
      toggleModel: props.toggleModel,
      resetToggleHandler: props.resetToggleHandler,
      sentResetCode: sentResetCode,
      setSentResetCode: setSentResetCode,
      styleCss: styleCss,
      emailAddress: emailAddress,
      setEmailAddress: setEmailAddress,
      setShowFinalConfirmation: setShowFinalConfirmation,
      showErrorMessage: props.showErrorMessage
    }
 
    useEffect(() => {
      if (showFinalConfirmation) {
        setHtmlElement( <ShowConfirmationModal { ...params } />)
      } else {
        if (sentResetCode) {
          setHtmlElement(<ValidatePassCodeAndUpdatePassword { ...params } /> )
        } else {
          setHtmlElement( <SendPassCode { ...params } />)
        }
      }
      setStyleCss(
        {
          normal:{
            background: tenantBranding.color_palette.submit_background_color,
            color: tenantBranding.color_palette?.submit_color
          },
          hover: {
            background: tenantBranding.color_palette?.submit_background_color
          }
        }
      )
    }, [sentResetCode, props.toggleModel, showFinalConfirmation])
    

    return ( <Box as="span">{htmlElement}</Box> )
}

export { ResetPassword }