import { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { URLS, DEFAULT_HEADERS, ENCODE_PARAMS } from './app-helpers'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EmailIcon } from '@chakra-ui/icons'
import { useFormik } from "formik";
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { FormControl,
  FormLabel, 
  Stack, Image, Skeleton,
  Button,  Input, VStack, Box, IconButton, FormErrorMessage, InputGroup, InputLeftElement, InputRightElement, Container, Heading, 
  AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay, Spinner, HStack, useToast, Flex, Text } from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
import React from "react";
import { ISession } from "../interfaces/ISession"; 
import { ResetPassword } from "./reset-passcode/ResetPassword"
import { TenantBrandingDispatchContext, TenantBrandingContext } from '../TenantBrandingContext'
import { ITenantConfig } from "../interfaces/ITenantConfig";
import { setStorageTokens } from "../AppConstants";
import './styles.css'

const validatePassword = (inputText: string): boolean => {
  const pswRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
  if (!inputText) {
    return false
  }
  return pswRegex.test(inputText)
}
const Login = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const tenantBranding = useContext(TenantBrandingContext)
  const setTenantBranding = useContext(TenantBrandingDispatchContext)
  
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [initialRef, finalRef, cancelRef] = [React.useRef(null), React.useRef(null), React.useRef(null)]
  const [openPasswordResetModal, setOpenPasswordResetModal] = useState(false)
  
  const appNavigate = useNavigate()
  const { tenant_id } = useParams()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showPsw, setShowPsw] = useState(false)
  const [queryParams] = useSearchParams()
  const [errorMsg, setErrorMsg] = useState(queryParams.get("error"))
  const handlePasswordClick = () => setShowPsw(!showPsw)
  const toastClipboard = useToast()
  const [styleCss, setStyleCss] = useState({ normal:{ background: '#fff', color: 'blue' },  hover: { background: '#fff' } })

  const showErrorMessage = useCallback( (errorMsg: string) => {
    toastClipboard({
      title: 'Error',
      description: errorMsg,
      position: "top",
      isClosable: true,
      status: 'error'
    })
    setErrorMsg(null)
  }, [])

  useEffect( () => {
    sessionStorage.removeItem('access_token')
    if( setTenantBranding && tenant_id) {
      const headers = { 'Accept':'application/json', 'Content-Type':'application/json' }
      axios
        .create({
          baseURL: URLS.TENANT_DETAILS,
        })
        .post("", { tenant_id: tenant_id }, { headers: headers })
        .then((res) => res?.data?.body)
        .then((tenantConfig: ITenantConfig) => { 
          document.title = `${tenantConfig.tenant_name} Genomics Platform`
          setTenantBranding(tenantConfig)
          setStorageTokens(tenantConfig)
          setStyleCss(
            {
              normal:{
                background: tenantConfig.color_palette.submit_background_color,
                color: tenantConfig.color_palette?.submit_color
              },
              hover: {
                background: tenantConfig.color_palette?.submit_background_color
              }
            }
          )
          setIsLoaded(true)
        })
        .catch((err) => console.error(err))
      
    }
    if (errorMsg) { 
      showErrorMessage(errorMsg)
    }
  }, [appNavigate, tenant_id, queryParams, errorMsg, setTenantBranding, showErrorMessage])

  const authRequestHandler = (postParams: object) => {
    const errorMsg = 'The credentials entered are incorrect. Please try again.'
    const errorParams = ENCODE_PARAMS({'error': errorMsg})
    onOpen()
    axios
      .post(URLS.AUTH, { ...postParams }, { headers: DEFAULT_HEADERS() })
      .then(res => res?.data?.body) 
      .then((res: ISession) => {
        const accessToken = res.access_token;
        if (accessToken === undefined) {
          onClose()
          showErrorMessage(errorMsg)
          appNavigate(`/t/${tenant_id}/login?${errorParams}`);
        } else {
          sessionStorage.setItem("tenant.app.user_email", res.user_email);
          sessionStorage.setItem("tenant.app.full_name", res.user_full_name);
          sessionStorage.setItem("access_token", accessToken);
          appNavigate(`/t/${tenant_id}/home?qat=${encodeURIComponent(accessToken)}`)
          onClose()
        }
      })
      .catch((err) => {
        console.error("Unable to log user", err)
        onClose()
        showErrorMessage(errorMsg)
        appNavigate(`/t/${tenant_id}/login?${errorParams}`);
      });
  };

  const loginForm = useFormik({
    initialValues: {
        email:'',
        password: ''
    }, onSubmit: (values) => {
      if (! validatePassword(values?.password)) {
        setIsSubmitting(false)
        showErrorMessage('The password must be between 10 to 18 characters, contain at least 1 number, at least 1 uppercase, at least 1 lowercase, and at least 1 special character. If you would like to reset the password instead, please click on the "Password Reset" link.')
      }
      else{
        const loginData = {
          user_email: values.email,
          user_passcode: values.password,
          user_tenant_id: tenant_id,
        };
      setIsSubmitting(true);
      authRequestHandler(loginData);
      }
        
    }
  })

  return (
    <Container backgroundColor={`${tenantBranding?.color_palette?.background_color}`}
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }} >
        <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered closeOnOverlayClick={false}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Please Wait
            </AlertDialogHeader>
            <AlertDialogBody>
              <HStack spacing='40px'>
                <Box>Validating credentials.....</Box>
                <Box><Spinner thickness='8px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
              </HStack>              
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Skeleton isLoaded={isLoaded}>
      <Stack spacing="8">
        <Stack spacing="6" className={'media_loging_logo'}>
          <VStack textAlign="center">
            <Image width={'200px'} height={'100%'} objectFit="cover" src={tenantBranding?.logo} />
          </VStack>
          <VStack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={{ base: "xs", md: "sm" }} color={tenantBranding?.color_palette?.highlight_color_dark}>
              Login to your {tenantBranding?.tenant_name} account<br />
            </Heading>
          </VStack>
        </Stack>
        <Box
          py={{ base: "4", sm: "10" }}
          px={{ base: "4", sm: "10" }} className={'add_box_shadow_and_radius media_content_box'}
          boxShadow='xl' p='6' backgroundColor={`${tenantBranding?.color_palette?.background_color}`}
          borderRadius={{ base: "none", sm: "xl" }} >
          <form onSubmit={loginForm.handleSubmit}>
            <VStack spacing={8} align="flex-start">
              <FormControl isRequired>
                <FormLabel htmlFor="email" color={tenantBranding?.color_palette?.highlight_color_dark}>
                  Email Address
                </FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <EmailIcon color={tenantBranding?.color_palette?.icon_background_color_dark} />
                  </InputLeftElement>
                  <Input ref={finalRef}
                    id="email"
                    name="email"
                    type="email"
                    variant="outline"
                    onChange={loginForm.handleChange}
                    value={loginForm.values.email}
                    width="sm" />
                </InputGroup>
                <FormErrorMessage>This is required.</FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="password" color={tenantBranding?.color_palette?.highlight_color_dark}>
                  Password
                </FormLabel>
                <InputGroup>
                  <Input
                    id="password"
                    name="password"
                    type={showPsw ? "text" : "password"}
                    variant="outline"
                    onChange={loginForm.handleChange}
                    value={loginForm.values.password}
                    width="sm"
                    errorBorderColor="crimson"
                  />
                  <InputRightElement>
                    <IconButton
                      variant="text" color={tenantBranding?.color_palette?.icon_background_color_dark}
                      aria-label={showPsw ? "Mask password" : "Reveal password"}
                      icon={showPsw ? <HiEyeOff /> : <HiEye />}
                      onClick={handlePasswordClick}
                    />
                  </InputRightElement>
                </InputGroup>
                <Flex textAlign={'right'}>
                  <Text cursor={'pointer'} onClick={() => {setOpenPasswordResetModal(true)}} as="span" width={'100%'} fontSize={'xs'} style={{color:tenantBranding?.color_palette?.small_tool_tips}}>Password Reset?</Text> 
                  <ResetPassword {...{initialRef: initialRef, finalRef: finalRef, tenant_id: tenant_id, toggleModel: openPasswordResetModal, resetToggleHandler: () => {setOpenPasswordResetModal(false)}, showErrorMessage: showErrorMessage}} />
                </Flex>
              </FormControl>
              <Button
                type="submit"
                backgroundColor={`${tenantBranding?.color_palette?.submit_background_color}`}
                color={`${tenantBranding?.color_palette?.submit_color}`}
                width="full" style={{...styleCss.normal, ...styleCss.hover}}
                disabled={isSubmitting}>Login
              </Button>
            </VStack>
          </form>
        </Box>
      </Stack>
      </Skeleton>
    </Container>
  );
};

export { Login };
