import { Grid, GridItem, useDisclosure, IconButton, ButtonGroup, VStack, Button, FormControl, FormLabel, ModalBody, Input, InputGroup, InputLeftElement, ModalHeader, Modal, ModalCloseButton, ModalContent, ModalOverlay, ModalFooter, useToast } from "@chakra-ui/react"
import { useCallback, useContext, useEffect, useState } from "react"
import { URLS, DEFAULT_HEADERS, ENCODE_PARAMS } from '../app-helpers'
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import axios from "axios"
import { EmailIcon } from '@chakra-ui/icons'
import { TenantBrandingContext } from "../../TenantBrandingContext"

const SendPassCode = (props: {
  initialRef: React.MutableRefObject<null>,
  finalRef: React.MutableRefObject<null>,
  tenant_id: string,
  toggleModel: boolean,
  resetToggleHandler: any,
  sentResetCode: boolean,
  setEmailAddress: React.Dispatch<React.SetStateAction<string>>,
  setSentResetCode: React.Dispatch<React.SetStateAction<boolean>>,
  styleCss: {normal: {background: string, color: string}, hover: {background: string}},
  showErrorMessage: (errorMsg: string) => void
}) => {
  const tenantBranding = useContext(TenantBrandingContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const appNavigate = useNavigate()
  const toastClipboard = useToast()

  const showErrorMessage = useCallback( (errorMsg: string) => {
    toastClipboard({
      title: 'Error',
      description: errorMsg,
      position: "top",
      isClosable: true,
      status: 'error'
    }) 
  }, [])
  
  const resetPasswordForm = useFormik({
    initialValues: {
      reset_email: "",
    },
    onSubmit: (values) => {
      const sendCodeData = {
        email_address: values.reset_email,
        send_code: "True",
        tenant_id: props.tenant_id,
      };
      setIsSubmitting(true)
      sendCodeHandler(sendCodeData)
    },
  })

  const modalCloseHanlder = (error?: any) => {
    resetPasswordForm.values.reset_email = ''
    setIsSubmitting(false)
    onClose()
    props?.resetToggleHandler()
  }

  const sendCodeHandler = (postParams: any) => {
    const errorMsg = "The email address you entered is incorrect. Please try again."
    const errorParams = ENCODE_PARAMS({ error: errorMsg })
    axios
      .post(URLS.SEND_PASSWORD_RESET_CODE, { ...postParams }, { headers: DEFAULT_HEADERS() })
      .then((res) => res?.data?.body?.task_completed)
      .then((task_completed: string) => {
        if (task_completed !== "true") {
          props.setEmailAddress('')
          modalCloseHanlder(errorMsg)
          appNavigate(`/t/${props.tenant_id}/login?${errorParams}`)
        } else {
          props.setEmailAddress(postParams.email_address)
          props.setSentResetCode(true)
        }
      })
      .catch((err) => {
        props.setEmailAddress('')
        console.error("Unable to send code to the user", err)
        modalCloseHanlder(errorMsg)
        showErrorMessage(errorMsg)
        appNavigate(`/t/${props.tenant_id}/login`)
      })
  }

  useEffect(() => {
    if (!props?.toggleModel) { 
      return; // Nothing to do
    } else if (props.toggleModel) {
      onOpen()
    }
    
  }, [props.tenant_id, props.toggleModel, onOpen])

  return (
    <Modal
      initialFocusRef={props.initialRef}
      finalFocusRef={props.finalRef}
      isOpen={isOpen} size={'xl'}
      onClose={modalCloseHanlder}
    >
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(1deg)' />
      <ModalContent>
        <ModalHeader>Reset Your Password</ModalHeader>
        <ModalCloseButton onClick={modalCloseHanlder} />
        <form onSubmit={resetPasswordForm.handleSubmit}>
          <ModalBody pb={6}>
            <VStack spacing={8} align="flex-start">
              <FormControl isRequired>
                <FormLabel
                  htmlFor="reset_email" 
                  fontSize={"md"} mb={4}
                >
                  Please enter the email address you registered with. We will
                  send you a temporary code to verify.
                </FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <EmailIcon color={tenantBranding.color_palette.icon_background_color_dark} />
                  </InputLeftElement>
                  <Input
                    ref={props.initialRef}
                    id="reset_email"
                    name="reset_email"
                    borderRadius="10px"
                    variant="outline"
                    placeholder="Enter Email"
                    onChange={resetPasswordForm.handleChange}
                    value={resetPasswordForm.values.reset_email}
                  />
                </InputGroup>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Grid w="100%" templateColumns="repeat(4, 1fr)" gap={3}>
              <GridItem />
              <GridItem>
                <ButtonGroup size='md' isAttached variant='outline' px={2}>
                    <IconButton aria-label='Save' color={`${tenantBranding.color_palette.submit_background_color}`} icon={<EmailIcon />} disabled />
                    <Button type="submit"  backgroundColor={`${tenantBranding.color_palette.submit_background_color}`}
                        color={`${tenantBranding.color_palette.submit_color}`} style={{ ...props.styleCss.normal, ...props.styleCss.hover }}
                        isLoading={isSubmitting} variant='solid' loadingText='Sending..'
                        mr={3} disabled={isSubmitting}>Send Code</Button>
                </ButtonGroup>
              </GridItem>
              <GridItem>
                <Button w="100%" onClick={modalCloseHanlder} isDisabled={isSubmitting}>
                  Cancel
                </Button>
              </GridItem>
            </Grid>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export { SendPassCode }