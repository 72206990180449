import { RefObject, useEffect, useMemo, useRef, useState } from "react"
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom"
import { URLS, DEFAULT_HEADERS } from "../app-helpers";
import { IPanelDetails } from "../../interfaces/IPanelDetail";
import { useDisclosure, Box, VStack, Flex, Center, Container, AlertDialog, AlertDialogOverlay, AlertDialogContent, Skeleton, AlertDialogHeader, AlertDialogBody, HStack, Spinner } from "@chakra-ui/react";
import { NavBar } from "../navigation/TopNavBar";
import { PanelMarkersAndGauges } from "./PanelMarkersAndGauges";
import { PanelNavigation } from "./PanelNavigation";
import { FooterPanel } from "../footer";
import '../styles.css'

const ViewPanelData = () => {
    const [loggedInUserRole, setLoggedInUserRole] = useState<string>('')
    const [isLoaded, setIsLoaded] = useState(false)
    const [ panelData, setPanelData] = useState<IPanelDetails>() 
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef: RefObject<any> = useRef()
    const [queryParams] = useSearchParams()
    const { tenant_id } = useParams() 
    const [panel_id, kit_id, report, reportTitle] = [queryParams.get('panel_id') || '', queryParams.get('kit_id') || '', queryParams.get('report') || '', queryParams.get('reportTitle')] 

    useMemo(() => {
        const accessToken = sessionStorage.getItem('access_token') || queryParams.get("qat") || ''
        const postParams = { tenant_id: tenant_id };
        const reqHeaders = DEFAULT_HEADERS(accessToken);
        axios
        .post(URLS.GET_NAV_BAR, { ...postParams }, { headers: reqHeaders })
        .then((res) => res?.data?.role)
        .then((user_role) => { 
            if (user_role) {
                setLoggedInUserRole(user_role)
            } 
        })
        .catch((err) => {
            console.error("Unable to get user role for the logged in user", err)
        })
    }, [tenant_id, report])

    useEffect( () => {
        onOpen()
        const postParams = {tenant_id: tenant_id, kit_id: kit_id, panel_id: panel_id, report: report}
        axios.post(URLS.PANEL_DETAILS, {...postParams}, { headers: DEFAULT_HEADERS() })
        .then((res) => res?.data?.body)
        .then( async (data: IPanelDetails) => {
            const genotype_data = data.genotype_data
            const allSnps = genotype_data.genes
            const toolTipsToSearch: Array<string> = []
            for (const snp of allSnps) {
                if ((sessionStorage.getItem(`app.rsid.tooltip.${snp.rsid}`) || '').length > 0) {
                    snp.tooltip = sessionStorage.getItem(`app.rsid.tooltip.${snp.rsid}`) || ''
                } else {
                    toolTipsToSearch.push(snp.rsid)
                }
            }
            if (toolTipsToSearch.length > 0) {
                const rsidToSearch: string = toolTipsToSearch.join('|')
                const postParams= { tenant_id: tenant_id, mosaic_id: kit_id, panel_id: panel_id, report_id: report, rsid: rsidToSearch }
                await axios.post(URLS.GET_TOOL_TIPS, {...postParams}, { headers: DEFAULT_HEADERS() })
                    .then((res) => {
                        console.log(res)
                        const tool_tip_data = res?.data?.body?.data || []
                        for (const tdata of tool_tip_data) {
                            for (const snp of allSnps) {
                                if (snp.rsid === tdata?.rsid) {
                                    snp.tooltip = tdata.tool_tip || ''
                                    sessionStorage.setItem(`app.rsid.tooltip.${snp.rsid}`, tdata.tool_tip || '')
                                }
                            }
                        }
                    })
                    .catch((err) => {
                        console.error('Unable to find the tool tip', err) 
                    }) 
            }
            
            genotype_data.genes = allSnps
            data.genotype_data = genotype_data
            
            setPanelData(data)
            onClose()
        })
        .catch((err) => {
            console.error('Unable to find the snapshot', err) 
        })
    }, [panel_id, kit_id, report, tenant_id]) 

    const markerParams = {
        panelDetails: panelData,
        kitId: kit_id,
        setIsLoaded: setIsLoaded,
        loggedInUserRole: loggedInUserRole,
        panelId: panel_id, reportId: report
    }
    const panelNavParams = { 
        panel_id: panel_id, kit_id: kit_id, 
        tenant_id: tenant_id, report_id: report, reportTitle: reportTitle
    }
    return (
        <VStack w="100%">
            <NavBar />
            <VStack w={'100%'} >
                <Flex w={'100%'} alignContent={"center"} justifyContent={"center"}>
                <Skeleton isLoaded={isLoaded}>
                    <Center>
                        <Container maxW="5xl" className={'add_box_shadow'} my='5' borderRadius='2xl'>
                            <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered closeOnOverlayClick={false}>
                                <AlertDialogOverlay>
                                <AlertDialogContent>
                                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                    Please Wait
                                    </AlertDialogHeader>
                                    <AlertDialogBody>
                                    <HStack spacing='40px'>
                                        <Box>Loading.....</Box>
                                        <Box><Spinner thickness='8px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
                                    </HStack>              
                                    </AlertDialogBody>
                                </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
                            <PanelNavigation {...panelNavParams} />
                            <PanelMarkersAndGauges {...markerParams} />
                        </Container>
                    </Center>
                    </Skeleton> 
                </Flex>
                <Box w="100%">
                    <FooterPanel />
                </Box>
            </VStack>
        </VStack>
    )
}

export { ViewPanelData }