import { EmailIcon } from "@chakra-ui/icons"
import { Box, Text, useDisclosure, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, ButtonGroup, IconButton, Button, Select, FormLabel, FormControl } from "@chakra-ui/react"
import axios from "axios"
import { useFormik } from "formik"
import { useState, useEffect } from "react"
import { URLS, DEFAULT_HEADERS, REPORT_SERVER_URLS } from "../app-helpers"
import { IClient } from "../../interfaces/IClient"

const clientOptionsDropDown = (coachList: IClient[], selectionChangeHandler: any): JSX.Element => {
    if (!coachList || coachList.length === 0) {
        return <Select placeholder='Pick a coach' />
    }

    coachList = coachList.sort((a,b) => a.full_name.localeCompare(b.full_name))

    return <Box fontSize='xs'> <Select variant='filled' id='to_coach_id' name='to_coach_id' placeholder='Pick a coach' onChange={selectionChangeHandler}> 
    {coachList.map( (elem: IClient, idx: number) => <option key={`idx-${idx}-option`} id={`idx-${idx}-option`} value={elem.cid}>{elem.full_name} [{elem.email}]</option>)}
    </Select></Box>
}
const TransferClient = (props: {clientName: string, cid: string, coachName: string, toggleModel: boolean, setReloadGrid: React.Dispatch<React.SetStateAction<boolean>>,
                                initialRef: React.MutableRefObject<null>, finalRef: React.MutableRefObject<null>, 
                                coachId: string, tenantId: any, coachList: IClient[], resetToggleHandler: any}, ) => {
    
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const toastClipboard = useToast()
    const modalTitle = `Transfer ${props.clientName}`
    const [myCoaches, setMyCoaches] = useState<JSX.Element>(clientOptionsDropDown([], () => {}))

    const showMsg = (title: string, errorMsg: string, toastStatus: "success" | "error") => {
        toastClipboard({
        title: title,
        description: errorMsg,
        position: "top",
        isClosable: true,
        status: toastStatus
        })
    }

    const transferClientHandler = (reqParams: {client_ids: string, to_coach_id: string, from_coach_id: string, tenant_id: string, action: string}) => { 
        axios
        .post(URLS.ADMIN_MY_TASKS, { ...reqParams }, { headers: DEFAULT_HEADERS() })
        .then(async res => {
            if (res?.data?.body?.status) {
                const reqUrl: string = REPORT_SERVER_URLS(reqParams.tenant_id, undefined)['ADMIN_TRIGGER_USER_CACHE_UPDATE']
                for (const id of [reqParams.from_coach_id, reqParams.to_coach_id]){
                    await axios
                        .get(`${reqUrl}/${id}`, { headers: DEFAULT_HEADERS() })
                        .then(res2 => {return res})
                        .catch((err) => {
                            modalCloseHanlder()
                            showMsg('Error', `Unable to transfer "${props.clientName}". Please try again later.`, 'error') 
                        })
                } 
            } else {
                modalCloseHanlder()
                showMsg('Error', `Unable to transfer "${props.clientName}". Please try again later.`, 'error') 
            }
        })
        .then( res => {
            props.setReloadGrid(true)
            modalCloseHanlder()
            showMsg('Success', `The client "${props.clientName}" has been transferred from the coach "${props.coachName}" successfully.`, 'success')
        })
        .catch((err) => {
            console.error(`Unable to send a welcome email. Please try again later.`, err)
            modalCloseHanlder()
            showMsg('Error', `Unable to transfer "${props.clientName}". Please try again later.`, 'error')
        })
    }

    const clientTransferForm = useFormik({
        initialValues: { 
            to_coach_id: '',
            from_coach_id: props.coachId
        }, onSubmit: (values) => {
            setIsSubmitting(true)
            const formData = {
                to_coach_id: values.to_coach_id,
                from_coach_id: props.coachId,
                client_ids: props.cid, 
                tenant_id: props.tenantId,
                action: 'REASSIGN_CLIENT'
              }
              transferClientHandler(formData)
        }
      })

    const modalCloseHanlder = (param?: any) => {
        onClose()
        clientTransferForm.values.to_coach_id = ''
        clientTransferForm.values.from_coach_id = ''
        props?.resetToggleHandler()
        setIsSubmitting(false)
    }
    
    useEffect( () => {
        setMyCoaches(clientOptionsDropDown(props.coachList, clientTransferForm.handleChange))
        if(props?.toggleModel && props?.cid && props?.clientName && props?.coachName && props?.tenantId) {
            onOpen()
        }
        }, [props.toggleModel, onOpen, props.cid, props.tenantId, props.clientName, props.coachName])
    return (
        <Modal
            initialFocusRef={props.initialRef}
            finalFocusRef={props.finalRef}
            isOpen={isOpen} size={'xl'}
            onClose={modalCloseHanlder}
        >
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(1deg)' />
            <ModalContent>
            <ModalHeader>{modalTitle}</ModalHeader>
            <ModalCloseButton onClick={modalCloseHanlder} />
            <form onSubmit={clientTransferForm.handleSubmit}>
                <ModalBody whiteSpace={'1'}> 
                    <Box my={2}>
                        <Text fontSize={'sm'} my={2}>You are about to transfer the client &quot;<Text as='b'>{props.clientName}</Text>&quot; from the coach &quot;<Text as='b'>{props.coachName}</Text>&quot;. The new coach will also own all the kits and corresponding reports generated for &quot;<Text as='b'>{props.clientName}</Text>&quot;.<br /><br />Once the transfer is complete, the new coach will receive email confirmation as well. Select the coach you want to reassign from the drop-down below:</Text>
                        <FormControl my={8} isRequired>
                        <FormLabel>Select one from the drop down</FormLabel>
                        {myCoaches}
                        </FormControl>
                    </Box>
                    <Box my={2}>
                        <Text fontSize={'sm'} width={'100%'}>Are you sure you want to continue? Click "Yes" to proceed, or "No" to cancel and go back.</Text>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup size='md' isAttached variant='outline' colorScheme="telegram" px={2}>
                        <IconButton aria-label='Save' icon={<EmailIcon />} disabled />
                        <Button type="submit" 
                            isLoading={isSubmitting} variant='solid' loadingText='Sending'
                            mr={3} disabled={isSubmitting}>Yes</Button>
                    </ButtonGroup>
                    <Button onClick={modalCloseHanlder} isDisabled={isSubmitting}>No</Button>
                </ModalFooter>
            </form>
            </ModalContent>
        </Modal>
    )
}

export { TransferClient }