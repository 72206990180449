import { Box, VStack, Flex } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { IGauges } from "../../interfaces/IGauges"
import { DisplayGauge } from "./DisplayGauges"

const gaugesJsxElements = (g: IGauges[]) : JSX.Element[] => {
    if (!g || g.length === 0) {
        return []
    }
    return g.map( (elem: IGauges, idx: number) => {
        const gaugeDataParams = { gaugeData: elem }
        return <Box key={`${idx}-box-gauge`}><DisplayGauge {...gaugeDataParams} /></Box>
    } )
}
const GaugesPanel = (props: {gaugeData: IGauges[]}) => {
    const [gaugeData, setGaugeData] = useState<JSX.Element[]>([])

    useEffect( () => {
        setGaugeData(gaugesJsxElements(props?.gaugeData))
    }, [props])
    
    return (
        <Flex justifyContent={'center'} textAlign={'center'}>
        <VStack>
            {gaugeData}
        </VStack>
        </Flex>
    )
}

export { GaugesPanel }