import React, { useState, useEffect, RefObject, } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import axios from "axios";
import { DEFAULT_HEADERS, URLS } from "../app-helpers";
import {  AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay, Box, Flex, HStack, Spinner, useDisclosure } from "@chakra-ui/react";
import { IAddress } from "../../interfaces/IAddress";

// This is your test publishable API key.
//const stripePromise = loadStripe("pk_test_519O9J0Ec0iozGLcJoKg6snyG5cfqSe1A3FvWvfFaz1pJulv2w4uVIybQA1YHVB1owQddthpBuUgSaUtYivQqMCLf00HYfOo3rJ");

const alertDialog = <AlertDialogOverlay>
<AlertDialogContent>
  <AlertDialogHeader fontSize='lg' fontWeight='bold'>
    Please Wait
  </AlertDialogHeader>
  <AlertDialogBody>
    <HStack spacing='40px'>
      <Box>Loading.....</Box>
      <Box><Spinner thickness='8px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
    </HStack>              
  </AlertDialogBody>
</AlertDialogContent>
</AlertDialogOverlay>
const StripeCheckout = (props: { orderInCart: any, stripePromise: any, deliveryAddress: IAddress | undefined, setShippingAddressCollected: React.Dispatch<React.SetStateAction<boolean>>}) => {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef: RefObject<any> = React.useRef()
  
  const [stripeSessionTokens, setStripeSessionTokens] = useState({
    coachEmail: '',
    orderId: ''
  })
  const [clientSecret, setClientSecret] = useState('')
  const [clientSessionData, setClientSessionData] = useState('')

  const handleComplete = () => {
    processOrderConfirmation()
  }

  const processOrderConfirmation = () => {
    const postOrderParams = {
      'tenant_id': props.orderInCart.tenantId,
      'coach_email': props.orderInCart.coachEmail,
      'client_session_id': clientSessionData
    }
    axios
      .post(URLS.CONFIRM_STRIPE_SESSION_ID, { ...postOrderParams}, { headers: DEFAULT_HEADERS()})
      .then((res) => {
        setStripeSessionTokens({
          'coachEmail': postOrderParams.coach_email,
          'orderId': res?.data?.body?.order_id
        })
      }).catch((err: any) => {
        console.error(err)
      })
  }
  useEffect( () => {
    onOpen()
    console.log(props.deliveryAddress)
    const postParams = {
      tenant_id: props.orderInCart.tenantId,
      amount_in_cents: props.orderInCart.amountInCents,
      coach_email: props.orderInCart.coachEmail,
      product_name: props.orderInCart.product,
      product_desc: props.orderInCart.description,
      product_qty: props.orderInCart.productQty,
      product_shipping_enabled: `${props?.orderInCart?.shippingEnabled === true}`,
      name: props.deliveryAddress?.owner_name,
      organization: props.deliveryAddress?.owner_name,
      address_line_1: props.deliveryAddress?.street_address_line_1,
      address_line_2: props.deliveryAddress?.street_address_line_2 || '',
      city_locality: props.deliveryAddress?.city,
      state_province: props.deliveryAddress?.state,
      phone: props.deliveryAddress?.phone,
      postal_code: props.deliveryAddress?.postal_code,
      country_code: props.deliveryAddress?.country,
    }
    axios
      .post(URLS.GET_STRIPE_CHECKOUT_SESSION_ID, { ...postParams }, { headers: DEFAULT_HEADERS()})
      .then((res) => {
        setClientSecret(res?.data?.body?.stripe_client_secret_id)
        setClientSessionData(res?.data?.body?.stripe_session_id)
        onClose()
      }).catch((err: any) => {
        console.error(err)
        onClose()
      } )
  }, [props])
  

      return (
        <Flex w="100%" h="100%">
          <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered closeOnOverlayClick={false}>
            {alertDialog}
          </AlertDialog>
          { clientSecret && (
            <EmbeddedCheckoutProvider
            stripe={props.stripePromise}
            options={{clientSecret, onComplete: handleComplete}}
          >
            <EmbeddedCheckout className="embedded-checkout-box" />
          </EmbeddedCheckoutProvider>
          )}
        </Flex>          
      );
    
}

export { StripeCheckout }