import { AgGridReact } from "ag-grid-react"
import axios from "axios"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-enterprise';
import { useState, useCallback, useMemo, useRef, useEffect } from "react"
import { useParams } from "react-router-dom" 
import { DEFAULT_HEADERS, FN_DATE_COMP, REPORT_SERVER_URLS } from "../app-helpers"
import { IKitSummary } from "../../interfaces/IKitSummary"
import { TimestampDateCellRendererInSeconds } from "../ag-grid/TimestampDateCellRenderer" 
import { Box, VStack, Text } from "@chakra-ui/react" 
import { KitRegistration } from "./RegisterKit"
import { IClient } from "../../interfaces/IClient"
import { ITooltipParams, StatusPanelDef } from "ag-grid-enterprise";

interface IKitStatus {
    orderStatus: string, orderToolTip: string
}
const KitStatusToolTip: Array<IKitStatus> = [
    {orderStatus: 'Order Confirmed', orderToolTip:'The order has been confirmed, and we are working on fulfilling it.'},
    {orderStatus: 'Order Shipped', orderToolTip: 'The order has been shipped.'},
    {orderStatus: 'Order Delivered', orderToolTip: 'The order has been delivered.'},
    {orderStatus: 'Kit Lost', orderToolTip: 'The kit was reported as lost.'},
    {orderStatus: 'Kit Lost & Replaced', orderToolTip: 'The kit was reported as lost & it was replaced.'},
    {orderStatus: 'Sample Intransit To Lab', orderToolTip: 'The lab still needs to receive the sample that the client sent.'},
    {orderStatus: 'Sample Received By Lab', orderToolTip: 'The lab has received the sample from the client and is currently processing it.'},
    {orderStatus: 'Sample Results Received From Lab', orderToolTip: 'We have just received the lab results and are processing the reports for your coach\'s evaluation.'},
    {orderStatus: 'Sample Results Processed', orderToolTip: 'We have completed processing the reports, and the coach is reviewing them.'}, 
    {orderStatus: 'Review Completed By Coach', orderToolTip: 'The coach has reviewed the reports generated and will soon share their feedback with the client.'},
    {orderStatus: 'Client Access Granted', orderToolTip: 'The coach has granted the client access to this report.'}
]
const KitsDashboard = (props: {userRole: string | undefined}) => {

    const [reloadGrid, setReloadGrid] = useState(false)
    const [rowData, setRowData] = useState<Array<IKitSummary> | undefined>()
    const [mosaicIdSelected, setMosaicIdSelected] = useState<string>()
    const [initialRefCoach, finalRefCoach] = [useRef(null), useRef(null)] 
    const [allMyClients, setAllMyClients] = useState<Array<IClient>>([])
    const [openKitModal, setOpenKitModal] = useState(false)
    const { tenant_id } = useParams()
    const gridRef = useRef<AgGridReact>(null)

    const setGridData = () => {
        const kitHeaders = DEFAULT_HEADERS()
        const kitsUrl = REPORT_SERVER_URLS(tenant_id, null)['KITS_SUMMARY'] 
        axios.get(kitsUrl, {headers: kitHeaders})
        .then((res) => res?.data || [])
        .then((data: IKitSummary[]) => {
            setRowData(data)
            if (gridRef?.current) { 
                gridRef.current!.api.setGridOption('rowData', data)
                gridRef.current!.api.refreshCells({})
              }
        })
        .catch((err) => {
            console.error('Unable to find the snapshot', err) 
        })
    }
    const getRowToolTip = (kitStatus: string): string => {
        if (!kitStatus || kitStatus.trim().length === 0) {
            return 'Not Assigned'
        }
        
        return KitStatusToolTip.filter((elem: IKitStatus) => elem.orderStatus.toLowerCase() === kitStatus.toLowerCase())
                    .map((elem: IKitStatus) => elem.orderToolTip).pop() || 'Not Assigned'
    }

    const getClientsList = () => {
        const reqUrl = REPORT_SERVER_URLS(tenant_id, 'client')['USER_DETAILS']
        axios
        .get(reqUrl, { headers: DEFAULT_HEADERS() })
        .then ( res =>  res.data || [])
        .then ((clientsList: IClient[]) => {
            clientsList.sort( (a: IClient, b: IClient) => a.full_name.localeCompare(b.full_name))
            setAllMyClients(clientsList)
        })
        .catch((err) => {
          console.error("Unable to get my client list", err) 
        })
      }
    
    useEffect(() => {
        getClientsList()
        setGridData()
        if (reloadGrid) {
            setGridData()
            setReloadGrid(false)
        }
    }, [tenant_id, reloadGrid])

    const onGridReady = useCallback((params: any) => {
        getClientsList() 
    }, [tenant_id, reloadGrid])

    const defaultColDef = useMemo(() => {
        return {
            resizable: true, enableRowGroup: true, minWidth: 200,
            filter: "agTextColumnFilter",
        }
    }, [])

    const columnTypes = useMemo(() => {
        return {
            nonEditableColumn: { editable: false },
            dateColumn: {
            filter: "agDateColumnFilter",
            filterParams: { comparator: FN_DATE_COMP },
            },
        }
    }, [])

    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200
        }
    }, [])

    const autoSizeStrategy: any = useMemo(() => {
        return {
            type: "fitGridWidth"
        }
    }, [])

    const gridOptions = {}
    
    const toolTipValueGetter = (params: ITooltipParams) => (params.value == null || params.value === '') ? '- Not Available -' : getRowToolTip(params.value);
    const gridCols: any = [
        { field: "mosaic_id", headerName: 'Mosaic Kit ID'},
        { field: "kit_status", headerName: 'Status', tooltipValueGetter: toolTipValueGetter},
        { field: "client_name", headerName: 'Client', sort: 'asc', cellStyle: (params:any) => {
            if (!params?.value || params.value === 'Not Assigned') {
                return {color: 'black', backgroundColor: '#F9E4E0'};
            }
            return null;
        }},
        { field: "coach_name", headerName: 'Coach'}, 
        { field: "last_updated", headerName: 'Last Updated', type: ['dateColumn', 'nonEditableColumn'], cellRenderer: TimestampDateCellRendererInSeconds } 
    ]
    const getContextMenuItems = useCallback((params: any) => {
        const result = [
            {
                name: 'Register Client',
                action: () => {
                    setOpenKitModal(true)
                },
                tooltip: 'Click here to register the kit to your client.'
            },
            'copy',
            'copyWithHeaders',
            'separator',
            'export'
        ]
        return result
    }, [])
    
    const statusBar = useMemo<{
        statusPanels: StatusPanelDef[];
      }>(() => {
        return {
          statusPanels: [
            { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
            { statusPanel: 'agTotalRowCountComponent', align: 'center' },
            { statusPanel: 'agFilteredRowCountComponent' },
            { statusPanel: 'agSelectedRowCountComponent' },
            { statusPanel: 'agAggregationComponent' },
          ],
        };
      }, [])
    
    return (
            <VStack>
                <Box>
                    <Text fontSize='sm'>To register or reassign a kit, right-click on the kit you want to register or reassign and click <Text as='b'>Register Client</Text>.</Text>
                </Box>
                <Box> 
                    <div className="ag-theme-quartz" style={{ height: '450px', minWidth: '850px' }}>
                        <AgGridReact autoSizeStrategy={autoSizeStrategy} 
                        onCellContextMenu={(event: any) => {setMosaicIdSelected(event.data.mosaic_id); event.node.setSelected()}}
                        autoGroupColumnDef={autoGroupColumnDef}  
                        rowData={rowData} columnTypes={columnTypes} columnDefs={gridCols}  gridOptions={gridOptions}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady} 
                        rowGroupPanelShow={'always'} tooltipMouseTrack={true} tooltipShowDelay={800}
                        allowContextMenuWithControlKey={true} enableCellChangeFlash={true}
                        getContextMenuItems={getContextMenuItems}
                        ref={gridRef} statusBar={statusBar}
                        overlayLoadingTemplate={
                        '<div style="position:absolute;top:0;left:0;right:0; bottom:0; background: url(https://ag-grid.com/images/ag-grid-loading-spinner.svg) center no-repeat" aria-label="loading"></div>'
                        } />
                    </div>
            </Box>
            <Box>
                <KitRegistration {...{ setReloadGridHandler: setReloadGrid, clientList: allMyClients, mosaicId: mosaicIdSelected, initialRef: initialRefCoach, finalRef: finalRefCoach, tenant_id: tenant_id, toggleModel: openKitModal, resetToggleHandler: () => { setOpenKitModal(false); } }} />
            </Box>
        </VStack>
    );
}

export { KitsDashboard }