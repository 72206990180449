import "../shop/kits.css";
import { StripeCheckout } from "./StripeCheckout";
import "../shop/StripeCheckout.css"
import { Flex, Box, VStack, AlertIcon, Alert } from "@chakra-ui/react";
import { AllSalesFinalAsText } from "./AllSalesFinalText";
import { IAddress } from "../../interfaces/IAddress";

const CheckoutKits = (props: { orderInCart: any, stripePromise: any, deliveryAddress: IAddress | undefined, setShippingAddressCollected: React.Dispatch<React.SetStateAction<boolean>>}) => {
  return (
    <VStack w="100%" backgroundColor="#101d22" spacing={2}>
      <Box color="#000" textAlign="left" w="50%">
        <Alert status="warning" fontSize="2xs">
          <AlertIcon />
          We accept all major credit cards for your convenience. To
          provide this service, we have partnered with Mosaic Biodata, Inc. This
          company uses strict security protocols to ensure the safety of your
          credit card transactions. They do not store or view your credit card
          information. Mosaic Biodata works in collaboration with Stripe to
          process all payments.&nbsp;
          <AllSalesFinalAsText {...{showJustMsg: true}} />
        </Alert>
      </Box>
      <Flex
        width={"100vw"}
        height={"100vh"}
        alignContent={"center"}
        justifyContent={"center"}
        backgroundColor="#101d22"
      >
        <StripeCheckout {...props} /> 
      </Flex> 
    </VStack>
  );
};

export { CheckoutKits };
