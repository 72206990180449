import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from "@chakra-ui/react"
import { TenantBrandingContext } from "../../TenantBrandingContext";
import { useContext } from "react";

const NavBarRenderer = (props: { navJsxElement: JSX.Element }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const tenantBranding = useContext(TenantBrandingContext)
  const handleNavBarClick = () => {
    onOpen()
  }

  return (
    <Flex position="absolute" top={2} left={2} bgColor={tenantBranding.color_palette.top_banner_background_color}>
      <HamburgerIcon m={2} boxSize={8} onClick={() => handleNavBarClick()} color={tenantBranding.color_palette.icon_font_color} />

      <Drawer onClose={onClose} isOpen={isOpen} size="xs" placement="left" >
        <DrawerOverlay />
        <DrawerContent bgColor={tenantBranding.color_palette.top_banner_background_color} color={tenantBranding.color_palette.font_color}>
          <DrawerCloseButton />
          <DrawerHeader>{window.sessionStorage.getItem('app.tenant_name') || tenantBranding.tenant_name}</DrawerHeader>
          {props.navJsxElement}
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export { NavBarRenderer }
