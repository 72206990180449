// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mosaic-dna-kit-img {
    max-width: 280px;
    max-height: 280px;
}

.right-kits-order-panel {
    max-width: 500px;
    margin-left: 25px;
}

.add-to-cart-box {
    margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/shop/kits.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".mosaic-dna-kit-img {\n    max-width: 280px;\n    max-height: 280px;\n}\n\n.right-kits-order-panel {\n    max-width: 500px;\n    margin-left: 25px;\n}\n\n.add-to-cart-box {\n    margin-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
