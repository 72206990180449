import { useEffect, useState } from "react"
import { IGenes } from "../../interfaces/IGenes"
import { TableContainer, Table, TableCaption, Thead, Tr, Th, Tbody, Td, Tfoot, Flex, Text, Tooltip } from "@chakra-ui/react"

const DisplayGenotypes = (props: {snpData: IGenes[], kidId: string}) => {
    const [geneData, setGeneData] = useState<IGenes[]>(props?.snpData || [])
    useEffect( () => {
        const allSnps: IGenes[] = props?.snpData || []
        setGeneData(allSnps)
    }, [props])
    const tableHeaders = ['Gene', 'Marker', 'Your Genotype']
    return (
        <TableContainer textAlign='center'>
            <Table variant='striped' colorScheme='orange'>
                <TableCaption fontSize={'md'}>{props?.kidId}</TableCaption>
                <Thead>
                    <Tr>
                        { tableHeaders.map((elem: string, idx: number) => <Th key={`td-header-row-${idx}`}><Text as='b'>{elem}</Text></Th>) }
                    </Tr>
                </Thead>
                <Tbody fontSize='sm'>
                    {
                        geneData.map((elem: IGenes, idx: number) => {
                            return <Tr key={`${idx}-tr`}> 
                                    <Td><Tooltip label={elem.tooltip || ''} fontSize='xs'><Flex align="center" justify="center">{elem.snp_label}</Flex></Tooltip></Td>
                                    <Td><Tooltip label={elem.tooltip || ''} fontSize='xs'><Flex align="center" justify="center">{elem.rsid}</Flex></Tooltip></Td>
                                    <Td align='center'><Tooltip label={elem.tooltip || ''} fontSize='xs'><Flex align="center" justify="center">{elem.geno_type}</Flex></Tooltip></Td>
                            </Tr>
                        })
                    } 
                </Tbody>
                <Tfoot>
                </Tfoot>
            </Table>
        </TableContainer>
    )
}

export { DisplayGenotypes }