
import React, { createContext, useState } from "react";
import { ITenantConfig } from "./interfaces/ITenantConfig";
import { getFromStorageToken } from "./AppConstants";

const initialState: ITenantConfig = {
  tenant_name: 'Mosaic Genomics Platform', 
  logo: getFromStorageToken('app.logo'), 
  nav_logo: getFromStorageToken('app.nav_logo'), 
  tenant_created_at: 0, 
  tenant_last_updated_at: 0, 
  tenant_description: getFromStorageToken('app.tenant_description'),
  color_palette: {
    background_color: getFromStorageToken('app.color_palette.background_color'),                         font_color: getFromStorageToken('app.color_palette.font_color'),
    submit_background_color: getFromStorageToken('app.color_palette.submit_background_color'),           submit_color: getFromStorageToken('app.color_palette.submit_color'),
    small_tool_tips: getFromStorageToken('app.color_palette.small_tool_tips'),                           highlight_color_dark: getFromStorageToken('app.color_palette.highlight_color_dark'),
    icon_font_color: getFromStorageToken('app.color_palette.icon_font_color'),                           icon_background_color_dark: getFromStorageToken('app.color_palette.icon_background_color_dark'),
    top_banner_background_color: getFromStorageToken('app.color_palette.top_banner_background_color'),   footer_background_color: getFromStorageToken('app.color_palette.footer_background_color'),
    top_banner_font_color: getFromStorageToken('app.color_palette.top_banner_font_color'),               footer_font_color: getFromStorageToken('app.color_palette.footer_font_color'),
    highlight_color_light: getFromStorageToken('app.color_palette.highlight_color_light'),               submit_background_color_contrast: getFromStorageToken('app.color_palette.submit_background_color_contrast'),
  }
}

const TenantBrandingContext = createContext(initialState);
const TenantBrandingDispatchContext = createContext<React.Dispatch<React.SetStateAction<ITenantConfig>> | undefined>(undefined);

const TenantBrandingProvider = (props: {children: JSX.Element}) => {
  const [tenantBranding, setTenantBranding] = useState(initialState);

  return (
    <TenantBrandingContext.Provider value={tenantBranding}>
      <TenantBrandingDispatchContext.Provider value={setTenantBranding}>
        {props.children}
      </TenantBrandingDispatchContext.Provider>
    </TenantBrandingContext.Provider>
  );
}

export { TenantBrandingProvider, TenantBrandingContext, TenantBrandingDispatchContext };