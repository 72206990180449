import { VStack, Flex, Center, Container, Card, CardHeader, Heading, Badge,
  Icon, CardBody, Stack, StackDivider, Box, Text, useClipboard, useToast } from "@chakra-ui/react"
import { NavBar } from "../../navigation/TopNavBar"
import { ShowOrderData } from "./ShowOrderData"
import { IOrderData } from "../../../interfaces/IOrderData"
import { MdContentCopy } from "react-icons/md";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { URLS, DEFAULT_HEADERS } from '../../app-helpers'

const badgeStatusColorMap: { [key: string]: string } = {
  'Order Received': 'purple',
  'Pending Shipping': 'yellow',
  'In Transit': 'blue',
  'Delivered': 'green',
  'Canceled': 'red'
}
const getOrderData = (orderId: any, tenantId: any, setOrderData: React.Dispatch<React.SetStateAction<any>>) => {

  const postParams = {mosaic_order_id: orderId, tenant_id: tenantId}
  axios.post(URLS.PURCHASE_DETAILS, {...postParams}, { headers: DEFAULT_HEADERS() })
        .then((res) => res?.data?.body || {})
        .then((data: IOrderData) => setOrderData(data))
        .catch((err) => {
            console.error('Unable to find the order details', err) 
        })

}

const ViewOrderDetails = () => {

  const [orderData, setOrderData] = useState<IOrderData>()
  const { tenant_id } = useParams()
  const [queryParams] = useSearchParams()
  const toastClipboard = useToast()
  const orderId = queryParams.get('o')
  useEffect( () => {
    getOrderData(orderId, tenant_id, setOrderData)
    toastClipboard({
      title: 'Next Update',
      description: 'The expected shipping is in 2-3 business days.',
      position: "bottom-right",
      isClosable: true,
    })
  }, [tenant_id, toastClipboard, orderId])
  const { onCopy, value, setValue, hasCopied } = useClipboard("")
  
  const product = 'Mosaic Biodata Genetic Test Kit'
  const copyOrderId = () => {
    setValue(orderData?.order_id || '')
  }
  return (
      <VStack w="100%">
        <NavBar />
        <Flex  width={"100vw"} height={"75vh"} alignContent={"center"} justifyContent={"center"}>
          <Center>
            <Container maxW="5xl">
              <Card boxShadow='xl'>
                <CardHeader>
                  <Heading size="md">Thank you for your purchase!</Heading>
                </CardHeader>
                <CardBody>
                  <Stack spacing="5" divider={<StackDivider />}>
                    <Box>
                      <Heading pb='1' letterSpacing={1} size='sm' textTransform='uppercase'>Order Summary<Badge mx={3} variant='solid' colorScheme={badgeStatusColorMap[orderData?.status || '']} fontSize={'2xs'}>{orderData?.status}</Badge></Heading>
                      <Text fontSize='sm' color='#4A5568' as='b'>
                        Order ID: {orderData?.order_id} <Icon cursor='pointer' as={MdContentCopy} onClick={() => {
                          onCopy()
                          copyOrderId()
                          toastClipboard({
                            description: "Order ID has been copied to your clipboard successfully.",
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                          })
                        }} color='blue.500' />
                      </Text>
                    </Box>
                    <Box p='4'>
                      <ShowOrderData {...{orderData: orderData, product: product}} />
                    </Box>
                  </Stack>
                </CardBody>
              </Card>
            </Container>
          </Center>
        </Flex>
      </VStack>
    )
}

export { ViewOrderDetails }