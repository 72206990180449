import {
  Container,
  Image,
  HStack,
  Heading,
  VStack,
} from "@chakra-ui/react"; 
import { TenantBrandingContext } from "../../TenantBrandingContext";
import { useContext } from "react";

const TopBar = (props: { tenant_id: string; access_token: string }) => {
  const tenantBranding = useContext(TenantBrandingContext)
  const appTitle = "",
    appByLine = "";
  return (
    <Container minW="350px" my={5}>
      <HStack>
        <Image
          className="tenant-logo-top-nav-bar"
          src={tenantBranding.nav_logo || tenantBranding.logo}
          maxW='100'
        />
        <VStack>
          <Heading as="h3" size="lg">
            {appTitle}
          </Heading>
          <Heading as="h6" size="xs">
            {appByLine}
          </Heading>
        </VStack>
      </HStack>
    </Container>
  );
};

export { TopBar as IconAndLogoMenuBar };
