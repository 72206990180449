import { ICellRendererParams } from 'ag-grid-community';

const TimestampDateCellRenderer = (props: ICellRendererParams<number>) => {
    let rawValue = props?.value || 0
    const dt = new Date(parseInt(rawValue))
    const monStr = dt.getMonth() + 1
    const dtStr = `${dt.getFullYear()}-${(monStr < 10 ? '0' + monStr : monStr)}-${(dt.getDate() < 10 ? '0'+dt.getDate(): dt.getDate())}`
    return <span>{dtStr}</span>;
}

const TimestampDateCellRendererInSeconds = (props: ICellRendererParams<number>) => {
    let rawValue = props?.value || 0
    const dt = new Date(parseInt(rawValue)*1000)
    const monStr = dt.getMonth() + 1
    const dtStr = `${dt.getFullYear()}-${(monStr < 10 ? '0' + monStr : monStr)}-${(dt.getDate() < 10 ? '0'+dt.getDate(): dt.getDate())}`
    return <span>{dtStr}</span>;
}

export { TimestampDateCellRenderer, TimestampDateCellRendererInSeconds };