import { useState, useEffect, useContext, useRef } from "react";
import { Box, Divider, Flex, AbsoluteCenter, VStack, Table, TableCaption, TableContainer, Tbody, Td, Text, Tr, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay, HStack, Spinner } from "@chakra-ui/react";
import axios from "axios";
import { useDisclosure } from '@chakra-ui/react'
import { useParams, useSearchParams } from 'react-router-dom' 
import { DEFAULT_HEADERS, URLS } from "../../app-helpers";
import { IReportPanels, IReportView } from "../../../interfaces/IReportView";
import { IPanelDetails } from "../../../interfaces/IPanelDetail";  
import { PanelMarkersAndGauges } from "../../Panels/PanelMarkersAndGauges"; 
import { TenantBrandingContext } from "../../../TenantBrandingContext";

const PrintAllPaneslView = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [ cancelRef] = [useRef(null)]
    const [loadingText, setLoadingText] = useState<string>('Loading..')
    const tenantBranding = useContext(TenantBrandingContext)
    const { tenant_id } = useParams()
    const [queryParams] = useSearchParams()
    const [reportDate, setReportDate] = useState<string>()
    const [id, kitId, createdOn, clientName] = [queryParams.get('id') || '', queryParams.get('k') || '', queryParams.get('ct'), queryParams.get('cn')]
    const accessToken = sessionStorage.getItem('access_token') || queryParams.get("qat") || '' 

    const [reportView, setReportView] = useState<IReportView>()
    const [data, setData] = useState<any>()
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [dataJsx, setDataJsx] = useState<JSX.Element>(<div>Please do not close this window.</div>) 
    
    const addPanelData = async (elem: IReportPanels, allPanelResponses: IPanelDetails[], idx: number, totalCount: number) => {
        const postParams = {tenant_id: tenant_id, kit_id: kitId, panel_id: elem.id, report: id}
        await axios.post(URLS.PANEL_DETAILS, {...postParams}, { headers: DEFAULT_HEADERS(accessToken) })
        .then((res) => res?.data?.body)
        .then( (respData: IPanelDetails) => { 
            allPanelResponses.push(respData) 
            setLoadingText(`Finished loading ${Math.floor((idx * 100)/totalCount)}% of data.`)
            })
        .catch((err) => {
            console.error('Unable to find the panel detail', err)
        })
    }

    const getReportData = async () => {
        onOpen()
        const params = {
            kit_id: kitId, report: id, tenant_id: tenant_id
        } 
        const allPanelResponses: IPanelDetails[] = []
        await Promise.all([
            axios.post(URLS.VIEW_REPORT, {...params}, { headers: DEFAULT_HEADERS(accessToken) })
                .then((res) => res?.data?.body)
                .then((res2: IReportView) => {
                    setReportView(res2)
                    setLoadingText(`Loading data for ${(res2.report_modules || []).length} panels...`)
                    if (res2?.created_on) {
                        const dt = new Date(res2.created_on)
                        const dtStr = dt.toDateString()
                        setReportDate(dtStr)
                    }
                    return res2.report_modules.sort( (elem1: IReportPanels, elem2: IReportPanels) => elem1.sort_order < elem2.sort_order ? -1 : 1)
                })
                .then(async (reportPanels: IReportPanels[]) => { 
                    for (let pos = 0; pos < reportPanels.length; pos++) {
                        await addPanelData(reportPanels[pos], allPanelResponses, pos+1, reportPanels.length) 
                    }
                    await updateDataLayout(allPanelResponses)
                })
                .catch((err) => {
                    console.error('Unable to find the report data', err) 
                })
        ])
        
        await setData(allPanelResponses)
        onClose()
    }
    
    useEffect(() => {
       getReportData() 
    }, [tenant_id, id, kitId])

    const updateDataLayout = (panelDetails: IPanelDetails[]) => {
        if (! panelDetails ) {
            return
        }

        const allPanelsLayout: JSX.Element[] = panelDetails.map( (v: IPanelDetails, idx: number) => { 
            const panelParams = {
                panelDetails: v,
                loggedInUserRole: 'coach',
                kitId: kitId, hideNotes: true,
                setIsLoaded: setIsLoaded, panelId: v.id
            }
            return <Box key={`${idx}-panel-item-${v.id}`} margin={'10px 0'}>
                <Box width={'100%'}>
                   <PanelMarkersAndGauges {...panelParams} />
                </Box>
                <Box my={'25px'}>
                    <Divider orientation='horizontal' color={'black'} />
                    <AbsoluteCenter bg='white' px='4'>END: {v.name}</AbsoluteCenter>
                </Box>
            </Box>
        } )

        if (allPanelsLayout.length > 0) {
            setDataJsx(<Box>{allPanelsLayout}</Box>)
        }
    }

    return ( 
        <Flex w={'1080px'} margin={'5px auto'} alignContent={"center"} justifyContent={"center"}>
            <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered closeOnOverlayClick={false}>
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Please Wait
                    </AlertDialogHeader>
                    <AlertDialogBody>
                    <HStack spacing='40px'>
                        <Box>{loadingText}. <br />Please do not close or refresh your browser.</Box>
                        <Box><Spinner thickness='8px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
                    </HStack>              
                    </AlertDialogBody>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <VStack>
                <Box w='100%' marginTop={'50px'} marginBottom={'50px'} alignContent={"center"}>
                    <Box maxWidth={'800px'} >
                    <TableContainer w='100%'>
                        <Table w='100%' variant='simple' >
                            <TableCaption>Copyright @ Mosaic Biodata, Inc</TableCaption> 
                            <Tbody>
                                <Tr>
                                    <Td color={tenantBranding.color_palette.icon_font_color || '#000'} backgroundColor={tenantBranding.color_palette.footer_background_color || 'blue'}><Text as='b'>Report</Text></Td>
                                    <Td>{reportView?.name || 'Loading...'}</Td>
                                </Tr>
                                <Tr>
                                    <Td color={tenantBranding.color_palette.icon_font_color || '#000'} backgroundColor={tenantBranding.color_palette.footer_background_color || 'blue'}><Text as='b'>Generated On</Text></Td>
                                    <Td>{reportDate || 'Loading...'}</Td>
                                </Tr>
                                <Tr>
                                    <Td color={tenantBranding.color_palette.icon_font_color || '#000'} backgroundColor={tenantBranding.color_palette.footer_background_color || 'blue'}><Text as='b'>Kit ID</Text></Td>
                                    <Td>{kitId || 'Loading...'}</Td>
                                </Tr>
                                <Tr>
                                    <Td color={tenantBranding.color_palette.icon_font_color || '#000'} backgroundColor={tenantBranding.color_palette.footer_background_color || 'blue'}><Text as='b'>Coach</Text></Td>
                                    <Td>{reportView?.name || 'Loading...'}</Td>
                                </Tr> 
                            </Tbody> 
                        </Table>
                    </TableContainer>
                    </Box>
                </Box>
                <Box my={'5px'}>
                    <Divider orientation='horizontal' />
                </Box>
                <Box w='100%'>
                    {dataJsx}
                </Box>
            </VStack>
        </Flex>
    )
}

export { PrintAllPaneslView }