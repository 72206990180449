import { Box, Button, Text, Flex, HStack, Input, Stat, StatLabel, StatNumber, VStack, Select, useNumberInput } from "@chakra-ui/react"
import { useState } from "react"
import { TiShoppingCart } from "react-icons/ti"
import { COMMA_SEPARATOR } from '../app-helpers'
 
const htmlProduct = (selections: any, onChangeHandler: any) => {
  return (
    <Select size="md" variant="outline" onChange={onChangeHandler} placeholder="Select a Pack">
      {selections.map((selObj: any, idx: number) => {
        return <option key={`option-${idx}-${selObj.htmlOptionValue}-${selObj.priceBeforeTax}`} value={selObj.htmlOptionValue}> {selObj.htmlBtnLabel} @ ${COMMA_SEPARATOR(selObj.priceBeforeTax)} / pack</option>
      })}
    </Select>
  );
}; 
  
const AddToCart = (props: {data: any, tenantId: any, coachEmail: any, readyToPurchaseHook: React.Dispatch<React.SetStateAction<boolean>>, orderInCartHook: React.Dispatch<React.SetStateAction<any>>}) => {
    const productData = props.data
    const [size, setSize] = useState(0)
    const [qty, setQty] = useState(0)
    
    const [totalPrice, setTotalPrice] = useState(0)
    const [pricePerSize, setPricePerSize] = useState(0)
    const [cartSelected, setCartSelected] = useState({
        'tenantId': props?.tenantId || '',
        'coachEmail': props?.coachEmail || '',
        'productQty': 0,
        'amountInCents': 0,
        'product': 'Mosaic Biodata Genetic Test Kit',
        'description': '',
        'shippingEnabled': true
    })
     
    const qtyHandler = (event: any) => {
        const qtySelected = Number(event)
        setQty(qtySelected)
        setTotalPrice(qtySelected * pricePerSize)
    }

    const getPrice = (valSelected: number) => {
        return productData.selections.filter((sel: any) => sel.htmlOptionValue === valSelected)[0]?.priceBeforeTax
    }

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 0,
      min: 0,
      max: 30,
      onChange: qtyHandler
    })
    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()

    const selectionHandler = (event: any) => {
        const valSelected = Number(event.target.value)
        setSize(valSelected)
        setPricePerSize(getPrice(valSelected))
        setTotalPrice(qty * pricePerSize)
    }

    const startCheckout = (event: any) => { 
        const finalCartSelected = cartSelected
        finalCartSelected['productQty'] = qty
        finalCartSelected['product'] = `${size} Pack Genetic Test Kit${(qty*size)>1 ? `s`: ``}`
        finalCartSelected['amountInCents'] = pricePerSize * 100
        finalCartSelected['description'] = `Total Kit${(qty*size)>1 ? `s`: ``}: ${COMMA_SEPARATOR(qty * size)}`
                
        props?.readyToPurchaseHook(true)
        props?.orderInCartHook(finalCartSelected)
    }

    const [productOptions, _] = useState(htmlProduct(productData.selections, selectionHandler))
    return (
        <Box className="right-kits-order-panel"> 
            <VStack>
                <Box textAlign="left" my={3}>
                    <Text fontSize='sm'>{productData.description}</Text> 
                </Box> 
                <Flex w='100%' mt={2}> 
                    {productOptions} 
                </Flex>
                <Flex w='100%' textAlign='center'>
                    <HStack>
                        <Box as='span'>Quantity</Box>
                        <Button {...dec}>-</Button>
                        <Input maxW='100px' {...input} />
                        <Button {...inc}>+</Button>
                        <Stat textAlign='center' ml={5}>
                            <StatLabel>Total Charges</StatLabel>
                            <StatNumber>${COMMA_SEPARATOR(qty * pricePerSize)}</StatNumber>
                        </Stat>
                    </HStack>
                </Flex>
                <Box w='100%' textAlign='right' className="add-to-cart-box">
                    <Button rightIcon={<TiShoppingCart />} colorScheme='blue' variant='solid' isDisabled={totalPrice <= 0} onClick={startCheckout}>Add To Cart</Button>
                </Box>
                <Box w='100%' textAlign='right'>
                    <Text fontSize='2xs' as='i'>* The credit card details and the shipping information will be entered at the next page.</Text>
                </Box>
            </VStack> 
        </Box>
    )
}

export { AddToCart }