import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { INote } from "../../interfaces/INote"
import { useParams } from "react-router-dom"
import { useFormik } from "formik"
import { DEFAULT_HEADERS, URLS } from '../app-helpers'
import { BiSave } from "react-icons/bi";
import '../styles.css'
import { Flex, Textarea, Card, IconButton, StackDivider, CardBody, Text, Heading, Accordion, AccordionButton, AccordionItem, AccordionIcon, Box, AccordionPanel, useToast, Stack, Button, ButtonGroup, CardHeader } from "@chakra-ui/react" 
import { TenantBrandingContext } from "../../TenantBrandingContext"

const to_dt = (data: number) => { 
    const dt = new Date(data*1000)
    return dt.toLocaleString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
}

const notesToJsxElem = (notesData: INote[]): JSX.Element => {
    if (!notesData || notesData.length === 0) {
        return <></>
    }
    
    return <Box m={4}><Accordion allowToggle>
        {notesData.map( (elem: INote, idx: number) => {
            return (
                <AccordionItem key={`item-${idx}`}>
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left' fontSize={'xs'}>
                            From <Text as='b'>{elem.note_from}</Text> on <Text as='i' size={'xs'}>{to_dt(elem.created_at)}</Text>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel p={4} fontSize={'xs'}>
                    {elem.msg}
                    </AccordionPanel>
                </AccordionItem>
            )
        })}
    </Accordion></Box>
}
const NotesPanel = (props: {hideNotes: boolean, kidId: string, panelId: string, noteType: string, loggedInUserRole: string, reportId: string, cardTitle: string, newNotePlaceholderText: string}) => { 
    const { tenant_id } = useParams()
    const tenantBranding = useContext(TenantBrandingContext)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [allowSaving, setAllowSaving] = useState<boolean>(['admin', 'coach'].indexOf(props.loggedInUserRole)  !== -1)
    
    const [notesJsxData, setNotesJsxData] = useState<JSX.Element>(<></>)
    const toastClipboard = useToast()
    const showMsg = (title: string, errorMsg: string, toastStatus: "success" | "error") => {
        toastClipboard({
          title: title,
          description: errorMsg,
          position: "top",
          isClosable: true,
          status: toastStatus
        })
      }
  
  const newNoteForm = useFormik({
      initialValues: { 
          msg: ''
      }, onSubmit: (values) => {
          if(values.msg && values.msg.length > 0) {
            setIsSubmitting(true) 
            const noteData = {
                mosaic_id: props.kidId,
                panel_id: props.panelId, 
                report_id: props.reportId, 
                req_type: 'create', 
                note_type: props.noteType, 
                msg: values.msg,
                tenant_id: tenant_id
              }
            addNewNote(noteData)
          } else {
            setIsSubmitting(false)
          }
          
      }
    })

  const addNewNote = (noteData: {mosaic_id: string, req_type: string, panel_id: string, report_id: string, note_type: string, msg: string, tenant_id: any}) => {
      axios
        .post(URLS.UPSERT_PANEL_NOTES, { ...noteData }, { headers: DEFAULT_HEADERS() })
        .then(res => {
          if (res?.data?.body?.status) {
            newNoteForm.values.msg = ''
            setIsSubmitting(false)
            showMsg('Success', `Note saved successfully.`, 'success')
          } else { 
            showMsg('Error', `Unable to save the note. Please try again later.`, 'error') 
            setIsSubmitting(false) 
          }
        })
        .catch((err) => {
          showMsg('Error', `Unable to save the note. Please try again later.`, 'error') 
          setIsSubmitting(false)
        })
  }
    
  useEffect(() => { 
    if (props?.hideNotes) {
      return
    }
    if(['admin', 'coach'].indexOf(props.loggedInUserRole)  !== -1) {
      setAllowSaving(true)
    }
    
    const params = { tenant_id: tenant_id, mosaic_id: props.kidId, panel_id: props.panelId, report_id: props.reportId, req_type:'view', note_type: props.noteType }
    axios
    .post(URLS.PANEL_NOTES, { ...params }, { headers: DEFAULT_HEADERS() })
    .then((res) => res?.data?.body?.notes || [])
    .then((data: INote[]) => {
      if (!data || data.length === 0) {
      setNotesJsxData(
        <Box as="span" flex='1' textAlign='left' fontSize={'xs'} py={2}>
            <Text>No Notes Received from your coach.</Text>
        </Box>
      )
      return
      }
      setNotesJsxData(notesToJsxElem(data))
    })
    .catch((err) => {
      console.error("Unable to get client data for the coach", err);
    })
  }, [props.panelId, props.kidId, tenant_id, props.noteType, props.reportId, props.loggedInUserRole, isSubmitting])

    return <Card p={3}
    my="4"  className={'add_box_shadow_and_radius'} > 
        <CardHeader className={'add_box_radius'} backgroundColor={tenantBranding.color_palette.footer_background_color}>
          <Heading size="sm" textAlign="left" color={tenantBranding.color_palette.icon_font_color || '#000'}>
            {props.cardTitle}
          </Heading>
        </CardHeader>
        <CardBody>
          <Stack w='100%' divider={<StackDivider />}>
          {notesJsxData} 
            <Box w='100%' p={3} hidden={!allowSaving}> 
                <form onSubmit={newNoteForm.handleSubmit}>
                    <Textarea w='100%' id='msg' name='msg' value={newNoteForm.values.msg}
                    placeholder={props.newNotePlaceholderText} resize={'vertical'} size='xs' onChange={newNoteForm.handleChange} />
                    <Flex justifyContent={'right'} my={2}>
                        <ButtonGroup size='sm' isAttached variant='outline' mt={2}>
                            <IconButton aria-label='Save' icon={<BiSave />} 
                                color={tenantBranding.color_palette.footer_background_color || '#000'} disabled />
                            <Button 
                                color={tenantBranding.color_palette.icon_font_color || '#000'}
                                backgroundColor={tenantBranding.color_palette.footer_background_color}
                                type="submit" loadingText='Saving' isLoading={isSubmitting}
                                disabled={isSubmitting}
                                variant='solid'  size='sm'>Save</Button>
                        </ButtonGroup>
                    </Flex>
                </form> 
            </Box>
          </Stack>
        </CardBody> 
  </Card>
}

export { NotesPanel }